import { CartItem, CartOption, Order, Tip } from '../models'
import { CartState } from '../providers/app/reducers'

export const getPriceFromOptionString = (
  optionString: string | number | (string | number | undefined)[] | undefined,
  key: string,
): number => {
  if (!optionString) {
    return 0
  }

  if (key === 'choices') {
    const node = JSON.parse(optionString as string)
    return parseInt(node.price, 10)
  }

  const optionStringArray = optionString.toLocaleString().split(', ')
  const price = optionStringArray.pop()
  if (!price) {
    return 0
  }

  return parseFloat(price)
}

export const getTitleFromOptionString = (
  optionString: string | number | (string | number | undefined)[] | undefined,
  key: string,
): string | null => {
  if (!optionString) {
    return null
  }

  if (key === 'choices') {
    const node = JSON.parse(optionString as string)
    return node.optionTitle
  }

  const optionStringArray = optionString.toLocaleString().split(', ')
  optionStringArray.pop()
  return optionStringArray.join(', ')
}

export const calculateOptionsTotal = (options: CartOption[], key: string) => {
  if (!options) {
    return 0
  }

  return options.reduce((runningTotal, option) => {
    if (Array.isArray(option[key])) {
      const prices = option[key].map((item) => getPriceFromOptionString(item as string, key))
      return prices.reduce((subtotal, price) => {
        return subtotal + price
      }, runningTotal)
    }
    return runningTotal + getPriceFromOptionString(option[key] as string, key)
  }, 0)
}

export const calculateTipForCart = (subtotal: number, tip?: Tip, tipAmount?: string | null) => {
  if (!tip) {
    return subtotal + parseFloat(tipAmount || '0')
  }

  const { strategy, value } = tip
  let total = subtotal

  switch (strategy) {
    case 'mul':
      total = subtotal + subtotal * value
      break
    case 'add':
    default:
      total = subtotal + value
  }

  return total
}

type CartOrOrder = CartState & Order

export const updateCartWithTotal = (cart: CartState | Order, key = 'value') => {
  if (!cart) {
    return
  }

  const { items, selectedTipValue, tip } = cart as CartOrOrder

  if (!items) {
    return cart
  }

  const updatedItems: CartItem[] = items.map((item) => {
    const newItem = {
      ...item,
      subtotal:
        (parseFloat(item.price.toString()) + calculateOptionsTotal(item.options as CartOption[], key)) * parseInt(item.qty.toString(), 10),
    }
    return newItem
  })

  const subtotal = updatedItems.reduce((prev, curr) => {
    return prev + (curr.subtotal || 0)
  }, 0)

  const total = calculateTipForCart(subtotal, selectedTipValue, tip)

  return {
    ...cart,
    subtotal,
    total,
    items: updatedItems,
  }
}
