import { CalendarIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/core'
import { Moment } from 'moment'
import React, { FC, useState, useEffect, useContext } from 'react'
import { SingleDatePicker } from 'react-dates'
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import aphroditeInterface from 'react-with-styles-interface-aphrodite'
import { textColourLight, borderColourLight } from '../../styles'
import { DateTimeContext, RestaurantContext, ThemeContext } from '../../providers'
import { isClosedDateForRestaurant } from '../../utils/feature-flags'

const updatedTheme = {
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottom: 'none',
        borderBottomFocused: 'none',
      },
      pickerInput: {
        borderWidth: 0,
      },
    },
    color: {
      ...DefaultTheme.reactDates.color,
      text: '#767676',
    },
    font: {
      ...DefaultTheme.reactDates.font,
      input: {
        ...DefaultTheme.reactDates.font.input,
        size: '1rem',
      },
    },
    sizing: {
      ...DefaultTheme.reactDates.sizing,
      inputWidth: 141,
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      displayTextPaddingLeft: 0,
    },
  },
}

ThemedStyleSheet.registerInterface(aphroditeInterface)
ThemedStyleSheet.registerTheme(updatedTheme)

export interface DatePickerProps {
  value?: Moment
  onChange?: (date: Moment) => void
  maxBookingDays?: number
}

export const DatePicker: FC<DatePickerProps> = (props) => {
  const { onChange, value, maxBookingDays } = props
  const { theme } = useContext(ThemeContext)
  const { restaurant } = useContext(RestaurantContext)
  const { moment, shortDateFormat } = useContext(DateTimeContext)
  const [isFocused, setFocus] = useState<boolean>(false)
  const defaultDate = value && value.isValid() ? value : moment()
  const [currentDate, setDate] = useState<Moment>(defaultDate)

  const calendarIcon = <CalendarIcon name='calendar' boxSize='.75rem' {...textColourLight} />

  const minDate = moment()
  const maxDate = moment().add(maxBookingDays, 'days')

  const setTheme = () => {
    if (!theme) {
      return
    }

    const {
      colors: { brand, white, gray },
    } = theme

    ThemedStyleSheet.registerTheme({
      ...updatedTheme,
      color: {
        ...updatedTheme.reactDates.color,
        border: gray['200'],
        core: {
          ...updatedTheme.reactDates.color.core,
          white,
          gray: gray['500'],
          grayLight: gray['400'],
          grayLighter: gray['300'],
          grayLightest: gray['200'],
          primary: brand['500'],
          primaryShade_1: brand['400'],
          primaryShade_2: brand['300'],
          primaryShade_3: brand['200'],
          primaryShade_4: brand['100'],
          primary_dark: brand['600'],
        },
        selected: {
          backgroundColor: brand['500'],
          backgroundColor_active: brand['600'],
          backgroundColor_hover: brand['600'],
          borderColor: brand['500'],
          borderColor_active: brand['600'],
          borderColor_hover: brand['600'],
          color: white,
          color_active: white,
          color_hover: white,
        },
        selectedSpan: {
          backgroundColor: brand['500'],
          backgroundColor_active: brand['600'],
          backgroundColor_hover: brand['600'],
          borderColor: brand['500'],
          borderColor_active: brand['600'],
          borderColor_hover: brand['600'],
          color: white,
          color_active: white,
          color_hover: white,
        },
      },
    })
  }

  useEffect(() => {
    setTheme()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  useEffect(() => {
    if (!onChange) {
      return
    }

    onChange(currentDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFocus = ({ focused }) => {
    setFocus(focused)
  }

  const handleDateChange = (date: Moment) => {
    setDate(date)

    if (!onChange) {
      return
    }

    onChange(date)
  }

  const isOutsideRange = (date) =>
    date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day') || isClosedDateForRestaurant(restaurant, date)

  return (
    <Box width='100%' borderWidth={1} {...borderColourLight} zIndex={1}>
      <SingleDatePicker
        id='thedatepicker'
        displayFormat={shortDateFormat}
        onDateChange={handleDateChange}
        focused={isFocused}
        onFocusChange={handleFocus}
        date={currentDate}
        customInputIcon={calendarIcon}
        numberOfMonths={1}
        showClearDate={false}
        isOutsideRange={isOutsideRange}
      />
    </Box>
  )
}
