import { Action, Reducer } from 'redux'
import { isType } from 'typescript-fsa'
import { setSearchTerm } from '../actions'

export interface SearchState {
  searchTerm?: string
}

export const initialSearchState = {}

export const searchState: Reducer = (state: SearchState = initialSearchState, action: Action) => {
  if (isType(action, setSearchTerm)) {
    return {
      searchTerm: action.payload,
    }
  }

  return state
}
