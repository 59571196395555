import { ChildDataProps, graphql, compose } from 'react-apollo'
import React, { createContext, memo } from 'react'
import { OptionsForMenuItem } from '../../models'
import { optionsForMenuItem as optionsForMenuItemQuery } from '../../queries'

interface Response {
  optionsForMenuItem: OptionsForMenuItem[]
}

interface Variables {
  menuItemId: string
}

interface InputProps {
  menuItemId: string
  menuItemOptions: {
    menuOptionsError?: Error
    loading: boolean
    optionsForMenuItem: OptionsForMenuItem[]
  }
}

type ChildProps = ChildDataProps<{}, Response, Variables>

export const MenuOptionsContext = createContext<{
  menuOptionsError?: Error
  loading: boolean
  optionsForMenuItem?: OptionsForMenuItem[]
  menuItemId: string
}>({
  loading: true,
  menuItemId: '',
})

const menuItemOptionsQuery = graphql<InputProps, Response, Variables, ChildProps>(optionsForMenuItemQuery, {
  name: 'menuItemOptions',
  options: ({ menuItemId }) => ({
    variables: {
      menuItemId,
    },
  }),
})

const formatMenuOptions = (menuItemOptions: OptionsForMenuItem): OptionsForMenuItem | undefined => {
  if (!menuItemOptions) {
    return
  }

  const formattedOptionsForMenuItem = {}

  Object.keys(menuItemOptions).forEach((key) => {
    try {
      formattedOptionsForMenuItem[key] = JSON.parse(menuItemOptions[key])
    } catch (ex) {
      formattedOptionsForMenuItem[key] = menuItemOptions[key]
    }
  })

  return formattedOptionsForMenuItem as OptionsForMenuItem
}

const Component = memo<InputProps>((props) => {
  const { children, menuItemOptions, menuItemId } = props
  const { optionsForMenuItem, menuOptionsError, loading } = menuItemOptions || {}

  const formattedOptions = optionsForMenuItem ? optionsForMenuItem.filter((item) => formatMenuOptions(item)) : undefined

  return (
    <MenuOptionsContext.Provider
      value={{
        menuItemId,
        optionsForMenuItem: formattedOptions,
        menuOptionsError,
        loading,
      }}
    >
      {children}
    </MenuOptionsContext.Provider>
  )
})

export const MenuOptionsContextProvider = compose(menuItemOptionsQuery)(Component)
