import { ChildDataProps, graphql, compose } from 'react-apollo'
import React, { createContext, memo } from 'react'
import { restaurantsForMultiSlug } from '../../queries'
import { MultiRestaurant } from '../../models'

interface Response {
  restaurantsForMultiSlug: [MultiRestaurant]
}

interface Variables {
  multiSlug?: string
}

interface InputProps {
  multiSlug?: string
  multiRestaurants: {
    error?: Error
    loading: boolean
    restaurantsForMultiSlug: [MultiRestaurant]
  }
}

type ChildProps = ChildDataProps<{}, Response, Variables>

export const MultiRestaurantContext = createContext<{
  loading: boolean
  multiRestaurants?: [MultiRestaurant]
  multiRestaurantError?: Error
  multiSlug?: string
}>({
  loading: true,
})

// Root query requests the multi restaurant
const multiRestaurantQuery = graphql<InputProps, Response, Variables, ChildProps>(restaurantsForMultiSlug, {
  name: 'multiRestaurants',
  options: ({ multiSlug }) => ({
    variables: {
      multiSlug,
    },
  }),
})

/**
 * Format the data in the 'Restaurants' array
 * - parse stringified JSON
 * - filter out inactive restaurants
 *
 * @param restaurants
 */
const formatRestaurants = (restaurants: [MultiRestaurant]): [MultiRestaurant] | undefined => {
  if (!restaurants) {
    return
  }

  const formattedRestaurants = restaurants
    .map((restaurant) => {
      const formattedRestaurant = {}

      Object.keys(restaurant).forEach((key) => {
        if (typeof restaurant[key] !== 'string') {
          formattedRestaurant[key] = restaurant[key]
          return
        }

        // Parse stringified JSON if able
        try {
          formattedRestaurant[key] = JSON.parse(restaurant[key])
        } catch (ex) {
          formattedRestaurant[key] = restaurant[key]
        }
      })

      return formattedRestaurant
    })
    .filter((restaurant: any) => restaurant.enabled)

  // @ts-ignore
  return formattedRestaurants
}

const Component = memo<InputProps>((props) => {
  const { children, multiSlug, multiRestaurants } = props
  const { restaurantsForMultiSlug: restaurants, error, loading } = multiRestaurants || {}
  const formattedRestaurants = formatRestaurants(restaurants)

  return (
    <MultiRestaurantContext.Provider
      value={{
        loading,
        multiRestaurants: formattedRestaurants,
        multiRestaurantError: error,
        multiSlug,
      }}
    >
      {children}
    </MultiRestaurantContext.Provider>
  )
})

export const MultiRestaurantContextProvider = compose(multiRestaurantQuery)(Component)
