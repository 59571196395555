import { isEqual, omit } from 'lodash'
import React, { FC, useContext } from 'react'
import CurrencyInput from 'react-currency-input'
import { Flex, Input, Stack, Text, FormControl } from '@chakra-ui/core'
import { Button } from '../button'
import { Tip, TipStrategy } from '../../models'
import { AppContext } from '../../providers'
import { addTipToCart } from '../../providers/app/actions'

export const CartTip: FC = () => {
  const {
    state: { cartState },
    dispatch,
  } = useContext(AppContext)

  const handleClick = (value: Tip) => () => {
    dispatch(addTipToCart(value))
  }

  const handleCustomTipChange = (e, maskedValue, value) => {
    const customTip = {
      value,
      label: 'Other',
      strategy: 'add' as TipStrategy,
    }

    dispatch(addTipToCart(customTip))
  }

  const { selectedTipValue, tipValues } = cartState

  return (
    <Flex direction='column' flex={1} mt={4}>
      <Stack direction='row'>
        <Text flex={1}>Tip</Text>
        {tipValues.map((tipValue) => {
          const isSelected = isEqual(omit(selectedTipValue, 'value'), omit(tipValue, 'value'))
          return (
            <Button
              key={tipValue.label}
              size='xs'
              onClick={handleClick(tipValue)}
              brand={isSelected}
              reversed={isSelected}
              transparent={!isSelected}
              isRounded
            >
              {tipValue.label}
            </Button>
          )
        })}
      </Stack>
      {selectedTipValue.strategy === 'add' && (
        <FormControl d='flex' alignItems='center' flexDirection='row' mt={4}>
          <Input as={CurrencyInput} precision={2} prefix='$' value={selectedTipValue.value} onChangeEvent={handleCustomTipChange} />
        </FormControl>
      )}
    </Flex>
  )
}
