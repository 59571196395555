import React, { useContext, FC } from 'react'
import { Box, Link, Text, Flex, Popover, PopoverTrigger, PopoverContent, Stack, PopoverBody } from '@chakra-ui/core'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { FaPhoneAlt, FaMapMarkerAlt, FaRegClock, FaQuestionCircle } from 'react-icons/fa'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import moment from 'moment'
import { Button } from '../button'
import { RestaurantContext } from '../../providers'
import { DAYS, DAYS_FULL, OPEN_STATUS } from '../../utils'
import {
  borderRadiusNormalTopOnly,
  borderRadiusNormal,
  brandColour,
  borderRadiusNormalBottomOnly,
  brandColourText,
  fontSizeSmall,
  textColourNormal,
  shadowLarge,
} from '../../styles'
import { getSupportText, getSupportURL, hasDisplayLocation, isClosedDateForRestaurant } from '../../utils/feature-flags'

export const StoreDetails: FC = () => {
  const { restaurant } = useContext(RestaurantContext)

  if (!restaurant) return null

  const { hours, location, openStatus } = restaurant

  const displayTodaysOpeningHours = () => {
    if (!hours) return null

    const label = DAYS[new Date().getDay()]

    // Display next open hours
    if (openStatus.status === 'willOpen' || openStatus.status === 'wasOpened') {
      return <>{openStatus.opensAt}</>
    }

    // Display siesta hours
    if (restaurant.hours[`${label}OpenEx`]) {
      return (
        <>
          {hours[`${label}Open`]} - {hours[`${label}OpenEx`]}
          <br />
          {hours[`${label}CloseEx`]} - {hours[`${label}Close`]}
        </>
      )
    }

    // Display regular hours
    return (
      <>
        {hours[`${label}Open`]} - {hours[`${label}Close`]}
      </>
    )
  }

  const displayAllOpeningHours = () => {
    if (!hours) return null

    return (
      <>
        {DAYS.map((day, index) => {
          const closed = restaurant.hours[`${day}Open`] === 'Closed' || restaurant.hours[`${day}Close`] === 'Closed'

          const isToday = index === new Date().getDay()

          const dayMoment = moment().startOf('week').add(moment(day, 'ddd').day(), 'days')

          if (closed || isClosedDateForRestaurant(restaurant, dayMoment)) {
            return (
              <Flex key={day} alignItems='center' fontWeight={isToday ? 600 : 400}>
                <Box w='90px' mr={4}>
                  {DAYS_FULL[index]}
                </Box>
                <Text>Closed</Text>
              </Flex>
            )
          }

          if (restaurant.hours[`${day}OpenEx`]) {
            return (
              <Flex key={day} alignItems='center' fontWeight={isToday ? 600 : 400}>
                <Box w='90px' mr={4}>
                  {DAYS_FULL[index]}
                </Box>
                <Flex flexDirection='column'>
                  <Text data-opening>
                    {hours[`${day}Open`]} - {hours[`${day}OpenEx`]}
                  </Text>
                  <Text>
                    {hours[`${day}CloseEx`]} - {hours[`${day}Close`]}
                  </Text>
                </Flex>
              </Flex>
            )
          }

          return (
            <Flex key={day} alignItems='center' fontWeight={isToday ? 600 : 400}>
              <Box w='90px' mr={4}>
                {DAYS_FULL[index]}
              </Box>
              <Text data-opening>
                {hours[`${day}Open`]} - {hours[`${day}Close`]}
              </Text>
            </Flex>
          )
        })}
      </>
    )
  }

  const displayHours = () => {
    const dayAsInt = new Date().getDay()
    const dayMoment = moment().startOf('week').add(dayAsInt, 'days')

    const isClosedDate = isClosedDateForRestaurant(restaurant, dayMoment)

    return (
      <Box>
        <Popover>
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant={{ base: 'link', lg: 'ghost' }}
                  reversed
                  _hover={{
                    bg: 'white',
                  }}
                  _selected={{
                    bg: 'white',
                  }}
                  brand
                  px={2}
                  rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  {...fontSizeSmall}
                  {...(isOpen ? borderRadiusNormalTopOnly : borderRadiusNormal)}
                >
                  <Box marginRight={4} display={{ base: 'none', md: 'block' }}>
                    <FaRegClock fontSize={24} />
                  </Box>
                  <Text as='span' mr={2}>
                    {OPEN_STATUS[isClosedDate ? 'closed' : openStatus.status]}
                  </Text>
                  {!isClosedDate && openStatus.status !== 'closed' && (
                    <Text as='span'>{displayTodaysOpeningHours()}</Text>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                color={textColourNormal.color}
                border='none'
                marginTop='0! important'
                zIndex={2}
                top='auto !important'
                maxWidth='fit-content'
                {...borderRadiusNormalBottomOnly}
              >
                <PopoverBody {...shadowLarge}>
                  <Stack spacing={3}>{displayAllOpeningHours()}</Stack>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Box>
    )
  }

  const displayPhoneNumber = () => {
    if (!location.phone) return null

    const parsedPhoneNumber = parsePhoneNumberFromString(
      location.phone,
      `${process.env.REACT_APP_COUNTRY_CODE}`.toUpperCase() as CountryCode,
    )
    const formattedPhoneNumber = parsedPhoneNumber ? parsedPhoneNumber.formatNational() : location.phone

    return (
      <Link href={`tel:${location.phone}`}>
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={[brandColour, brandColour, brandColour, brandColourText]}>
            <FaPhoneAlt fontSize={24} />
          </Box>
          <Text data-phone whiteSpace='nowrap'>
            {formattedPhoneNumber}
          </Text>
        </Flex>
      </Link>
    )
  }

  const displaySupport = () => {
    const supportURL = getSupportURL(restaurant)
    if (!supportURL) {
      return null
    }

    return (
      <Link href={supportURL} isExternal>
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={[brandColour, brandColour, brandColour, brandColourText]}>
            <FaQuestionCircle fontSize={24} />
          </Box>
          <Text data-address>{getSupportText(restaurant)}</Text>
        </Flex>
      </Link>
    )
  }

  const displayLocation = () => {
    if (!location.address1) return null

    if (!hasDisplayLocation(restaurant)) {
      return null
    }

    return (
      <Link
        href={`https://maps.google.com/?q=${location.address1}+${location.city}+${location.postcode}+${location.state}`}
        isExternal
      >
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={[brandColour, brandColour, brandColour, brandColourText]}>
            <FaMapMarkerAlt fontSize={24} />
          </Box>
          <Text data-address>{location.address1}</Text>
        </Flex>
      </Link>
    )
  }

  return (
    <>
      <Flex alignItems='center' ml={4} display={{ base: 'none', xl: 'flex' }} color='white'>
        <Stack alignItems='center' direction='row' spacing={6}>
          {displayHours()}
          {displayPhoneNumber()}
          {displayLocation()}
          {displaySupport()}
        </Stack>
      </Flex>
      <Box display={{ xs: 'block', xl: 'none' }} mt={{ base: 2, md: 0 }}>
        {displayHours()}
      </Box>
    </>
  )
}
