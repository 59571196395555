export interface GeolocationPositionError {
  code: GeolocationPositionErrorCode
  message: string
}

export interface GeolocationCoordinates {
  latitude: number
  longitude: number
  altitude?: number
  accuracy?: number
  altitudeAccuracy?: number
  heading?: number
  speed?: number
}

export interface GeolocationPosition {
  coords: GeolocationCoordinates
  timestamp?: number
}

enum GeolocationPositionErrorCode {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}
