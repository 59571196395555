import React, { ChangeEvent, FC, useContext } from 'react'
import { Input, InputGroup, InputLeftElement, useBreakpointValue } from '@chakra-ui/core'
import { FaSearch } from 'react-icons/fa'
import { debounce } from 'lodash'
import { borderColourBrand, borderRadiusNormal, brandColourAsText, fontSizeNormal } from '../../styles'
import { AppContext } from '../../providers'
import { setSearchTerm } from '../../providers/app/actions'

export const SearchForm: FC = () => {
  const { dispatch } = useContext(AppContext)

  const handleSearchInput = debounce(async (e) => {
    if (e.target.value.length <= 2) {
      dispatch(setSearchTerm(undefined))
    }

    dispatch(setSearchTerm(e.target.value))
  }, 350)

  const handleChange = (e: ChangeEvent) => {
    e.persist()
    handleSearchInput(e)
  }

  return (
    <InputGroup height='fit-content' width={useBreakpointValue({ base: '100%', md: 'auto' })}>
      <InputLeftElement minHeight={10} px={0} {...brandColourAsText}>
        <FaSearch {...fontSizeNormal} />
      </InputLeftElement>
      <Input
        _focus={{ ...borderColourBrand }}
        bg='white'
        borderColor='white'
        paddingLeft={10}
        placeholder='Search your cravings...'
        type='search'
        {...borderRadiusNormal}
        onChange={handleChange}
      />
    </InputGroup>
  )
}
