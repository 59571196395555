import React, { FC, useContext, useState } from 'react'
import Scrollspy from 'react-scrollspy'
import { Link, ListItem, Flex } from '@chakra-ui/core'
import { MenuContext } from '../../providers'
import { fontSemiBold, fontSizeH5, textColourLight, brandColour } from '../../styles'
import { toSlug } from '../../utils/strings'

export interface MenuNavigationProps {
  offset?: number
}

export const MenuNavigation: FC<MenuNavigationProps> = (props) => {
  const { offset } = props
  const { categories } = useContext(MenuContext)
  const [activeCategory, setActiveCategory] = useState<string>()

  if (!categories) return null

  const scrollableItems = () => {
    const newItems: string[] = []
    Object.keys(categories).map((key) => newItems.push(toSlug(categories[key])))
    const scrollableItemArray = Array.from(new Set(newItems))
    return scrollableItemArray
  }

  const handleUpdate = (item: HTMLElement) => {
    if (!item) {
      return
    }

    // Scroll to active tab
    const link = document.querySelector(`a[href="#${item.id}"]`)
    const scrollContainer = document.getElementById('scroll-tabs')

    if (link instanceof HTMLElement && scrollContainer) {
      const linkOffset = link.offsetLeft - 24
      scrollContainer.scrollLeft = linkOffset
    }

    const { id } = item
    setActiveCategory(id)
  }

  const handleClick = (e) => {
    e.preventDefault()
    const { target } = e
    const href = target.getAttribute('href')
    const hrefWithoutHash = href.substring(1)
    const targetElement = document.getElementById(hrefWithoutHash)

    if (!targetElement) return

    // Scroll to active tab
    const linkOffset = e.target.offsetLeft - 24
    const scrollContainer = document.getElementById('scroll-tabs')

    if (scrollContainer) {
      scrollContainer.scrollLeft = linkOffset
    }

    const pos = targetElement.offsetTop
    window.scrollTo(0, pos + (offset || 0))
    setActiveCategory(targetElement.id)
  }

  return (
    <Flex
      id='scroll-tabs'
      className='scroll-theme'
      pt='1rem'
      pb='1rem'
      alignItems='center'
      overflowX='auto'
      overflowY='hidden'
      {...textColourLight}
    >
      <Scrollspy items={scrollableItems()} offset={offset} style={{ display: 'flex', listStyle: 'none' }} onUpdate={handleUpdate}>
        {categories.map((category) => {
          const isActive = activeCategory === toSlug(category)
          return (
            <ListItem
              key={toSlug(category)}
              marginRight={30}
              color={isActive ? brandColour : 'currentColor'}
              borderBottom={isActive ? '2px solid !important' : 'none'}
              borderBottomColor={isActive ? brandColour : 'none'}
            >
              <Link
                _hover={{
                  textDecoration: 'none',
                }}
                key={category}
                fontWeight={600}
                href={`#${toSlug(category)}`}
                px={0}
                paddingBottom={1}
                whiteSpace='nowrap'
                onClick={handleClick}
                {...fontSemiBold}
                {...fontSizeH5}
              >
                {category}
              </Link>
            </ListItem>
          )
        })}
      </Scrollspy>
    </Flex>
  )
}
