import React, { ChangeEvent, FC, useState } from 'react'
import { Stack, Text, Textarea, useToast } from '@chakra-ui/core'
import { Mutation } from 'react-apollo'
import { ReportBillingIssue } from '../../mutations'
import { Button } from '../button'
import { Modal } from '../modal'
import { fontSizeNormal, fontBold } from '../../styles'

export interface ContactUsProps {
  isOpen: boolean
  onClose: () => void
}

export const ContactUsForm: FC<ContactUsProps> = (props) => {
  const { isOpen, onClose } = props
  const [input, setInput] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSuccess] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false)
  const toast = useToast()

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    setInput(inputValue)
  }

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  const showSuccess = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'success',
      isClosable: true,
    })
  }

  const handleUpdate = (reportBillingIssue) => async () => {
    const data = await reportBillingIssue({ variables: { orderId: 'FB751930-91DA-CD93-FF6C-7C402C191E00', message: input } })
    const successStatus = JSON.parse(data.data.reportBillingIssue)

    if (successStatus.status === 'success') {
      setSuccess(true)
      showSuccess('Thank you', 'Your message has been sent succesfully.')
      onClose()
    } else {
      setError(true)
      showError('Sorry', 'An error ocurred and we could not process your message.')
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isDrawerOnMobile showCloseButton>
      <Stack direction='column' padding={8}>
        <Mutation mutation={ReportBillingIssue}>
          {(reportBillingIssue) => (
            <>
              <Text mb={4} {...fontSizeNormal} {...fontBold}>
                Please leave your message here:
              </Text>
              <Textarea value={input} onChange={handleInputChange} placeholder='Your message...' size='md' />
              <Button brand isRounded reversed onClick={handleUpdate(reportBillingIssue)} width='100%'>
                Submit
              </Button>
            </>
          )}
        </Mutation>
      </Stack>
    </Modal>
  )
}
