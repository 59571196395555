import { Action, Reducer } from 'redux'
import { isType } from 'typescript-fsa'
import { LatLon } from 'use-places-autocomplete'
import {
  userEmail,
  userAddressGeocoded,
  userAddressReceived,
  userPostcodeSet,
  userLocationError,
  userLocationReceived,
  userName,
  userPhone,
} from '../actions/user'
import { GeolocationPosition, GeolocationPositionError } from '../../../models'

export interface UserState {
  position?: GeolocationPosition
  address?: string
  addressGeocoded?: LatLon
  addressPostcode?: string
  fullName?: string
  error?: GeolocationPositionError
  email?: string
  phone?: string
}

export const userState: Reducer = (state: UserState = {}, action: Action) => {
  if (isType(action, userLocationReceived)) {
    return {
      ...state,
      position: action.payload,
      error: undefined,
    }
  }

  if (isType(action, userLocationError)) {
    return {
      ...state,
      error: action.payload,
      position: undefined,
    }
  }

  if (isType(action, userAddressReceived)) {
    return {
      ...state,
      address: action.payload,
    }
  }

  if (isType(action, userAddressGeocoded)) {
    return {
      ...state,
      addressGeocoded: action.payload,
    }
  }

  if (isType(action, userPostcodeSet)) {
    return {
      ...state,
      addressPostcode: action.payload,
    }
  }

  if (isType(action, userName)) {
    return {
      ...state,
      fullName: action.payload,
    }
  }

  if (isType(action, userEmail)) {
    return {
      ...state,
      email: action.payload,
    }
  }

  if (isType(action, userPhone)) {
    return {
      ...state,
      phone: action.payload,
    }
  }

  return state
}
