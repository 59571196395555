import React from 'react'
import { Tabs as ChakraTabs, Tab, TabList } from '@chakra-ui/core'

export interface TabsProps {
  tabs: Array<string>
}

export const Tabs = (props) => {
  const { tabs } = props

  return (
    <ChakraTabs>
      <TabList overflow='scroll'>
        {tabs.map((tab) => (
          <Tab color='black' key={tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
    </ChakraTabs>
  )
}
