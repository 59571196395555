import { Box, Flex, Link, Stack, Spinner, Text, useDisclosure, useBreakpointValue } from '@chakra-ui/core'
import React, { FC, useContext } from 'react'
import { Subscription } from 'react-apollo'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import moment from 'moment'
import { Button, CartTable, ContactUsForm, ErrorMessage, OrderTracker } from '../../../components'
import { OrderType } from '../../../models'
import {
  borderColourLight,
  brandColourAsText,
  fontSizeH4,
  fontSizeSmall,
  fontBold,
  textColourLight,
  textColourNormal,
} from '../../../styles'
import { RestaurantContext, OrderContext } from '../../../providers'
import { subscribeToOrder } from '../../../subscriptions'
import { getPickupTime } from '../../../utils/feature-flags'

export const OrderItem: FC = () => {
  const { order, loading } = useContext(OrderContext)
  const { restaurant } = useContext(RestaurantContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const stackStyles = useBreakpointValue({ base: 'column', lg: 'row' })

  if (!restaurant) return null
  if (!order) return null

  const { name, location, website } = restaurant

  const displayPhoneNumber = () => {
    if (!location.phone) return null
    if (order.type === OrderType.DINEIN) return null

    const parsedPhoneNumber = parsePhoneNumberFromString(
      location.phone,
      `${process.env.REACT_APP_COUNTRY_CODE}`.toUpperCase() as CountryCode,
    )
    const formattedPhoneNumber = parsedPhoneNumber ? parsedPhoneNumber.formatNational() : location.phone

    return (
      <Box>
        <Text {...textColourNormal}>
          Contact restaurant for pickup issues{' '}
          <Link href={`tel:${location.phone}`} {...fontBold}>
            {formattedPhoneNumber}
          </Link>
        </Text>
      </Box>
    )
  }

  const formatDate = (date) => moment(date, 'YYYY-MM-DD').format(restaurant.dateFormat)

  const displayOrderTime = () => {
    if (order.type === OrderType.DINEIN) return null

    const timeContext = order.type === OrderType.PICKUP ? 'Pickup' : 'Delivery'

    return (
      <Box mb={3}>
        <Text>
          {timeContext} Date: {formatDate(order.pickupDate)}
        </Text>
        <Text>
          {timeContext} Time: {getPickupTime(restaurant, order)}
        </Text>
      </Box>
    )
  }

  const displayVenueLinks = () => {
    if (order.type === OrderType.DINEIN) return null

    return (
      <Stack borderBottom='2px solid' {...borderColourLight} direction={['column', 'row']} pb={4}>
        <Link
          _hover={{ textDecoration: 'none' }}
          href={`https://maps.google.com/?q=${location.address1}+${location.city}+${location.postcode}+${location.state}`}
          isExternal
        >
          <Button isRounded transparent brand>
            Get Directions
          </Button>
        </Link>
        {website && (
          <Link _hover={{ textDecoration: 'none' }} href={website} isExternal>
            <Button isRounded transparent brand>
              Website
            </Button>
          </Link>
        )}
      </Stack>
    )
  }

  const displayOrderDetails = () => {
    if (order.type === OrderType.DINEIN) {
      return (
        <Box>
          <Text {...textColourNormal}>
            Table Number: <strong>{order.tableNum}</strong>
          </Text>
        </Box>
      )
    }

    return null
  }

  const displayContactUs = () => {
    if (order.type === OrderType.DINEIN) return null

    return (
      <Flex alignItems='center'>
        <Text>For billing issues</Text>
        <Button px={1} noBorder onClick={onOpen}>
          Contact Us
        </Button>
      </Flex>
    )
  }

  const displayEditOrderOption = () => {
    // TODO: handle update and cancel

    if (order.type === OrderType.DINEIN) return null

    return (
      <Stack spacing={1}>
        <Text {...textColourNormal} {...fontBold}>
          Change and Cancellation Policy
        </Text>
        <Text {...fontSizeSmall} {...textColourLight}>
          Your order can no longer be canceled or modified. To make changes please contact the restaurant directly.
        </Text>
      </Stack>
    )
  }

  if (loading) {
    return (
      <Box justifyContent='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' size='xl' {...brandColourAsText} />
      </Box>
    )
  }

  if (!loading && !order) {
    return <ErrorMessage title='Order not found' description='Sorry, we could not find the order you requested' hideInitial />
  }

  return (
    <>
      <Box mb={8}>
        <Flex borderBottom='2px solid' {...borderColourLight}>
          <Text {...brandColourAsText} {...fontSizeH4} {...fontBold}>
            Order Confirmation Details
          </Text>
        </Flex>
        <Stack direction={stackStyles} spacing={10} mt={6}>
          <Stack minWidth={['unset', '350px']} maxWidth={['100%', '35%']} spacing={1}>
            <Text {...fontSizeSmall} {...textColourLight}>
              Ordered From
            </Text>
            <Text {...fontSizeH4} {...fontBold} {...textColourNormal}>
              {name}
            </Text>
            {displayOrderTime()}
            {displayVenueLinks()}
            <CartTable order={order} isReadOnly />
            {displayOrderDetails()}
            {displayPhoneNumber()}
            {displayContactUs()}
          </Stack>
          <Stack flex={1} spacing={6}>
            <Subscription subscription={subscribeToOrder} variables={{ orderId: order.objectId }}>
              {(response) => (
                <OrderTracker status={response.data && response.data.subscribeToOrder && response.data.subscribeToOrder.status} />
              )}
            </Subscription>
            {displayEditOrderOption()}
          </Stack>
        </Stack>
      </Box>
      <ContactUsForm isOpen={isOpen} onClose={onClose} />
    </>
  )
}
