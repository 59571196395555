import gql from 'graphql-tag'

export const configForGroup = gql`
  query ConfigForGroup($groupSlug: String!) {
    configForGroup(groupSlug: $groupSlug) {
      title
      logoLink
      bgColor
      banner
      restaurants {
        objectId
        branding
        countryCode
        # custTransFeePercent
        # dateFormat
        # deliveryFee
        # deliveryPostcodes
        enabled
        # hours
        imageLink
        # lastUpdated
        # locale
        location
        # menus {
        #   objectId
        #   endTime
        #   menuIndex
        #   menuTitle
        #   objectId
        #   startTime
        #   type
        # }
        name
        # services
        # showMenuImages
        slug
        # socialLinks
        # timeZone
        # typicalEta
        website
      }
    }
  }
`
