import { FontSizeProps, LineHeightProps, FontWeightProps } from 'styled-system'

export const fontSizeExtraSmall: FontSizeProps = {
  fontSize: 'xs',
}

export const fontSizeSmall: FontSizeProps = {
  fontSize: 'sm',
}

export const fontSizeNormal: FontSizeProps = {
  fontSize: 'md',
}

export const lineHeightNormal: LineHeightProps = {
  lineHeight: 'shorter',
}

export const fontBold: FontWeightProps = {
  fontWeight: 'bold',
}

export const fontLight: FontWeightProps = {
  fontWeight: '300',
}

export const fontSemiBold: FontWeightProps = {
  fontWeight: 'semibold',
}

export const fontSizeH1: FontSizeProps = {
  fontSize: '6xl',
}

export const fontSizeH2: FontSizeProps = {
  fontSize: '4xl',
}

export const fontSizeH3: FontSizeProps = {
  fontSize: '2xl',
}

export const fontSizeH4: FontSizeProps = {
  fontSize: 'xl',
}

export const fontSizeH5: FontSizeProps = {
  fontSize: 'md',
}

export const fontSizeH6: FontSizeProps = {
  fontSize: 'sm',
}
