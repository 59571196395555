export const formatCurrency = (amount: number) => {
  let correctAmount = amount
  if (!amount || Number.isNaN(amount)) {
    correctAmount = 0
  }
  return new Intl.NumberFormat(process.env.REACT_APP_LOCALE || 'en-US', {
    style: 'currency',
    currency: process.env.REACT_APP_CURRENCY || 'USD',
    minimumFractionDigits: 2,
  }).format(correctAmount)
}
