import React, { FC, ReactNode } from 'react'
import {
  Box,
  Flex,
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  CloseButton,
  Drawer,
  DrawerProps,
  DrawerOverlay,
  DrawerContent,
  useBreakpoint,
} from '@chakra-ui/core'
import { borderRadiusLarge, brandColourAsBackground, fontBold, fontSizeH5 } from '../../styles'
import { Breakpoint } from '../../models'

export interface ModalProps extends ChakraModalProps, Omit<DrawerProps, 'size'> {
  isDrawerOnMobile?: boolean
  showCloseButton?: boolean
  headerText?: string
  headerRight?: ReactNode
  zIndex?: number
  overflow?: 'scroll' | 'auto' | 'hidden' | 'initial'
  maxHeight?: string
  maxWidth?: string
  minHeight?: string
  borderRadius?: boolean
  scrollBehavior?: 'inside' | 'outside'
  isCentered?: boolean
  showHeader?: boolean
  closeWhenClickedOutside?: boolean
  style?: object
}

export const Modal: FC<ModalProps> = (props) => {
  const {
    children,
    isDrawerOnMobile,
    headerText,
    showCloseButton,
    onClose,
    maxHeight = 'initial',
    zIndex = 2,
    overflow = 'hidden',
    maxWidth,
    borderRadius = false,
    showHeader = true,
    closeWhenClickedOutside = false,
    style,
  } = props

  const breakpoint = useBreakpoint()

  const renderHeader = () => {
    return (
      <Flex
        position='relative'
        alignItems='center'
        borderTopLeftRadius={borderRadius ? ['none', '1.75rem'] : 'none'}
        borderTopRightRadius={borderRadius ? ['none', '1.75rem'] : 'none'}
        justifyContent='center'
        minHeight={35}
        px={8}
        py={2}
        textAlign={showCloseButton ? 'left' : 'center'}
        {...brandColourAsBackground}
        {...fontBold}
        {...fontSizeH5}
      >
        {showCloseButton && <CloseButton position='absolute' left='1rem' onClick={onClose} />}
        <Box textAlign='center'>{headerText}</Box>
      </Flex>
    )
  }

  const renderModal = () => {
    return (
      <ChakraModal {...props}>
        <ModalOverlay
          zIndex={zIndex}
          onClick={() => {
            if (closeWhenClickedOutside) {
              onClose()
            }
          }}
        >
          <ModalContent maxHeight={maxHeight} overflow={overflow} bg='white' style={style} maxWidth={maxWidth} {...borderRadiusLarge}>
            {showHeader && renderHeader()}
            {children}
          </ModalContent>
        </ModalOverlay>
      </ChakraModal>
    )
  }

  const renderDrawer = () => {
    // eslint-disable-next-line
    const { size, ...drawerProps } = props
    return (
      <Drawer {...drawerProps}>
        <DrawerOverlay zIndex={2}>
          <DrawerContent maxHeight='80vh' bg='white'>
            {renderHeader()}
            <Box flex={1} overflow='scroll'>
              {children}
            </Box>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  }

  if (isDrawerOnMobile) {
    return breakpoint === Breakpoint.MOBILE ? renderDrawer() : renderModal()
  }

  return renderModal()
}
