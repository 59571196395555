import React, { useState, useEffect, useContext } from 'react'
import { Elements, PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { Box, Spinner } from '@chakra-ui/core'
import { AppContext, RestaurantContext, PaymentContext } from '../../providers'
import image from '../../images/google-pay.png'
import { brandColourAsText } from '../../styles'
import { Button } from '../button'

const PaymentRequestButton = ({ isSubmitting, validateAndSubmitPayRequest, handlePayRequest }) => {
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  const {
    state: {
      cartState: { total },
    },
  } = useContext(AppContext)

  const { restaurant } = useContext(RestaurantContext)

  useEffect(() => {
    const paymentMethod = async (event, pr) => {
      const { complete } = event
      const { id: paymentMethodId } = event.paymentMethod

      handlePayRequest(paymentMethodId, complete)

      // @ts-ignore
      pr.off('paymentmethod')
    }

    const totalWithoutDecimal = Math.round(total * 100)
    const label = restaurant ? `${restaurant.name} Order` : 'Order'

    if (stripe) {
      const pr = stripe.paymentRequest({
        country: `${process.env.REACT_APP_COUNTRY_CODE}`,
        currency: `${process.env.REACT_APP_CURRENCY}`,
        total: {
          label,
          amount: totalWithoutDecimal,
        },
        requestPayerName: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            // @ts-ignore
            pr.on('paymentmethod', async (event) => paymentMethod(event, pr))

            // @ts-ignore
            setPaymentRequest(pr)
          }
        })
        .catch((error) => console.log('error: ', error))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe])

  if (paymentRequest) {
    if (isSubmitting) {
      return (
        <Button>
          <Spinner position='absolute' thickness='2px' speed='0.65s' emptyColor='gray.200' size='md' {...brandColourAsText} />
        </Button>
      )
    }

    // @ts-ignore
    if (paymentRequest._canMakePaymentAvailability.APPLE_PAY) {
      return (
        <PaymentRequestButtonElement
          onClick={(e) => {
            e.preventDefault()

            // @ts-ignore
            validateAndSubmitPayRequest(paymentRequest.show)
          }}
          options={{
            // @ts-ignore
            paymentRequest,
          }}
        />
      )
    }

    return (
      <Box
        role='button'
        backgroundColor='#000'
        borderRadius='.4rem'
        height='2.8rem'
        padding='0.75rem'
        textAlign='center'
        verticalAlign='middle'
        onClick={() => {
          // @ts-ignore
          validateAndSubmitPayRequest(paymentRequest.show)
        }}
      >
        <img src={image} alt='Buy with Google Pay' style={{ height: '100%', display: 'inline-block', marginTop: '-.4rem' }} />
      </Box>
    )
  }

  return null
}

const PaymentRequestContainer = ({ validateAndSubmitPayRequest, isSubmitting, handlePayRequest }) => {
  const { stripe } = useContext(PaymentContext)

  return (
    <Elements stripe={stripe}>
      <PaymentRequestButton
        isSubmitting={isSubmitting}
        validateAndSubmitPayRequest={validateAndSubmitPayRequest}
        handlePayRequest={handlePayRequest}
      />
    </Elements>
  )
}

export default PaymentRequestContainer
