import Values from 'values.js'
import React, { FC, createContext, useContext } from 'react'
import { ChakraProvider, CSSReset } from '@chakra-ui/core'
import { default as DefaultTheme, Theme } from '@chakra-ui/theme'
import { RestaurantContext } from '../restaurant'
import { GroupConfigContext } from '../group-config'

type Colour = {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

export type EatclubTheme = Theme & {
  colors: Theme['colors'] & {
    brand: Colour
    brandText: Colour
  }
}

export const ThemeContext = createContext<{
  theme?: EatclubTheme
}>({})

export const ThemeContextProvider: FC = (props) => {
  const { restaurant: singleRestaurant } = useContext(RestaurantContext)
  const { restaurants } = useContext(GroupConfigContext)

  const getRestaurantContext = () => {
    if (singleRestaurant) {
      return singleRestaurant
    }

    if (restaurants && restaurants.length > 0) {
      return restaurants[0]
    }

    return null
  }

  const restaurant = getRestaurantContext()

  // Default theme is black and white
  const brandBgColours = {
    50: 'rgba(255, 255, 255)',
    100: 'rgba(204, 204, 204)',
    200: 'rgba(153, 153, 153)',
    300: 'rgba(102, 102, 102)',
    400: 'rgba(51, 51, 51)',
    500: 'rgba(0, 0, 0)',
    600: 'rgba(0, 0, 0)',
    700: 'rgba(0, 0, 0)',
    800: 'rgba(0, 0, 0)',
    900: 'rgba(0, 0, 0)',
  }

  const brandFgColours = {
    50: 'rgba(255, 255, 255)',
    100: 'rgba(255, 255, 255)',
    200: 'rgba(255, 255, 255)',
    300: 'rgba(255, 255, 255)',
    400: 'rgba(255, 255, 255)',
    500: 'rgba(255, 255, 255)',
    600: 'rgba(204, 204, 204)',
    700: 'rgba(153, 153, 153)',
    800: 'rgba(102, 102, 102)',
    900: 'rgba(51, 51, 51)',
  }

  if (restaurant) {
    const bgValues = new Values(`#${restaurant.branding.bgColor}`)
    const fgValues = new Values(`#${restaurant.branding.fgColor}`)

    // Generate 10 shades of the brand colour
    // The brand colour will always be brand.500
    bgValues.all(20).forEach((colour, i: number) => {
      brandBgColours[i === 0 ? 50 : i * 100] = `rgb(${colour.rgb.join(',')})`
    })

    fgValues.all(20).forEach((colour, i: number) => {
      brandFgColours[i === 0 ? 50 : i * 100] = `rgb(${colour.rgb.join(',')})`
    })
  }

  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      brand: {
        ...brandBgColours,
      },
      brandText: {
        ...brandFgColours,
      },
    },
    styles: {
      ...DefaultTheme.styles,
      global: () => ({
        ...DefaultTheme.styles.global,
        'html,body': {
          height: '100%',
        },
        '@media screen and (max-height: 1080px) and (min-width: 480px) and (max-width: 1380px)': {
          html: {
            fontSize: '0.8rem',
          },
        },
      }),
    },
  }

  const { children } = props

  return (
    <ChakraProvider theme={theme}>
      <ThemeContext.Provider value={{ theme }}>
        <CSSReset />
        {children}
      </ThemeContext.Provider>
    </ChakraProvider>
  )
}
