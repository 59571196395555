import { ChildDataProps, graphql, compose } from 'react-apollo'
import React, { createContext, memo } from 'react'
import { configForGroup } from '../../queries'
import { GroupConfig, Restaurant } from '../../models'

interface Response {
  configForGroup: GroupConfig
  error?: Error
  loading: boolean
}

interface Variables {
  groupSlug?: string
}

interface InputProps {
  groupSlug?: string
  groupConfig: {
    error?: Error
    loading: boolean
    configForGroup: GroupConfig
  }
}

type ChildProps = ChildDataProps<{}, Response, Variables>

export const GroupConfigContext = createContext<{
  loading: boolean
  configForGroup?: GroupConfig
  restaurants?: Restaurant[]
  error?: Error
  groupSlug?: string
}>({
  loading: true,
})

const groupConfigQuery = graphql<InputProps, Response, Variables, ChildProps>(configForGroup, {
  name: 'groupConfig',
  options: ({ groupSlug }) => ({
    variables: {
      groupSlug,
    },
  }),
})

/**
 * Format the data in the 'Restaurants' array
 * - parse stringified JSON
 * - filter out inactive restaurants
 *
 * @param restaurants
 */
const formatRestaurants = (restaurants: Restaurant[]): Restaurant[] | undefined => {
  if (!restaurants) {
    return
  }

  const formattedRestaurants = restaurants
    .map((restaurant) => {
      const formattedRestaurant = {}

      Object.keys(restaurant).forEach((key) => {
        if (typeof restaurant[key] !== 'string') {
          formattedRestaurant[key] = restaurant[key]
          return
        }

        // Parse stringified JSON if able
        try {
          formattedRestaurant[key] = JSON.parse(restaurant[key])
        } catch (ex) {
          formattedRestaurant[key] = restaurant[key]
        }
      })

      // Default to AU
      if (!restaurant?.countryCode) {
        restaurant.countryCode = 'au';
      }

      return formattedRestaurant
    })
    .filter((restaurant: any) => restaurant.enabled)

  // @ts-ignore
  return formattedRestaurants
}

const Component = memo<InputProps>((props) => {
  const { children, groupConfig, groupSlug } = props
  const formattedRestaurants = formatRestaurants(groupConfig?.configForGroup?.restaurants)

  return (
    <GroupConfigContext.Provider
      value={{
        ...groupConfig,
        restaurants: formattedRestaurants,
        groupSlug,
      }}
    >
      {children}
    </GroupConfigContext.Provider>
  )
})

export const GroupConfigContextProvider = compose(groupConfigQuery)(Component)
