import { Action, Reducer } from 'redux'
import { isType } from 'typescript-fsa'
import { OrderStatus } from '../../../models/order'
import { changeOrderStatus } from '../actions'

export interface OrderState {
  status: OrderStatus
}

export const initialOrderState = {
  status: OrderStatus.NONE,
}

export const orderState: Reducer = (state: OrderState = initialOrderState, action: Action) => {
  if (isType(action, changeOrderStatus)) {
    return {
      ...state,
      type: action.payload,
    }
  }

  return state
}
