import React, { FC, forwardRef } from 'react'
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/core'
import {
  button,
  buttonBrand,
  buttonBrandReversed,
  buttonBrandTransparent,
  buttonReversed,
  buttonTransparent,
  borderRadiusFull,
  buttonBrandNoBorder,
  buttonTransparentNoBorder,
} from '../../styles'

export interface ButtonProps {
  brand?: boolean
  reversed?: boolean
  transparent?: boolean
  isRounded?: boolean
  noBorder?: boolean
}

export const Button: FC<ButtonProps & ChakraButtonProps> = forwardRef((props, ref) => {
  const { children, reversed = false, isRounded = false, transparent = false, brand = false, noBorder = false, ...rest } = props

  const reversedButtonType = brand ? buttonBrandReversed : buttonReversed
  const transparentButtonType = brand ? buttonBrandTransparent : buttonTransparent
  const noBorderButtonType = brand ? buttonBrandNoBorder : buttonTransparentNoBorder

  const styleProps = {
    ...(brand ? buttonBrand : button),
    ...(reversed ? reversedButtonType : {}),
    ...(transparent ? transparentButtonType : {}),
    ...(isRounded ? borderRadiusFull : {}),
    ...(noBorder ? noBorderButtonType : {}),
  }

  return (
    <ChakraButton {...rest} {...styleProps} ref={ref}>
      {children}
    </ChakraButton>
  )
})
