import slugify from 'slugify'

export const toSlug = (value: string) => {
  return slugify(value, { lower: true })
}

export const pluralise = (string, number, pluralString = '') => {
  if (number === 1) {
    return string
  }

  if (pluralString) {
    return pluralString
  }

  return `${string}s`
}

export const stripForeignCharacters = (value) => {
  return value.replace(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g, '')
}
