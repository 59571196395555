import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/core'
import { Field, Formik } from 'formik'
import React, { FC, useContext, useState, ChangeEvent, ReactText } from 'react'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { AppContext, MenuOptionsContext, RestaurantContext } from '../../providers'
import { CartItem, CartOption, OptionsForMenuItemChoice, OptionsForMenuItem, FulfilmentType } from '../../models'
import { addToCart, updateCartItem } from '../../providers/app/actions'
import { Modal } from '../modal'
import {
  borderColourDefault,
  borderRadiusFull,
  brandColourAsText,
  brandColourText,
  fontSemiBold,
  fontSizeExtraSmall,
  fontSizeSmall,
  textColourLight,
  textColourNormal,
} from '../../styles'
import { Button } from '../button'
import { orderHours, pluralise } from '../../utils'

export interface OptionsModalProps {
  image: string
  optionsTitle: string
  optionsDescription: string
  price: number
  isOpen: boolean
  disableDate: string
  onClose: () => void
}

export const OptionsModal: FC<OptionsModalProps> = (props) => {
  const { image, optionsTitle, optionsDescription, price, isOpen, onClose, disableDate } = props

  // Context
  const { menuItemId, optionsForMenuItem } = useContext(MenuOptionsContext)
  const { restaurant } = useContext(RestaurantContext)
  const { state, dispatch } = useContext(AppContext)
  const {
    cartState: { isEditingItem, itemEditing },
    fulfilmentState: { preferredDateTime },
  } = state
  const toast = useToast()

  const defaultValues: Pick<CartItem, 'qty' | 'requests' | 'options' | 'objectId'> = {
    qty: 1,
    options: [],
    objectId: uuidv4(),
  }

  if (isEditingItem && itemEditing) {
    defaultValues.qty = itemEditing.qty
    defaultValues.requests = itemEditing.requests
    defaultValues.options = itemEditing.options
    defaultValues.objectId = itemEditing.objectId
  }

  // State
  const [options, setSelectedOption] = useState<CartOption[]>(defaultValues.options || [])
  const [count, setCount] = useState(defaultValues.qty)
  const [input, setInput] = useState(defaultValues.requests)

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  if (!restaurant) return null

  const isChoiceDisabled = (choice: OptionsForMenuItemChoice): boolean => {
    if (!choice.disableDate) {
      return false
    }

    return moment().isSame(moment(choice.disableDate, 'DD/MM/YYYY'), 'days')
  }

  const isMenuItemDisabled = () => {
    if (!disableDate) {
      return false
    }

    return moment().isSame(moment(disableDate, 'DD/MM/YYYY'), 'days')
  }

  const updateSelectedOptions = (currentItem: CartOption) => {
    const { objectId } = currentItem

    setSelectedOption((prevState) => {
      if (!prevState) {
        return [currentItem]
      }
      const remainingItems = prevState.filter((item: CartOption) => item.objectId !== objectId)
      return [...remainingItems, currentItem]
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    setInput(inputValue)
  }

  const handleCheckboxChange = (objectId: string, setFieldValue: (objectId: string, value: string) => void) => (value: ReactText[]) => {
    setFieldValue(objectId, value.toLocaleString())
    updateSelectedOptions({ objectId, value })
  }

  const handleRadioChange = (objectId: string, setFieldValue: (objectId: string, value: string) => void) => (value: ReactText) => {
    setFieldValue(objectId, value.toLocaleString())
    updateSelectedOptions({ objectId, value })
  }

  const handleUpdate = () => {
    if (!optionsForMenuItem) {
      return onClose()
    }

    if (state.fulfilmentState.type !== FulfilmentType.DINE_IN) {
      const checkOrderHours = orderHours(restaurant, moment, preferredDateTime)

      if (!checkOrderHours) {
        showError('Date and time outside of opening hours', 'Please select a date and time within opening hours')
        return
      }
    }

    // Validate every option has its min and max choices selected
    for (let i = 0; i < optionsForMenuItem?.length; i += 1) {
      const option = optionsForMenuItem[i]
      const selectionsForOption = options?.find((optionToFind) => optionToFind?.objectId === option?.objectId)?.value || 0

      if (selectionsForOption < option?.minSelections) {
        showError(
          'Not enough choices selected',
          `You must select at least ${option?.minSelections} ${pluralise('choice', option?.minSelections)} for option '${
            option?.optionTitle
          }'`,
        )
        return
      }

      if (option?.maxSelections !== 0 && selectionsForOption > option?.maxSelections) {
        showError(
          'Too many choices selected',
          `You must select no more than ${option?.maxSelections} ${pluralise('choice', option?.maxSelections)} for option '${
            option?.optionTitle
          }'`,
        )
        return
      }
    }

    const cartItem: CartItem = {
      objectId: defaultValues.objectId,
      menuItemId,
      options,
      price,
      itemTitle: optionsTitle,
      requests: input,
      qty: count,
    }

    if (isEditingItem) {
      dispatch(updateCartItem(cartItem))
    } else {
      dispatch(addToCart(cartItem))
    }

    onClose()
  }

  const validateCheckboxes = (fieldName: string, mandatory: boolean) => (value: string): string | undefined => {
    if (!value && mandatory) {
      return `${fieldName} is required`
    }
  }

  const validateRadios = (fieldName: string, mandatory: boolean) => (value: string): string | undefined => {
    if (!value && mandatory) {
      return `${fieldName} is required`
    }
  }

  const getRadioValue = (objectId: string) => {
    const [option] = options.filter((item) => item.objectId === objectId)
    if (!option) {
      return
    }

    return option.value as string | number | undefined
  }

  const getCheckboxValue = (objectId: string) => {
    const [option] = options.filter((item) => item.objectId === objectId)
    if (!option) {
      return
    }

    return option.value as ReactText[]
  }

  const mapDefaultValuesToFormikOptions = () => {
    const optionsToFormValues = (defaultValues.options || []).reduce(
      (obj, curr) => ({
        ...obj,
        [curr.objectId]: [curr.value],
      }),
      {},
    )

    return {
      requests: defaultValues.requests,
      qty: defaultValues.qty,
      ...optionsToFormValues,
    }
  }

  const renderCheckBox = (option: OptionsForMenuItem, setFieldValue: (id: string, value: string) => void) => {
    const newChoices: OptionsForMenuItemChoice[] = JSON.parse(option.choices)
    if (option.maxSelections === 1 && newChoices.length > 1) return null

    return (
      <Field name={option.objectId} validate={validateCheckboxes(option.optionTitle, option.mandatory)}>
        {({ field: { onBlur }, form }) => (
          <FormControl isInvalid={form.errors[option.objectId] && form.submitCount > 0 && option.mandatory}>
            <CheckboxGroup
              data-checkboxes
              colorScheme='gray'
              defaultValue={getCheckboxValue(option.objectId)}
              onChange={handleCheckboxChange(option.objectId, setFieldValue)}
            >
              <Stack direction='column'>
                {Object.keys(newChoices).map((key) => {
                  const choice: OptionsForMenuItemChoice = newChoices[key]
                  const optionValues = options?.find((optionToFind) => optionToFind?.objectId === option?.objectId) || []
                  const value = choice.price ? `${choice.title}, ${choice.price}` : `${choice.title}, 0`
                  // @ts-ignore
                  const selectedChoices = optionValues?.value ?? []
                  const isSelected = selectedChoices?.includes(value) || false
                  const canBeSelected: boolean =
                    option.maxSelections === 0 || isSelected || (selectedChoices?.length || 0) < option.maxSelections

                  return (
                    <Checkbox
                      key={key}
                      value={value}
                      alignItems='flex-start'
                      display='inline-flex'
                      isReadOnly={isChoiceDisabled(choice) || !canBeSelected}
                      data-price={choice.price}
                      onBlur={onBlur}
                      {...textColourLight}
                      {...borderColourDefault}
                    >
                      <Flex alignItems='flex-end' {...fontSizeExtraSmall} {...fontSemiBold}>
                        <Box>
                          {isChoiceDisabled(choice) && <Text {...textColourNormal}>SOLD OUT&nbsp;</Text>}
                          <Text textDecoration={isChoiceDisabled(choice) ? 'line-through' : 'none'} {...textColourLight}>
                            {choice.title}
                          </Text>
                        </Box>
                        <Text {...brandColourAsText}>&nbsp;&nbsp;{choice.price ? `$${choice.price}` : ''}</Text>
                      </Flex>
                    </Checkbox>
                  )
                })}
              </Stack>
              <FormErrorMessage data-error>{form.errors[option.objectId]}</FormErrorMessage>
            </CheckboxGroup>
          </FormControl>
        )}
      </Field>
    )
  }

  const renderRadioButton = (option: OptionsForMenuItem, setFieldValue: (id: string, value: string) => void) => {
    const newChoices: OptionsForMenuItemChoice[] = JSON.parse(option.choices)
    if (option.maxSelections !== 1 || newChoices.length === 1) return null

    return (
      <Field name={option.objectId} validate={validateRadios(option.optionTitle, option.mandatory)}>
        {({ field: { onBlur }, form }) => (
          <FormControl isInvalid={form.errors[option.objectId] && form.submitCount > 0 && option.mandatory}>
            <RadioGroup
              data-radios
              {...textColourLight}
              value={getRadioValue(option.objectId)}
              onBlur={onBlur}
              onChange={handleRadioChange(option.objectId, setFieldValue)}
            >
              <Stack direction='column'>
                {Object.keys(newChoices).map((key) => {
                  const choice = newChoices[key]
                  return (
                    <Radio
                      key={key}
                      value={choice.price ? `${choice.title}, ${choice.price}` : `${choice.title}, 0`}
                      alignItems='flex-start'
                      isDisabled={isChoiceDisabled(choice)}
                      display='inline-flex'
                      colorScheme='gray'
                      data-price={choice.price}
                      {...borderColourDefault}
                    >
                      <Flex alignItems='flex-end' {...fontSizeExtraSmall} {...fontSemiBold}>
                        <Box>
                          <Text display={isChoiceDisabled(choice) ? 'initial' : 'none'} {...textColourNormal}>
                            SOLD OUT&nbsp;
                          </Text>
                          <Text textDecoration={isChoiceDisabled(choice) ? 'line-through' : 'none'} {...textColourLight}>
                            {choice.title}
                          </Text>
                        </Box>
                        <Text {...brandColourAsText}>&nbsp;&nbsp;{choice.price ? `$${choice.price}` : ''}</Text>
                      </Flex>
                    </Radio>
                  )
                })}
              </Stack>
              <FormErrorMessage data-error>{form.errors[option.objectId]}</FormErrorMessage>
            </RadioGroup>
          </FormControl>
        )}
      </Field>
    )
  }

  const renderOptions = (setFieldValue: (id: string, value: string) => void) => {
    if (!optionsForMenuItem) return null

    return (
      <>
        {optionsForMenuItem.map((option: OptionsForMenuItem) => (
          <Box data-option key={option.objectId}>
            <Box
              bg={textColourLight}
              color={brandColourText}
              marginRight={-8}
              marginLeft={-8}
              my={4}
              py={2}
              {...fontSizeExtraSmall}
              {...fontSemiBold}
            >
              <Text px={8}>{option.optionTitle}</Text>
              <Text px={8}>{option.optionDescription}</Text>
            </Box>
            {renderCheckBox(option, setFieldValue)}
            {renderRadioButton(option, setFieldValue)}
          </Box>
        ))}
      </>
    )
  }

  if (!optionsForMenuItem) {
    return (
      <Modal maxHeight='80vh' overflow='auto' isOpen={isOpen} onClose={onClose} placement='bottom' isDrawerOnMobile showCloseButton>
        <Flex minHeight={40} justifyContent='center' my='auto'>
          <Spinner top='50%' position='absolute' thickness='2px' speed='0.65s' emptyColor='gray.200' size='md' {...brandColourAsText} />
        </Flex>
      </Modal>
    )
  }

  return (
    <Modal
      headerText='Customise item'
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom'
      isDrawerOnMobile
      showCloseButton
      scrollBehavior='outside'
    >
      <Box display={image && restaurant.showMenuImages ? 'initial' : 'none'}>
        <Image
          alt={optionsTitle}
          maxHeight={350}
          objectFit='cover'
          src={image}
          width='100%'
          fallbackSrc={image} // NOTE: this fixes safari bug images not loading
        />
      </Box>
      <Flex flexDir='column' px={8} paddingBottom={6} paddingTop={6}>
        <Formik initialValues={mapDefaultValuesToFormikOptions()} onSubmit={handleUpdate}>
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Flex flexDir={['column', 'row']} flexGrow={1}>
                  <Flex display='inline-flex' flexDir='column' flexGrow={1}>
                    {isMenuItemDisabled() && <Text {...textColourNormal}>SOLD OUT&nbsp;</Text>}
                    <Text
                      textDecoration={isMenuItemDisabled() ? 'line-through' : 'none'}
                      marginBottom={2}
                      overflow='hidden'
                      {...fontSizeSmall}
                      {...fontSemiBold}
                    >
                      {optionsTitle}
                    </Text>
                    <Text
                      overflow='hidden'
                      position='relative'
                      {...fontSizeExtraSmall}
                      {...textColourLight}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {optionsDescription}
                    </Text>
                  </Flex>
                  <Flex
                    alignItems={['flex-start', 'flex-end']}
                    display='inline-flex'
                    flexDir={['row', 'column']}
                    justifyContent='space-between'
                    marginTop={[3, 0]}
                  >
                    <Text {...fontSemiBold} {...brandColourAsText}>
                      ${price}
                    </Text>
                  </Flex>
                </Flex>
                {renderOptions(setFieldValue)}
                <Box mt={4}>
                  <Text margin='0px !important' {...fontSizeSmall} {...fontSemiBold} {...textColourLight}>
                    Optional item requests?
                  </Text>
                  <Textarea
                    value={input}
                    onChange={handleInputChange}
                    placeholder='No mayo, sauce on the side...'
                    size='sm'
                    {...borderColourDefault}
                  />
                </Box>
                <Flex alignItems={['center', 'flex-start']} flexDir={['column', 'row']} justifyContent='space-between' mt={2}>
                  <Flex
                    data-quantity
                    alignItems='center'
                    border='1px solid'
                    marginBottom={4}
                    {...borderRadiusFull}
                    {...fontSizeSmall}
                    {...brandColourAsText}
                  >
                    <Button isRounded noBorder onClick={() => setCount((prevCount) => prevCount - 1)} isDisabled={count === 1}>
                      -
                    </Button>
                    <Text {...textColourLight}>Quantity: {count}</Text>
                    <Button isRounded noBorder brand onClick={() => setCount((prevCount) => prevCount + 1)}>
                      +
                    </Button>
                  </Flex>
                  <Button type='submit' brand isRounded reversed {...fontSizeSmall} isDisabled={isMenuItemDisabled()}>
                    Update Your order
                  </Button>
                </Flex>
              </Stack>
            </form>
          )}
        </Formik>
      </Flex>
    </Modal>
  )
}
