import React, { FC, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MenuOptionsContextProvider, AppContext, MenuContext } from '../../../providers'
import { ErrorMessage, OptionsModal } from '../../../components'
import { resetEditCartItem } from '../../../providers/app/actions'

export const MenuItemRoute: FC = () => {
  const history = useHistory()
  const { itemId } = useParams()
  const { menuItems } = useContext(MenuContext)
  const { state, dispatch } = useContext(AppContext)

  if (!menuItems) {
    return null
  }

  const [menuItem] = menuItems.filter((item) => item.objectId === itemId)

  if (!menuItem && menuItems.length > -1) {
    return null
  }

  if (!menuItem) {
    return <ErrorMessage hideInitial />
  }
  const { imageLink, itemTitle, itemDescription, price, disableDate } = menuItem

  const handleClose = () => {
    dispatch(resetEditCartItem())

    let url = `/`

    if (state.fulfilmentState.type === 'Dine In') {
      if (state.fulfilmentState.tableNumber) {
        url = `/table/${state.fulfilmentState.tableNumber}`
      } else {
        url = `/table`
      }
    }

    history.push(url)
  }

  return (
    <MenuOptionsContextProvider menuItemId={itemId}>
      <OptionsModal
        image={imageLink}
        optionsTitle={itemTitle}
        optionsDescription={itemDescription}
        price={price}
        isOpen
        onClose={handleClose}
        disableDate={disableDate}
      />
    </MenuOptionsContextProvider>
  )
}
