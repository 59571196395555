import React, { ChangeEvent, FC, forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { Box, Flex, FormControl, Input, InputProps, Select, Text, useToast } from '@chakra-ui/core'
import usePlacesAutocomplete, { GeocodeResult, LatLon, getGeocode, getLatLng } from 'use-places-autocomplete'
import moment from 'moment'
import { Button } from '../button'
import { FulfilmentTime } from '../../models'
// eslint-disable-next-line import/no-cycle
import { AppContext, RestaurantContext } from '../../providers'
import {
  userAddressGeocoded,
  userAddressReceived,
  userPostcodeSet,
  setFulfilmentPreferredDateTime,
  setFulfilmentTime,
} from '../../providers/app/actions'
// eslint-disable-next-line import/no-cycle
import { hasValidPostcode, stripForeignCharacters } from '../../utils'
// eslint-disable-next-line import/no-cycle
import {
  afterNightlyCutoff,
  getDeliveryDays,
  getLockedOrderTime,
  getNightlyCutoff,
  getRestaurantDisplayName,
  getRestaurantWebsite,
  isClosedDateForRestaurant,
  orderDaysInAdvance,
  validateCustomPostcodes,
} from '../../utils/feature-flags'
// @ts-ignore
import { ReactComponent as SuccessIcon } from '../../assets/success.svg'
// @ts-ignore
import { ReactComponent as FailureIcon } from '../../assets/failure.svg'
import './styles.css'
import { Modal } from '../modal'

const TextInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <FormControl key='thisform'>
    <Input ref={ref} key={props.id} {...props} style={{ height: '54px', fontSize: '18px' }} />
  </FormControl>
))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    if (htmlElRef.current) {
      // @ts-ignore: Object is possibly 'null'
      htmlElRef.current.focus()
    }
  }

  return [htmlElRef, setFocus]
}

export const InitialPopup: FC<InitialPopupProps> = () => {
  const { restaurant } = useContext(RestaurantContext)
  const toast = useToast()

  const [inputRef, setInputFocus] = useFocus()
  const [focusAfterDispatch, setFocusAfterDispatch] = useState(false)

  const [daysForPostcode, setDaysForPostcode] = useState<string[]>([])
  const [momentDaysForPostcode, setMomentDaysForPostcode] = useState<moment.Moment[]>([])
  const [hasSetDate, setHasSetDate] = useState<boolean>(false)
  const [date, setDate] = useState<string | null>(null)
  const [postcodeValid, setPostcodeValid] = useState<boolean>(true)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)

  // Get a list of the next 5 or so available dates, in moment format
  useEffect(() => {
    if (daysForPostcode.length === 0) {
      setMomentDaysForPostcode([])
      return
    }

    // Get all booking dates for the next few weeks
    let dates: Array<moment.Moment> = []
    daysForPostcode.forEach((day) => {
      // Get ISO weekday for day
      const isoWeekday = moment().day(day).isoWeekday()
      const today = moment().isoWeekday()
      const bookingDaysInAdvance = orderDaysInAdvance(restaurant)

      // Cut off orders placed after a certain time
      const nightlyCutoff = getNightlyCutoff(restaurant)
      let afterCutoff = false
      if (bookingDaysInAdvance === 1 && nightlyCutoff !== false) {
        afterCutoff = afterNightlyCutoff(moment(), restaurant)
      }

      if (today + bookingDaysInAdvance <= isoWeekday) {
        // We can book for this week
        const dayAsMoment = moment().isoWeekday(isoWeekday)
        if (!afterCutoff) {
          dates = [...dates, dayAsMoment]
        }

        dates = [
          ...dates,
          moment(dayAsMoment).add(1, 'weeks'),
          moment(dayAsMoment).add(2, 'weeks'),
          moment(dayAsMoment).add(3, 'weeks'),
          moment(dayAsMoment).add(4, 'weeks'),
        ]
      } else {
        // Otherwise, we must book for next week
        const dayAsMoment = moment().add(1, 'week').isoWeekday(isoWeekday)

        if (!afterCutoff) {
          dates = [...dates, dayAsMoment]
        }

        dates = [
          ...dates,
          moment(dayAsMoment).add(1, 'weeks'),
          moment(dayAsMoment).add(2, 'weeks'),
          moment(dayAsMoment).add(3, 'weeks'),
        ]
      }
    })

    // Sort dates chronologically
    dates = dates.sort((a, b) => (a.isBefore(b) ? -1 : 1))
    setMomentDaysForPostcode(dates)
  }, [daysForPostcode, restaurant])

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: `${process.env.REACT_APP_COUNTRY_CODE}`,
      },
    },
    debounce: 300,
  })

  const { state, dispatch } = useContext(AppContext)
  const {
    userState: { address, addressGeocoded, addressPostcode },
  } = state

  // Get delivery days when postcode is set
  useEffect(() => {
    if (!postcodeValid) {
      return
    }

    const deliveryDays = getDeliveryDays(restaurant)
    if (deliveryDays) {
      setDaysForPostcode(deliveryDays)
    } else {
      setDaysForPostcode([])
    }
  }, [addressPostcode, restaurant, postcodeValid])

  // Validate postcode when set
  useEffect(() => {
    console.log('postcode valid', validateCustomPostcodes(restaurant, addressPostcode))
    setPostcodeValid(validateCustomPostcodes(restaurant, addressPostcode))
  }, [addressPostcode, restaurant])

  const clearAddressInput = () => {
    clearSuggestions()
    setDaysForPostcode([])
    setHasSetDate(false)
    setDate(null)

    // @ts-ignore
    setValue('')
    dispatch(userAddressReceived(undefined))
    dispatch(userAddressGeocoded(undefined))
    dispatch(userPostcodeSet(undefined))

    // @ts-ignore
    setFocusAfterDispatch(true)
  }

  const handleSuggestedLocationClick = ({ description }) => async () => {
    setValue(description)
    const geocodingResult: GeocodeResult[] = await getGeocode({ address: description })
    const latLng: LatLon = await getLatLng(geocodingResult[0])
    const reverseGeocodingResult = await getGeocode({
      location: latLng,
    })

    const [bestResult] = reverseGeocodingResult
    const bestPostcode = bestResult.address_components.find((component) => component.types.includes('postal_code'))
    const userPostcode = bestPostcode?.long_name

    if (!hasValidPostcode(restaurant, userPostcode)) {
      clearAddressInput()
      showError(
        'Sorry, we do not deliver to your postcode',
        'Please choose a different delivery address or switch to pickup.',
      )
      return
    }

    dispatch(userAddressReceived(description))
    dispatch(userAddressGeocoded(latLng))
    dispatch(userPostcodeSet(userPostcode))

    clearSuggestions()
  }

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueWithSpecialCharactersRemoved = stripForeignCharacters(e.target.value)
    clearSuggestions()
    setValue(valueWithSpecialCharactersRemoved)
    dispatch(userAddressGeocoded(undefined))
    dispatch(userAddressReceived(undefined))
    dispatch(userPostcodeSet(undefined))
  }

  useEffect(() => {
    if (focusAfterDispatch) {
      // @ts-ignore
      setInputFocus()
      setFocusAfterDispatch(false)
    }
  }, [focusAfterDispatch, setFocusAfterDispatch, setInputFocus])

  const handleDateChange = (e) => {
    clearSuggestions()

    if (!e.target.value) {
      // We deselected...
      setValue(e.target.value)
      setDate(e.target.value)
      setHasSetDate(false)
      return
    }

    // Replicate the time setting logic
    const date: moment.Moment = moment(e.target.value)
    const time: moment.Moment = moment(getLockedOrderTime(restaurant), 'HH:mm')

    const mergedDateTime = moment([date.year(), date.month(), date.date(), time.hour(), time.minutes()])

    dispatch(setFulfilmentPreferredDateTime(mergedDateTime, mergedDateTime))
    dispatch(setFulfilmentTime(FulfilmentTime.OTHER))

    setValue(e.target.value)
    setDate(e.target.value)
    setHasSetDate(true)
  }

  useEffect(() => {
    if (focusAfterDispatch) {
      // @ts-ignore
      setInputFocus()
      setFocusAfterDispatch(false)
    }
  }, [focusAfterDispatch, setFocusAfterDispatch, setInputFocus])

  const renderSuggestions = () => {
    if (status !== 'OK' || addressGeocoded) {
      return null
    }

    return (
      <Flex flexDirection='column' marginTop={3}>
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion

          return (
            <Flex key={place_id} marginTop={1}>
              <Button
                isFullWidth
                data-autocomplete-suggestion
                justifyContent='flex-start'
                onClick={handleSuggestedLocationClick(suggestion)}
              >
                <Text isTruncated>
                  {main_text} {secondary_text}
                </Text>
              </Button>
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const [hasClosedInitialPopup, setHasClosedInitialPopup] = useState(!!sessionStorage.getItem('closed_initial_popup'))
  const closeInitialPopup = () => {
    console.log('closing initial popup')
    setHasClosedInitialPopup(true)
    sessionStorage.setItem('closed_initial_popup', 'true')
  }

  if (hasClosedInitialPopup) {
    return <></>
  }

  return (
    <Box className='initial-popup-container'>
      <Modal
        maxWidth='1180px'
        minHeight='400px'
        isOpen={!hasClosedInitialPopup}
        onClose={closeInitialPopup}
        zIndex={2}
        isCentered
        overflow='initial'
        borderRadius={false}
        showHeader={false}
        style={{ margin: '100px 20px' }}
      >
        <Box className='popup-content' minHeight='400px'>
          <Box className='popup-description' display='flex' mb='50px' style={{ gap: '20px', flexWrap: 'wrap' }}>
            <Box className='popup-header'>Get {getRestaurantDisplayName(restaurant)} delivered to your doorstep</Box>
            <Box className='popup-description-content'>
              All orders must be ordered 1 day in advance. Contactless deliveries will be between 4pm and 7pm. We only
              deliver to select postcodes. Please enter your address to see if we deliver to your area or check out{' '}
              <a
                style={{ textDecoration: 'underline' }}
                target='_blank'
                href={`https://${getRestaurantWebsite(restaurant)}`}
              >
                {getRestaurantWebsite(restaurant)}
              </a>
              !
            </Box>
          </Box>
          <Box className='popup-form' display='flex' style={{ gap: '20px', flexWrap: 'wrap' }}>
            <Box width='100%' style={{ maxWidth: '500px' }}>
              <Flex flexDirection='column'>
                {/* {renderLocationInput()} */}
                <Flex style={{ flexBasis: '50%' }}>
                  <Box style={{ width: '100%' }}>
                    <Box>Address</Box>
                    <TextInput
                      ref={inputRef}
                      isDisabled={!ready}
                      autoComplete='off'
                      onChange={handleAddressChange}
                      placeholder='Enter your delivery address'
                      value={`${address || value}`}
                      onClick={() => {
                        if (!address) {
                          return
                        }

                        clearAddressInput()
                      }}
                    />
                  </Box>
                </Flex>

                {address && !postcodeValid && (
                  <Box style={{ color: 'red' }}>No deliveries available for that address</Box>
                )}
                <Flex flexDirection='column' style={{ flexBasis: '50%' }}>
                  <Box
                    style={{
                      // position: 'absolute',
                      // top: '322px',
                      padding: '10px',
                      borderRadius: '6px',
                      backgroundColor: 'white',
                    }}
                  >
                    {renderSuggestions()}
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <Box width='100%' style={{ maxWidth: '500px' }}>
              <Box>Delivery date</Box>
              <Select
                ref={inputRef}
                isDisabled={!ready || !address || !postcodeValid}
                autoComplete='off'
                onChange={handleDateChange} // how annoying
                placeholder='Choose your delivery date'
                value={value}
                style={{ height: '54px', fontSize: '18px' }}
              >
                {momentDaysForPostcode.map((date) => {
                  const isClosed = isClosedDateForRestaurant(restaurant, date)
                  return (
                    <option disabled={isClosed} value={date.format('YYYY-MM-DD')}>
                      {`${date.format('dddd Do MMMM')}${isClosed ? ' - Closed' : ''}`}
                    </option>
                  )
                })}
              </Select>
            </Box>
          </Box>
          <Box className='popup-border' mt='45px' mb='30px' style={{ borderBottom: '1px solid #000000' }} />
          <Box className='popup-actions'>
            {(!address || (!hasSetDate && daysForPostcode.length > 0)) && (
              <Box display='flex' style={{ justifyContent: 'flex-end' }}>
                <Button
                  onClick={closeInitialPopup}
                  reversed
                  brand
                  transparent
                  isRounded
                  className='popup-continue-button'
                >
                  Skip, continue to the menu
                </Button>
              </Box>
            )}
            {address && daysForPostcode.length === 0 && (
              <Box>
                <Box display='flex'>
                  <FailureIcon style={{ marginRight: '15px', marginTop: '2px' }} />
                  <Box mb='30px' style={{ fontSize: '28px', fontWeight: 'bold', lineHeight: '1' }}>
                    Sorry, we don't currently deliver to your area.
                  </Box>
                </Box>
                <Box style={{ fontSize: '16px', marginBottom: '20px' }}>
                  Visit one of our restaurants or continue to browse our menu.
                </Box>
                <Box display='flex' style={{ justifyContent: 'flex-end' }}>
                  <Button
                    onClick={closeInitialPopup}
                    reversed
                    brand
                    transparent
                    isRounded
                    className='popup-continue-button'
                  >
                    Skip, continue to the menu
                  </Button>
                </Box>
              </Box>
            )}
            {address && hasSetDate && (
              <Box>
                <Box display='flex'>
                  <SuccessIcon style={{ marginRight: '15px', marginTop: '2px' }} />
                  <Box mb='30px' style={{ fontSize: '28px', fontWeight: 'bold', lineHeight: '1' }}>
                    Ready to start ordering
                  </Box>
                </Box>
                <Button
                  onClick={closeInitialPopup}
                  brand
                  transparent
                  reversed={false}
                  isRounded
                  className='popup-continue-button'
                >
                  Continue to menu
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export interface InitialPopupProps {
  onClose?: (e) => void
}
