import { Moment } from 'moment'
import { actionCreatorFactory } from 'typescript-fsa'
import { FulfilmentTime, FulfilmentType } from '../../../models'

const actionCreator = actionCreatorFactory('FULFILMENT')

export const setFulfilmentType = actionCreator<FulfilmentType>('FULFILMENT_TYPE_SET')
export const setFulfilmentTime = actionCreator<FulfilmentTime>('FULFILMENT_TIME_SET')
export const setFulfilmentPreferredDateTime = actionCreator<Moment | undefined>('FULFILMENT_PREFERRED_DATE_TIME')
export const setTableNumber = actionCreator<string | number | null | undefined>('FULFILMENT_TABLE_NUMBER')
export const setServingType = actionCreator<string | undefined>('FULFILMENT_SERVING_TYPE')
