import {
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useBreakpoint,
} from '@chakra-ui/core'
import { Field, Formik } from 'formik'
import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../button'
import {
  borderColourBrand,
  borderColourDefault,
  borderRadiusFull,
  brandColourAsBackground,
  fontBold,
  fontSemiBold,
  fontSizeH4,
  fontSizeH5,
  greyExtraLight,
  shadow,
  textColourLight,
} from '../../styles'
import { Breakpoint, DineInServingOptions, FulfilmentTime, FulfilmentType } from '../../models'
import { AppContext, RestaurantContext } from '../../providers'
import { EtaPill } from '../eta-pill'
import { CartTable } from '../cart-table'
import { setServingType, setTableNumber } from '../../providers/app/actions'
import { totalCartItems } from '../../utils'
import { formatCurrency } from '../../utils/numbers'
import {
  getFulfilmentTypes,
  getMinimumDelivery,
  getPostcodeValidationErrorMessage,
  meetsMinimumDelivery,
  mustChooseDeliveryDate,
  validateCustomPostcodes,
} from '../../utils/feature-flags'

export interface CartProps {
  isOpen: boolean
  onClose: () => void
}

export const Cart: FC<CartProps> = (props) => {
  const { isOpen, onClose } = props
  const { restaurant } = useContext(RestaurantContext)
  const { dispatch, state } = useContext(AppContext)
  const history = useHistory()
  const breakpoint = useBreakpoint()
  const [servingTypeValue, setServingTypeValue] = useState('Serve as ready')
  const hideDineInServingOptions = process.env.REACT_APP_DINE_IN_SERVING_OPTIONS === DineInServingOptions.HIDE
  const minimumDelivery = getMinimumDelivery(restaurant)

  const allowedFulfilmentTypes = getFulfilmentTypes(restaurant)
  const isValidFulfilmentType = allowedFulfilmentTypes.includes(state.fulfilmentState.type)
  const isValidDate = !mustChooseDeliveryDate(restaurant) || state.fulfilmentState.time !== FulfilmentTime.ASAP

  if (!restaurant || !state) {
    return null
  }

  const { name } = restaurant

  const {
    cartState: { items, subtotal },
    fulfilmentState: { type, tableNumber },
  } = state

  const emptyCart = items.length === 0

  const aboveMinimumDelivery = meetsMinimumDelivery(restaurant, subtotal)
  const validCustomPostcode = validateCustomPostcodes(restaurant, state.userState.addressPostcode)
  const disableOrderPlacement =
    emptyCart || !aboveMinimumDelivery || !validCustomPostcode || !isValidFulfilmentType || !isValidDate

  const handleRadioChange = (value: string) => {
    setServingTypeValue(value)
    dispatch(setServingType(servingTypeValue))
  }

  const handleUpdate = (values) => {
    if (emptyCart) return

    const { table } = values

    dispatch(setTableNumber(table))

    let url = '/checkout'

    if (state.fulfilmentState.type === 'Dine In') {
      url = `/table/${table}/checkout`
    }

    history.push(url)
  }

  const validateField = (fieldName: string) => (value: string): string | undefined => {
    const tableNumRegex = /^[A-Za-z0-9_-]*$/

    if (!value) {
      return `${fieldName} required`
    }

    if (!tableNumRegex.test(value)) {
      return `${fieldName} isn't valid`
    }
  }

  const renderTableNumber = () => {
    if (type !== FulfilmentType.DINE_IN) return null

    return (
      <Box px={8} mt={2}>
        <Flex
          flexDirection='row'
          width='100%'
          justifyContent='space-between'
          borderBottomColor={greyExtraLight}
          borderBottomWidth={1}
          py={4}
        >
          <Flex alignItems='center'>
            <Text {...textColourLight}>Ordering for Table</Text>
          </Flex>
          <Field name='table' validate={validateField('Table Number')}>
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.table}
                display='flex'
                alignItems='flex-end'
                flexDirection='column'
                width='auto'
              >
                <InputGroup
                  alignItems='center'
                  border='1px solid'
                  minHeight={6}
                  maxWidth={20}
                  ml={2}
                  px={4}
                  py={0}
                  size='xs'
                  {...borderRadiusFull}
                  {...borderColourBrand}
                >
                  <Input
                    {...field}
                    border='none'
                    errorBorderColor='none'
                    focusBorderColor='none'
                    minHeight={6}
                    px={0}
                    placeholder='Table'
                    type='table'
                  />
                </InputGroup>
                <FormErrorMessage {...borderRadiusFull}>{form.errors.table}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>
      </Box>
    )
  }

  const renderETAPill = () => {
    if (type === FulfilmentType.DINE_IN) return null

    return <EtaPill />
  }

  const renderServingOptions = () => {
    if (type !== FulfilmentType.DINE_IN || hideDineInServingOptions) return null

    return (
      <RadioGroup
        defaultValue='Serve as ready'
        py={4}
        onChange={handleRadioChange}
        value={servingTypeValue}
        {...textColourLight}
      >
        <Stack spacing={5} direction='row'>
          <Radio colorScheme='gray' value='Serve as ready' {...borderColourDefault}>
            Serve as ready
          </Radio>
          <Radio colorScheme='gray' value='Serve all together' {...borderColourDefault}>
            Serve all together
          </Radio>
        </Stack>
      </RadioGroup>
    )
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={breakpoint === Breakpoint.MOBILE ? 'bottom' : 'right'}>
      <DrawerOverlay zIndex={2}>
        <DrawerContent maxWidth='30rem' maxHeight={['80vh', 'initial']}>
          <Flex
            alignItems='center'
            justifyContent='space-between'
            minHeight={35}
            px={8}
            py={2}
            textAlign='left'
            {...brandColourAsBackground}
            {...fontBold}
            {...fontSizeH5}
          >
            <CloseButton onClick={onClose} />
            <Box>Your order</Box>
            <Box />
          </Flex>
          <Box overflow='auto'>
            <Flex alignItems='center' px={8} py={4} {...shadow}>
              <Text {...fontSemiBold} {...fontSizeH4}>
                {name}
              </Text>
            </Flex>
            <Formik initialValues={{ table: tableNumber || '' }} onSubmit={handleUpdate}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {renderTableNumber()}
                  <Box px={8} py={4} mt={2}>
                    <Flex flexDirection='row' justifyContent='space-between'>
                      <Flex alignItems='center' mr={4}>
                        <Box mr={2} px={3} {...borderRadiusFull} {...brandColourAsBackground}>
                          {totalCartItems(items)}
                        </Box>
                        <Text {...textColourLight}>items</Text>
                      </Flex>
                      {renderETAPill()}
                    </Flex>
                    <Flex
                      borderTopColor={greyExtraLight}
                      borderBottomColor={greyExtraLight}
                      borderTopWidth={1}
                      borderBottomWidth={1}
                      flexDirection='column'
                      flex={1}
                      py={4}
                      mt={4}
                    >
                      <CartTable isReadOnly={false} />
                    </Flex>
                    {renderServingOptions()}
                    {!aboveMinimumDelivery && (
                      <Box style={{ margin: '10px 0', color: '#FF0000' }}>
                        Your order must meet the minimum delivery amount of {formatCurrency(minimumDelivery)}
                      </Box>
                    )}
                    {!validCustomPostcode && (
                      <Box
                        style={{
                          margin: '10px 0',
                          color: '#FF0000',
                        }}
                      >
                        {getPostcodeValidationErrorMessage(
                          restaurant,
                          state.userState.addressPostcode,
                          state.fulfilmentState.preferredDateTime,
                        )}
                      </Box>
                    )}
                    {!isValidFulfilmentType && (
                      <Box
                        style={{
                          margin: '10px 0',
                          color: '#FF0000',
                        }}
                      >
                        Invalid fulfilment type. The fulfilment type must be one of:{' '}
                        {allowedFulfilmentTypes?.join(', ')}
                      </Box>
                    )}
                    {!isValidDate && (
                      <Box
                        style={{
                          margin: '10px 0',
                          color: '#FF0000',
                        }}
                      >
                        You must set a delivery date for your order
                      </Box>
                    )}
                    <Flex flex={1} py={4}>
                      <Button width='100%' brand isRounded reversed type='submit' isDisabled={disableOrderPlacement}>
                        Place order
                      </Button>
                    </Flex>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
