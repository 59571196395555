import { hasCustomPostcodes } from './feature-flags'

export const hasValidPostcode = (restaurant, userPostcode) => {
  // Custom postcodes are validated at checkout
  if (hasCustomPostcodes(restaurant)) {
    return true
  }

  // Null delivery postcodes = delivery anywhere
  if (restaurant?.deliveryPostcodes === null) {
    return true
  }

  // No delivery postcodes = no delivery
  if (!restaurant?.deliveryPostcodes) {
    return false
  }

  return restaurant?.deliveryPostcodes.includes(userPostcode)
}
