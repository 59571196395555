import { ShadowProps } from 'styled-system'

export const shadow: ShadowProps = {
  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
}

export const shadowLarge: ShadowProps = {
  boxShadow: '0 20px 25px rgba(0, 0, 0, 0.075)',
}

export const textShadow: ShadowProps = {
  textShadow: '3px 3px 8px rgba(0,0,0,0.7)',
}

export const textShadowThick: ShadowProps = {
  textShadow: '1px 1px 5px rgb(0, 0, 0, 0.8), 1px 1px 5px rgb(0, 0, 0, 0.8)',
}
