import { Box, Center, Flex, Heading, List, ListItem, Divider, Text, useBreakpointValue } from '@chakra-ui/core'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { OrderContext, RestaurantContext } from '../../providers'
import {
  borderRadiusLarge,
  brandColourAsBackground,
  borderColourLight,
  borderRadiusFull,
  shadow,
  buttonBrandReversed,
  buttonBrandTransparent,
} from '../../styles'
import { OrderStatus, OrderType } from '../../models'
import { ErrorMessage } from '../error-message'
import { getPickupTime } from '../../utils/feature-flags'

interface TabListItemProps {
  isActive?: boolean
}

interface OrderTrackerProps {
  status?: OrderStatus
}

export const OrderTracker: FC<OrderTrackerProps> = (props) => {
  const { status } = props
  const { order } = useContext(OrderContext)
  const { restaurant } = useContext(RestaurantContext)

  const flexStyle = useBreakpointValue({ base: 0.5, md: 1 })
  const heightStyle = useBreakpointValue({ base: 30, md: 1 })
  const dividerStyle = useBreakpointValue({ base: 'vertical', md: 'horizontal' })
  const directionStyle = useBreakpointValue({ base: 'column', md: 'row' })
  const listItemStyle = useBreakpointValue({ base: '100%', md: 'auto' })
  const boxPaddingStyle = useBreakpointValue({ base: 4, md: 10 })
  const headerXPaddingStyle = useBreakpointValue({ base: 4, md: 10 })

  if (!order || !restaurant) {
    return <ErrorMessage />
  }

  const currentStatus = status || order.status

  const now = moment()
  const orderPickupDateTime = moment(`${order.pickupDate} ${order.pickupTime}`, 'YYYY-MM-DD h:mm A')

  // Check if current time is after pickup date/time
  const isPastDue = now.isAfter(orderPickupDateTime, 'minutes')
  const isFutureOrder = now.isBefore(orderPickupDateTime, 'day')

  const isPreparing = currentStatus === OrderStatus.PREPARING || currentStatus === OrderStatus.PICKUP || isPastDue
  const isReady = currentStatus === OrderStatus.PICKUP || isPastDue
  const isCancelled = currentStatus === OrderStatus.CANCELLED
  const isReceived = currentStatus === OrderStatus.SENT || isPreparing || isReady || isCancelled || isPastDue
  const isError = !isReceived && !isPreparing && !isReady && !isCancelled

  const TabListItem: FC<TabListItemProps> = (tabProps) => {
    const { isActive = false, children } = tabProps

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _hover, _focus, ...buttonStyles } = isActive ? buttonBrandReversed : buttonBrandTransparent

    return (
      <ListItem
        width={listItemStyle}
        maxWidth='300px'
        textAlign='center'
        flex={1}
        paddingY={2}
        px={3}
        whiteSpace='nowrap'
        {...borderRadiusFull}
        {...buttonStyles}
      >
        {children}
      </ListItem>
    )
  }

  const formatDate = (date) => moment(date, 'YYYY-MM-DD').format(restaurant.dateFormat)

  const getReadyPillText = () => {
    switch (order.type) {
      case OrderType.DELIVERY:
        return 'Food Is Ready'
      case OrderType.DINEIN:
        return 'Nearly There'
      case OrderType.PICKUP:
      default:
        return 'Ready For Pickup'
    }
  }

  const getSentText = () => {
    switch (order.type) {
      case OrderType.DELIVERY:
        if (isFutureOrder) {
          return (
            <Box data-sent-delivery>
              <Text>Your order has been received.</Text>
              <Text>We will start preparing close to the required delivery time.</Text>
            </Box>
          )
        }
        return (
          <Box data-sent-delivery>
            <Text>Your order has been sent to the restaurant.</Text>
            <Text>They will start preparing it soon. Stay tuned!</Text>
          </Box>
        )
      case OrderType.DINEIN:
        return (
          <Box data-sent-dine-in>
            <Text>Your order has been sent to the restaurant.</Text>
            <Text>We&apos;ll be over to your table soon. Stay tuned!</Text>
          </Box>
        )
      case OrderType.PICKUP:
      default:
        if (isFutureOrder) {
          return (
            <Box data-sent-delivery>
              <Text>Your order has been received.</Text>
              <Text>We will start preparing close to the required pickup time.</Text>
            </Box>
          )
        }

        return (
          <Box data-sent-pickup>
            <Text>Your order has been sent to the restaurant.</Text>
            <Text>They will start preparing it soon. Stay tuned!</Text>
          </Box>
        )
    }
  }

  const getPreparingText = () => {
    return (
      <Box data-preparing-text>
        <Text>Your order is being prepared.</Text>
        <Text>We hope you are hungry!</Text>
      </Box>
    )
  }

  const getErrorText = () => {
    return (
      <Box>
        <Text>We&apos;ve encountered an issue with your order</Text>
        <Text>Our team will be in contact with you ASAP to reconcile</Text>
      </Box>
    )
  }

  const getPickupText = () => {
    switch (order.type) {
      case OrderType.DELIVERY:
        return (
          <Box data-ready-delivery>
            <Text>Your food is on the way.</Text>
            <Text>If there&apos;s any problems with the delivery or the time it is taking, please contact us.</Text>
          </Box>
        )
      case OrderType.DINEIN:
        return (
          <Box data-ready-dine-in>
            <Text>Your food is being plated and will be at your table soon.</Text>
            <Text>Enjoy!</Text>
          </Box>
        )
      case OrderType.PICKUP:
      default:
        return (
          <Box data-ready-pickup>
            <Text>Your order is ready for pickup.</Text>
            <Text>Enjoy!</Text>
          </Box>
        )
    }
  }

  const getCancelledText = () => {
    return (
      <Box data-cancelled>
        <Text>Your order has been cancelled.</Text>
      </Box>
    )
  }

  const getSummaryText = () => {
    if (isPastDue) {
      return getPickupText()
    }
    switch (currentStatus) {
      case OrderStatus.SENT:
        return getSentText()
      case OrderStatus.PREPARING:
        return getPreparingText()
      case OrderStatus.PICKUP:
        return getPickupText()
      case OrderStatus.CANCELLED:
        return getCancelledText()
      case OrderStatus.NONE:
      default:
        return getErrorText()
    }
  }

  const getHeadingText = () => {
    if (order.type !== OrderType.DINEIN && isFutureOrder) {
      return (
        <Heading size='md' textAlign='center' as='h3'>
          Pre-Ordered for {formatDate(order.pickupDate)} at {getPickupTime(restaurant, order)}
        </Heading>
      )
    }

    return (
      <Heading size='lg' textAlign='center' as='h3'>
        Track Your Order
      </Heading>
    )
  }

  return (
    <Box data-order-tracker overflow='hidden' {...borderRadiusLarge} {...borderColourLight} {...shadow}>
      <Box {...brandColourAsBackground} padding='4'>
        {getHeadingText()}
      </Box>
      <Box>
        <Flex as='header' {...shadow} paddingY={4} paddingX={headerXPaddingStyle} justifyContent='center'>
          <List d='flex' flex={flexStyle} alignItems='center' justifyContent='flex-start' flexDirection={directionStyle}>
            <TabListItem isActive={isReceived || isError}>Order Received</TabListItem>
            <ListItem maxWidth='20px' flex={0.5} alignItems='center'>
              <Flex height={heightStyle}>
                <Divider display='flex' borderColor='brand.500' orientation={dividerStyle} />
              </Flex>
            </ListItem>
            {!isCancelled && !isError && (
              <>
                <TabListItem isActive={isPreparing}>Preparing Your Food</TabListItem>
                <ListItem maxWidth='20px' flex={0.5} alignItems='center'>
                  <Flex height={heightStyle}>
                    <Divider borderColor='brand.500' orientation={dividerStyle} />
                  </Flex>
                </ListItem>
                <TabListItem data-ready-pill isActive={isReady}>
                  {getReadyPillText()}
                </TabListItem>
              </>
            )}
            {isCancelled && (
              <TabListItem data-is-cancelled isActive={isReady}>
                Cancelled
              </TabListItem>
            )}
            {isError && (
              <TabListItem data-has-error isActive={isReady}>
                Issue Encountered
              </TabListItem>
            )}
          </List>
        </Flex>
        <Center padding={boxPaddingStyle} textAlign='center'>
          {getSummaryText()}
        </Center>
      </Box>
    </Box>
  )
}
