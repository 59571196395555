import React from 'react'
import ReactDOM from 'react-dom'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Rehydrated } from 'aws-appsync-react'
import { ApolloProvider } from 'react-apollo'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import 'focus-visible/dist/focus-visible'
import './styles/scroll.css'

// Offline capabilities in AWS App Sync fail in Safari on Desktop and iOS, but we still want to
// provide a fast experience for other browsers
// const isSafari = /Safari/.test(navigator.userAgent)

const client = new AWSAppSyncClient({
  // Don't remove this line, otherwise it won't work in IOS
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/102
  // if you want to enable caching again, set disableOffline to isSafari and uncomment the isSafari variable
  disableOffline: true,
  url: `${process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT}`,
  region: `${process.env.REACT_APP_AWS_APPSYNC_REGION}`,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: `${process.env.REACT_APP_AWS_APPSYNC_API_KEY}`,
  },
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Rehydrated>
      <Router basename={process.env.REACT_APP_BASE_URL}>
        <Switch>
          <Route path='/:slug'>
            <App />
          </Route>
          <Route
            component={() => {
              window.location.href = 'https://www.home.order-direct.com.au/'
              return null
            }}
          />
        </Switch>
      </Router>
    </Rehydrated>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
