import { DAYS } from './dates'

const timeAsMinutes = (momentObject) => {
  return momentObject.minutes() + momentObject.hours() * 60
}

export const orderHours = (restaurant, moment, orderTime) => {
  if (!restaurant || !restaurant.hours) return false

  const getActualOrderTime = () => {
    let orderTimeWithoutEta

    if (!moment.isMoment(orderTime) && typeof orderTime === 'string') {
      orderTimeWithoutEta = moment(orderTime)
      return orderTimeWithoutEta
    }

    if (orderTime === undefined) {
      orderTimeWithoutEta = moment()
      return orderTimeWithoutEta
    }

    orderTimeWithoutEta = moment(orderTime, ['HH:mm A'])
    return orderTimeWithoutEta
  }
  const actualOrderTime = getActualOrderTime()

  const today = DAYS[new Date().getDay()]
  const label = orderTime ? DAYS[actualOrderTime.weekday()] : today
  const orderMinutes = timeAsMinutes(actualOrderTime)

  let isAbleToOrder = false

  if (restaurant.hours[`${label}OpenEx`]) {
    const morningOpeningTime = moment(restaurant.hours[`${label}Open`], ['HH:mm A'])
    const morningClosingTime = moment(restaurant.hours[`${label}OpenEx`], ['HH:mm A'])
    const afternoonOpeningTime = moment(restaurant.hours[`${label}CloseEx`], ['HH:mm A'])
    const afternoonClosingTime = moment(restaurant.hours[`${label}Close`], ['HH:mm A'])

    const morningOpeningMinutes = timeAsMinutes(morningOpeningTime)
    const morningClosingMinutes = timeAsMinutes(morningClosingTime)
    const afternoonOpeningMinutes = timeAsMinutes(afternoonOpeningTime)
    const afternoonClosingMinutes = timeAsMinutes(afternoonClosingTime)

    isAbleToOrder =
      (orderMinutes >= morningOpeningMinutes && orderMinutes < morningClosingMinutes) ||
      (orderMinutes >= afternoonOpeningMinutes && orderMinutes < afternoonClosingMinutes)
    return isAbleToOrder
  }

  const openingTime = moment(restaurant.hours[`${label}Open`], ['HH:mm A'])
  const closingTime = moment(restaurant.hours[`${label}Close`], ['HH:mm A'])

  const openingMinutes = timeAsMinutes(openingTime)
  let closingMinutes = timeAsMinutes(closingTime)

  if (closingMinutes < openingMinutes) {
    closingMinutes += 1440
  }

  isAbleToOrder = orderMinutes >= openingMinutes && orderMinutes < closingMinutes
  return isAbleToOrder
}
