import { createBrowserHistory } from 'history'
import moment from 'moment'
import React from 'react'
import { Route, BrowserRouter as Router, Switch, useParams } from 'react-router-dom'
import { MenuRoute, OrderRoute, LandingRoute } from './routes'
import {
  AppContextProvider,
  RestaurantContextProvider,
  DateTimeContextProvider,
  PaymentContextProvider,
  ThemeContextProvider,
  RestaurantContext,
  AppContext,
} from './providers'
import { ErrorMessage } from './components'
import { routeMap } from './utils'
import { MenuContextProvider } from './providers/menu'

const App = () => {
  const history = createBrowserHistory()
  const { slug } = useParams()
  const hashPath = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]
  const { root, menu, order, group, groupSlug } = routeMap

  if (hashPath) {
    history.replace(hashPath)
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path={groupSlug}>
          <LandingRoute />
        </Route>
        <Route path={group}>
          <ThemeContextProvider>
            <ErrorMessage hideInitial />
          </ThemeContextProvider>
        </Route>
        <Route>
          <Router basename={`/${slug}`} history={history}>
            <DateTimeContextProvider>
              <RestaurantContextProvider slug={slug}>
                <RestaurantContext.Consumer>
                  {({ restaurant }) => (
                    <AppContextProvider>
                      <AppContext.Consumer>
                        {({
                          state: {
                            fulfilmentState: { type, preferredDateTime },
                          },
                        }) => (
                          <MenuContextProvider
                            restaurant={restaurant}
                            type={type}
                            date={moment(preferredDateTime).format('YYYY-MM-DD')}
                            time={moment(preferredDateTime).format('h:mm a')}
                          >
                            <PaymentContextProvider>
                              <ThemeContextProvider>
                                <Switch>
                                  <Route exact path={root}>
                                    <MenuRoute />
                                  </Route>
                                  <Route exact path={menu}>
                                    <MenuRoute />
                                  </Route>
                                  <Route path={order}>
                                    <OrderRoute />
                                  </Route>
                                  <Route>
                                    <ErrorMessage hideInitial />
                                  </Route>
                                </Switch>
                              </ThemeContextProvider>
                            </PaymentContextProvider>
                          </MenuContextProvider>
                        )}
                      </AppContext.Consumer>
                    </AppContextProvider>
                  )}
                </RestaurantContext.Consumer>
              </RestaurantContextProvider>
            </DateTimeContextProvider>
          </Router>
        </Route>
      </Switch>
    </Router>
  )
}

export default App
