import moment from 'moment'
import { getPriceFromOptionString, getTitleFromOptionString } from './cart'
import { CartItem, FulfilmentType } from '../models'
import { AppContextState } from '../providers/app'

export const formatChoice = (choice: string | number | undefined) => {
  return JSON.stringify({
    price: `${getPriceFromOptionString(choice, 'value')}`,
    title: getTitleFromOptionString(choice, 'value'),
  })
}

export const formatAndStringifyChoices = (choices: string | number | (string | number | undefined)[] | undefined) => {
  if (Array.isArray(choices)) {
    return choices.map((choice) => formatChoice(choice))
  }

  return [formatChoice(choices)]
}

export const mapItemsToPaymentIntentMutation = (items: CartItem[]) => {
  return items.map((item: CartItem) => ({
    menuItemId: item.menuItemId,
    price: `${item.price}`,
    qty: item.qty,
    requests: item.requests,
    options: item.options
      ? item.options.map((option) => ({
          menuItemOptionId: option.objectId,
          choices: formatAndStringifyChoices(option.value),
        }))
      : null,
  }))
}

export const createPaymentIntentFromState = (state: AppContextState, restaurant) => {
  const { objectId: restaurantId, custTransFeePercent, deliveryFee, typicalEta } = restaurant

  const {
    userState: { fullName, email, phone, address },
    fulfilmentState: { type, preferredDateTime, tableNumber },
    cartState: { items, subtotal, total },
  } = state

  const isDelivery = type === FulfilmentType.DELIVERY
  const isDineIn = type === FulfilmentType.DINE_IN

  const dineInNow = moment()
  const dineInTime = typicalEta ? dineInNow.add(typicalEta, 'minute').format('hh:mm A') : dineInNow.format('hh:mm A')
  const dineInDate = dineInNow.format('YYYY-MM-DD')

  const preferredDateTimeAsMoment = moment(preferredDateTime)
  const formattedDateTime = preferredDateTime ? preferredDateTimeAsMoment.format('hh:mm A') : ''

  const getPickupDate = () => {
    if (isDineIn) {
      return dineInDate
    }
    if (preferredDateTime) {
      return preferredDateTimeAsMoment.format('YYYY-MM-DD')
    }
    return moment().format('YYYY-MM-DD')
  }

  const pickupTime = isDineIn ? dineInTime : formattedDateTime
  const pickupDate = getPickupDate()

  const tip = (total - subtotal).toFixed(2)

  const getTxnFees = () => {
    if (!restaurant) return null
    if (!custTransFeePercent) return null

    // @ts-ignore
    let orderTxnFee = (custTransFeePercent * total) / 100

    if (isDelivery) {
      if (typeof deliveryFee !== 'undefined') {
        // @ts-ignore
        orderTxnFee = (custTransFeePercent * (total + deliveryFee)) / 100
      }
    }

    // @ts-ignore
    const formatNumber = parseFloat(orderTxnFee).toFixed(2)

    return formatNumber
  }

  const custTransFee = getTxnFees() || null

  return {
    version: 5,
    restaurantId,
    items: mapItemsToPaymentIntentMutation(items),
    total: `${subtotal}`,
    tip: `${tip}`,
    phone,
    name: fullName,
    email,
    pickupTime,
    ...(isDineIn && tableNumber && { tableNum: tableNumber }),
    pickupDate,
    partySize: 1,
    type: type.toLowerCase(),
    ...(custTransFee && { custTransFee }),
    ...(isDelivery && deliveryFee && { deliveryFee: `${deliveryFee}` }),
    ...(isDelivery && address && { address }),
  }
}
