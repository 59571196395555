import gql from 'graphql-tag'

export const restaurantInfo = gql`
  query Slug($slug: String!) {
    restaurantForSlug(slug: $slug) {
      banner
      objectId
      lastUpdated
      enabled
      name
      imageLink
      website
      typicalEta
      countryCode
      locale
      dateFormat
      timeZone
      showMenuImages
      hours
      location
      services
      branding
      socialLinks
      menus {
        objectId
        menuTitle
        objectId
        menuIndex
        startTime
        endTime
        type
      }
      deliveryPostcodes
      deliveryFee
      custTransFeePercent
    }
  }
`
