import { ChildDataProps, graphql, compose } from 'react-apollo'
import React, { createContext, memo, useContext } from 'react'
import { Restaurant } from '../../models'
import { restaurantInfo } from '../../queries'
import { openingHours, openStatus } from '../../utils/opening-hours'
import { DateTimeContext } from '../datetime'

interface Response {
  restaurantForSlug: Restaurant
}

interface Variables {
  slug?: string
}

interface InputProps {
  slug?: string
  restaurant: {
    error?: Error
    loading: boolean
    restaurantForSlug: Restaurant
  }
}

type ChildProps = ChildDataProps<{}, Response, Variables>

export const RestaurantContext = createContext<{
  restaurant?: Restaurant
  restaurantError?: Error
  slug?: string
  isValidRestaurant?: boolean
  loading: boolean
}>({
  loading: true,
})

// Root query requests the restaurant and menus
const restaurantQuery = graphql<InputProps, Response, Variables, ChildProps>(restaurantInfo, {
  name: 'restaurant',
  options: ({ slug }) => ({
    variables: {
      slug,
    },
  }),
})

const formatWebsite = (website) => {
  if (!website) {
    return website
  }

  const pattern = /^((http|https|ftp):\/\/)/
  let url = website

  if (!pattern.test(url)) {
    url = `http://${url}`
  }

  return url
}

const formatRestaurant = (restaurant: Restaurant, moment): Restaurant | undefined => {
  if (!restaurant) {
    return
  }

  const formattedRestaurant = {}

  Object.keys(restaurant).forEach((key) => {
    if (typeof restaurant[key] !== 'string') {
      formattedRestaurant[key] = restaurant[key]
      return
    }

    if (key === 'website') {
      if (restaurant[key]) {
        const url = formatWebsite(restaurant[key])

        formattedRestaurant[key] = url
        return
      }
    }

    try {
      formattedRestaurant[key] = JSON.parse(restaurant[key])
    } catch (ex) {
      formattedRestaurant[key] = restaurant[key]
    }
  })

  formattedRestaurant['open'] = openingHours(formattedRestaurant, moment)
  formattedRestaurant['openStatus'] = openStatus(formattedRestaurant, moment)
  return formattedRestaurant as Restaurant
}

const Component = memo<InputProps>((props) => {
  const { children, slug, restaurant } = props
  const { restaurantForSlug, error, loading } = restaurant || {}
  const { moment } = useContext(DateTimeContext)
  const formattedRestaurant = formatRestaurant(restaurantForSlug, moment)
  const isValidRestaurant = formattedRestaurant ? formattedRestaurant.enabled : !!restaurantForSlug

  return (
    <RestaurantContext.Provider
      value={{
        restaurant: formattedRestaurant,
        isValidRestaurant,
        restaurantError: error,
        loading,
        slug,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  )
})

export const RestaurantContextProvider = compose(restaurantQuery)(Component)
