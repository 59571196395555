import { Action, Reducer } from 'redux'
import { isType } from 'typescript-fsa'
import { clearCart, editCartItem, resetEditCartItem } from '../actions/cart'

import { CartItem, Tip, TipStrategy } from '../../../models'
import { addToCart, removeFromCart, addTipToCart, updateCartItem, orderIsSubmitting } from '../actions'

export interface CartState {
  items: CartItem[]
  selectedTipValue: Tip
  tip: number
  tipValues: Tip[]
  total: number
  subtotal: number
  isEditingItem: boolean
  itemEditing?: CartItem
  isSubmitting?: boolean
}

export const initialCartState = {
  items: [],
  total: 0,
  tip: 0,
  subtotal: 0,
  isEditingItem: false,
  // TODO: Get these from an API?
  selectedTipValue: {
    label: 'No Tip',
    strategy: 'mul' as TipStrategy,
    value: 0,
  },
  tipValues: [
    {
      label: 'No Tip',
      strategy: 'mul' as TipStrategy,
      value: 0,
    },
    {
      label: '5%',
      strategy: 'mul' as TipStrategy,
      value: 0.05,
    },
    {
      label: '10%',
      strategy: 'mul' as TipStrategy,
      value: 0.1,
    },
    {
      label: '15%',
      strategy: 'mul' as TipStrategy,
      value: 0.15,
    },
    {
      label: 'Other',
      strategy: 'add' as TipStrategy,
      value: 0,
    },
  ],
  isSubmitting: false,
}

export const cartState: Reducer = (state: CartState = initialCartState, action: Action) => {
  if (isType(action, addToCart)) {
    return {
      ...state,
      items: [...state.items, action.payload],
    }
  }

  if (isType(action, removeFromCart)) {
    return {
      ...state,
      items: [...state.items.filter((item) => item.objectId !== action.payload.objectId)],
    }
  }

  if (isType(action, addTipToCart)) {
    return {
      ...state,
      selectedTipValue: action.payload,
    }
  }

  if (isType(action, editCartItem)) {
    return {
      ...state,
      isEditingItem: true,
      itemEditing: action.payload,
    }
  }

  if (isType(action, resetEditCartItem)) {
    return {
      ...state,
      isEditingItem: false,
      itemEditing: undefined,
    }
  }

  if (isType(action, updateCartItem)) {
    return {
      ...state,
      items: [...state.items.filter((item) => item.objectId !== action.payload.objectId), action.payload],
    }
  }

  if (isType(action, clearCart)) {
    return initialCartState
  }

  if (isType(action, orderIsSubmitting)) {
    return {
      ...state,
      isSubmitting: action.payload,
    }
  }

  return state
}
