import gql from 'graphql-tag'

export const orderForId = gql`
  query OrderForId($orderId: String!) {
    orderForId(orderId: $orderId) {
      objectId
      created
      version
      restaurantId
      businessName
      total
      tip
      phone
      name
      email
      pickupTime
      pickupDate
      transactionId
      status
      statusReason
      partySize
      paymentIntentStatus
      tableNum
      items {
        objectId
        menuItemId
        itemTitle
        price
        requests
        category
        qty
        options {
          objectId
          menuItemOptionId
          optionTitle
          choices
        }
      }
      type
      deliveryFee
      custTransFee
      custTransFeePercent
      address
    }
  }
`
