import gql from 'graphql-tag'

export const submitOrder = gql`
  mutation submitOrder($orderId: String!) {
    submitOrder(orderId: $orderId) {
      created
      version
      items {
        objectId
        options {
          objectId
          menuItemOptionId
          optionTitle
          choices
        }
        menuItemId
        itemTitle
        price
        requests
        category
        qty
      }
      restaurantId
      businessName
      total
      tip
      phone
      name
      email
      pickupTime
      pickupDate
      transactionId
      status
      statusReason
      partySize
      paymentIntentStatus
      tableNum
      address
    }
  }
`
