import { Box, Flex, Spinner, Stack, useDisclosure, useBreakpointValue } from '@chakra-ui/core'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import React, { useEffect, useRef, useContext, useState, memo, FC } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import {
  Cart,
  Container,
  ErrorMessage,
  FulfilmentModal,
  FulfilmentTypeToggle,
  FulfilmentTimeToggle,
  Header,
  HeroBanner,
  MenuNavigation,
  MiniCart,
  SearchForm,
  StoreDetails,
  TablePill,
  Modal,
} from '../../components'
import { FulfilmentType } from '../../models'
import { AppContext, RestaurantContext } from '../../providers'
import { setFulfilmentType, setTableNumber, setSearchTerm } from '../../providers/app/actions'
import { brandColourAsText } from '../../styles'
import '../styles.css'
import { routeMap } from '../../utils'
import { AnnouncementBanner } from '../../components/announcement-banner'
import { getDefaultFulfilmentType, getInitialPopupContent, hasDefaultFulfilmentType } from '../../utils/feature-flags'

export const RestaurantLayout: FC = memo((props) => {
  const { children } = props
  const { restaurant, loading } = useContext(RestaurantContext)
  const { tableNumber } = useParams()
  const navigationRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const heroBannerRef = useRef<HTMLDivElement>(null)
  const { isOpen: isFulfilmentOpen, onOpen: onFulfilmentOpen, onClose: onFulfilmentClose } = useDisclosure()
  const { isOpen: isCartOpen, onOpen: onCartOpen, onClose: onCartClose } = useDisclosure()
  const boxDisplayMobile = useBreakpointValue({ base: 'flex', md: 'flex', lg: 'none' })
  const stickyPositionMobile = useBreakpointValue({
    base: 'sticky',
    sm: 'initial',
    md: 'initial',
    lg: 'sticky',
    xl: 'sticky',
  })
  const stickyPositionDesktop = useBreakpointValue({
    base: 'initial',
    sm: 'initial',
    md: 'initial',
    lg: 'sticky',
    xl: 'sticky',
  })

  const { order, table } = routeMap
  const isOrder = useRouteMatch(order)
  const isTableRoute = useRouteMatch(table[0])
  const bannerText = restaurant?.banner

  const [offset, setOffset] = useState(0)
  const {
    state: {
      cartState: { isEditingItem },
      fulfilmentState: { type },
    },
    dispatch,
  } = useContext(AppContext)
  const isDelivery = type === FulfilmentType.DELIVERY

  // Initial popup
  const initialPopup = getInitialPopupContent(restaurant)

  useEffect(() => {
    // Set dine-in mode
    if (isTableRoute) {
      dispatch(setFulfilmentType(FulfilmentType.DINE_IN))
      dispatch(setTableNumber(tableNumber))
    } else if (isDelivery) {
      dispatch(setFulfilmentType(FulfilmentType.DELIVERY))
      dispatch(setTableNumber(undefined))
    } else {
      dispatch(setFulfilmentType(FulfilmentType.PICKUP))
      dispatch(setTableNumber(undefined))
    }

    dispatch(setSearchTerm(''))

    if (!restaurant) return undefined
    document.title = `${restaurant.name}`

    if (hasDefaultFulfilmentType(restaurant)) {
      const defaultFulfilmentType = getDefaultFulfilmentType(restaurant)
      dispatch(setFulfilmentType(defaultFulfilmentType))
      dispatch(setTableNumber(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant])

  useScrollPosition(() => {
    let y = 0

    if (headerRef.current) {
      y += headerRef.current.getBoundingClientRect().height
    }

    if (navigationRef.current) {
      y += navigationRef.current.getBoundingClientRect().height - navigationRef.current.getBoundingClientRect().top
    }

    if (heroBannerRef.current) {
      y += heroBannerRef.current.getBoundingClientRect().height
    }

    setOffset(-y)
  }, [])

  if (loading) {
    return (
      <Spinner
        top='50%'
        left={['45%', '50%']}
        transform='translate(-50%, -50%)'
        position='absolute'
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        size='xl'
        {...brandColourAsText}
      />
    )
  }

  const isDineIn = type === FulfilmentType.DINE_IN

  const getHeroBannerTopPosition = () => {
    if (isOrder) {
      return [0, -70, -70, 0]
    }
    return [0, -70, -70, -465]
  }

  const getBarTopPosition = () => {
    if (isOrder) {
      return [0, 200, 105]
    }
    return [0, 200, 70]
  }

  // @ts-ignore
  return (
    <Box>
      {initialPopup}
      <Box ref={headerRef} position={stickyPositionDesktop} top={0} zIndex={2}>
        <Header />
      </Box>
      <Box ref={heroBannerRef} top={getHeroBannerTopPosition()} zIndex={1} className='heroBannerBox'>
        <HeroBanner />
      </Box>
      <Box position={stickyPositionMobile} top={getBarTopPosition()} zIndex={1} ref={navigationRef}>
        <Box bg='brand.500' overflowX='auto' py={4}>
          <Container>
            {isDineIn && (
              <Flex justifyContent='flex-end' minHeight='2rem'>
                <TablePill />
              </Flex>
            )}
            {!isDineIn && (
              <Flex
                justifyContent={!isOrder ? 'space-between' : 'center'}
                alignItems='center'
                flexDirection={{ base: 'column', md: 'row' }}
              >
                {!isOrder && (
                  <>
                    <Flex width={{ base: '100%', md: 'auto' }}>
                      <Box mr={2} flexBasis={{ base: '50%', md: 'auto' }}>
                        <FulfilmentTypeToggle />
                      </Box>
                      <Box mr={{ lg: 2 }} flexBasis={{ base: '50%', md: 'auto' }}>
                        <FulfilmentTimeToggle onChange={onFulfilmentOpen} />
                      </Box>
                    </Flex>
                    <Box display={{ base: 'none', lg: 'flex' }}>
                      <SearchForm />
                    </Box>
                  </>
                )}
                <StoreDetails />
              </Flex>
            )}
          </Container>
        </Box>
        {!isOrder && (
          <>
            <Flex bg='brand.400' py={4} display={boxDisplayMobile}>
              <Stack alignItems='center' flexDirection='row' justifyContent='space-between' px={[4, 4, 6, 4]} width='100%'>
                <Box mr={2}>
                  <SearchForm />
                </Box>
                <MiniCart onClick={onCartOpen} />
              </Stack>
            </Flex>
            <Box bg='white' boxShadow='0 5px 10px rgba(0,0,0,0.2)'>
              <Container>
                <Flex justifyContent='space-between'>
                  <MenuNavigation offset={offset} />
                  <Box display={['none', 'none', 'none', 'flex']} mt='.7rem' height='100%' minWidth='fit-content' marginLeft={8}>
                    <MiniCart onClick={onCartOpen} />
                  </Box>
                </Flex>
              </Container>
            </Box>
          </>
        )}
      </Box>
      <Box bg='white' mb={16} mt={16}>
        <AnnouncementBanner bannerText={bannerText} />
        <Container>{children}</Container>
      </Box>
      <Cart isOpen={isCartOpen && !isEditingItem} onClose={onCartClose} />
      <FulfilmentModal isOpen={isFulfilmentOpen} onClose={onFulfilmentClose} />
      <ErrorMessage />
    </Box>
  )
})
