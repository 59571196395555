import { actionCreatorFactory } from 'typescript-fsa'
import { LatLon } from 'use-places-autocomplete'
import { GeolocationPosition, GeolocationPositionError } from '../../../models'

const actionCreator = actionCreatorFactory('USER')

export const userLocationReceived = actionCreator<GeolocationPosition>('USER_LOCATION_RECEIVED')
export const userLocationError = actionCreator<GeolocationPositionError>('USER_LOCATION_ERROR')
export const userAddressReceived = actionCreator<string | undefined>('USER_ADDRESS_RECEIVED')
export const userAddressGeocoded = actionCreator<LatLon | undefined>('USER_ADDRESS_GEOCODED')
export const userPostcodeSet = actionCreator<string | undefined>('USER_POSTCODE_SET')
export const userName = actionCreator<string | undefined>('USER_NAME')
export const userEmail = actionCreator<string | undefined>('USER_EMAIL')
export const userPhone = actionCreator<string | undefined>('USER_PHONE')
