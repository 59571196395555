import React, { useContext, useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/core'
import moment from 'moment'
import ellipsize from 'ellipsize'
import { Link } from 'react-router-dom'
import { IoIosImage } from 'react-icons/io'
import { Button } from '../button'
import {
  shadow,
  fontSizeSmall,
  fontSemiBold,
  fontSizeExtraSmall,
  brandColourAsText,
  textColourLight,
  borderRadiusNormal,
  borderRadiusNormalLeftOnly,
  textColourNormal,
} from '../../styles'
import { AppContext } from '../../providers'
import { formatCurrency } from '../../utils/numbers'

export interface CardProps {
  menuItemId: string
  category: string
  title: string
  description: string
  image: string
  price: number
  disableDate: string
  showMenuImages: boolean
}

const CardImage = ({ title, image, showMenuImages }) => {
  const [isLoading, setIsLoading] = useState(true)

  if (!showMenuImages || !image) {
    return null
  }

  return (
    <>
      {isLoading && (
        <Box
          height='auto'
          marginLeft={-15}
          my={-15}
          objectFit='cover'
          objectPosition='center'
          width={120}
          minWidth={120}
          backgroundColor='#d5d5d5'
          {...borderRadiusNormalLeftOnly}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <IoIosImage color='rgba(0,0,0,0.2)' fontSize='3rem' />
        </Box>
      )}
      <Image
        alt={title}
        display={!isLoading ? 'block' : 'none'}
        height='auto'
        marginLeft={-15}
        my={-15}
        objectFit='cover'
        objectPosition='center'
        src={image}
        width={120}
        minWidth={120}
        backgroundColor='#d5d5d5'
        whiteSpace='nowrap'
        overflow='hidden'
        style={{ textIndent: '100%' }}
        {...borderRadiusNormalLeftOnly}
        onLoad={() => {
          setIsLoading(false)
        }}
      />
    </>
  )
}

export const Card = (props) => {
  const { state } = useContext(AppContext)

  const { menuItemId, category, title, description, image, price, disableDate, showMenuImages } = props

  const isMenuItemDisabled = () => {
    if (!disableDate) {
      return false
    }

    return moment().isSame(moment(disableDate, 'DD/MM/YYYY'), 'days')
  }

  const getMenuLink = () => {
    let url = `/menu/${menuItemId}`

    if (state.fulfilmentState.type === 'Dine In') {
      if (state.fulfilmentState.tableNumber) {
        url = `/table/${state.fulfilmentState.tableNumber}/menu/${menuItemId}`
      } else {
        url = `/table/menu/${menuItemId}`
      }
    }

    return url
  }

  const menuLink = getMenuLink()

  return (
    <Link to={menuLink}>
      <Flex
        className={category}
        bg='white'
        flexDir='row'
        height='100%'
        maxHeight='12rem'
        minHeight='10rem'
        overflow='hidden'
        position='relative'
        px={15}
        py={15}
        {...shadow}
        {...borderRadiusNormal}
      >
        <CardImage title={title} image={image} showMenuImages={showMenuImages} />
        <Flex flexDir={['column', 'row']} flexGrow={1} paddingLeft={image && showMenuImages ? 3 : 0}>
          <Flex display='inline-flex' flexDir='column' flexGrow={1}>
            {isMenuItemDisabled() && <Text {...textColourNormal}>SOLD OUT&nbsp;</Text>}
            <Text
              textDecoration={isMenuItemDisabled() ? 'line-through' : 'none'}
              marginBottom={2}
              overflow='hidden'
              {...fontSizeSmall}
              {...fontSemiBold}
              flexShrink={0}
            >
              {title}
            </Text>

            <Text overflow='hidden' position='relative' {...fontSizeExtraSmall} {...textColourLight} mr={1}>
              {image && showMenuImages ? ellipsize(description, 70) : ellipsize(description, 150)}
            </Text>
          </Flex>
          <Flex
            alignItems={['flex-start', 'flex-end']}
            display='inline-flex'
            flexDir={['row', 'column']}
            justifyContent='space-between'
            marginTop={[3, 0]}
          >
            <Text {...fontSemiBold} {...brandColourAsText}>
              {formatCurrency(price)}
            </Text>

            <Button brand size='sm' transparent isRounded>
              + Add
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}
