import React, { FC } from 'react'
import { Router, useHistory, useParams, Redirect } from 'react-router-dom'
import { GroupConfigContext, GroupConfigContextProvider, ThemeContextProvider } from '../../providers'
import { LandingLayout } from '../../layout'
import { LandingItem } from './item'
import { ErrorMessage } from '../../components'

export const LandingRoute: FC = () => {
  const history = useHistory()
  const { groupSlug } = useParams()

  return (
    <Router history={history}>
      <GroupConfigContextProvider groupSlug={groupSlug}>
        <GroupConfigContext.Consumer>
          {({ restaurants, error }) => {
            if (restaurants && restaurants.length < 1) {
              return (
                <ThemeContextProvider>
                  <ErrorMessage hideInitial />
                </ThemeContextProvider>
              )
            }

            if (error) {
              return <Redirect to={`/${groupSlug}`} />
            }

            return (
              <ThemeContextProvider>
                <LandingLayout>
                  <LandingItem />
                </LandingLayout>
              </ThemeContextProvider>
            )
          }}
        </GroupConfigContext.Consumer>
      </GroupConfigContextProvider>
    </Router>
  )
}
