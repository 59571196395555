import 'rc-time-picker/assets/index.css'
import './styles.scss'
import { Box, Flex, Text } from '@chakra-ui/core'
import { TimeIcon } from '@chakra-ui/icons'
import { Moment } from 'moment'
import React, { FC, useContext, useState } from 'react'
import { default as RCTimePicker } from 'rc-time-picker'
import { borderColourLight } from '../../styles'
import { RestaurantContext, DateTimeContext } from '../../providers'
import { Button } from '../button'
import { getClosedHoursByDay, getClosedMinutesByDayByHour } from '../../utils/opening-hours'

export interface TimePickerProps {
  value?: Moment
  onChange?: (date: Moment) => void
  date?: Moment
  showPlaceholder: boolean
  setShowPlaceholder: (showPlaceholder: boolean) => void
}

export const TimePicker: FC<TimePickerProps> = (props) => {
  const { restaurant } = useContext(RestaurantContext)
  const { moment } = useContext(DateTimeContext)
  const { onChange, value, date, showPlaceholder, setShowPlaceholder } = props
  const { typicalEta } = restaurant || {}

  const [open, setOpen] = useState(false)

  const handleChange = (RCDate: Moment) => {
    if (!onChange) {
      return
    }
    onChange(RCDate)
  }

  const getDisabledMinutes = (selectedHour) => {
    if (!date) {
      return []
    }

    // @ts-ignore
    const now = moment().add(typicalEta + 5, 'minute')

    const getClosedMinutesByDay = () => {
      const dayAsNum = date.day()
      const closedMinutesForDay = getClosedMinutesByDayByHour(moment, restaurant, dayAsNum, selectedHour)

      return closedMinutesForDay
    }

    const getMinutesInPast = () => {
      let minutesInPast: number[] = []
      const allMinutes = [0, 15, 30, 45]

      const selectedDateTime = moment(date).startOf('day').add(selectedHour, 'hour')

      // NOTE: if changing between am/pm can result in this anomaly
      if (selectedDateTime.isBefore(now, 'hour')) {
        return allMinutes
      }

      if (selectedDateTime.isBefore(now, 'minute')) {
        let pastSteps = Math.ceil(now.minute() / 15)

        // NOTE: Disable all times if passed the grace period
        // @ts-ignore
        const gracePeriod = 60 - (typicalEta + 5)
        if (now.minutes() > gracePeriod) {
          pastSteps = 4
        }

        minutesInPast = Array.from(Array(pastSteps).keys()).map((e) => e * 15)
      }

      return minutesInPast
    }

    const minutesInPast = getMinutesInPast()
    const closedMinutesForDay = getClosedMinutesByDay()

    const disabledMinutes = [...minutesInPast, ...closedMinutesForDay]
    const uniqueDisabledMinutes = disabledMinutes.filter((hour, index) => disabledMinutes.indexOf(hour) === index)

    return uniqueDisabledMinutes
  }

  const getDisabledHours = () => {
    // @ts-ignore
    const now = moment().add(typicalEta + 5, 'minute')

    const getHoursInPast = () => {
      if (!date) {
        return []
      }

      // NOTE: Don't disable hours if in the future
      if (date.isAfter(now, 'day')) {
        return []
      }

      const start = moment().startOf('day')
      let hoursCount = now.diff(start, 'hours')
      const minutesCount = now.minutes()

      // @ts-ignore
      const gracePeriod = 60 - (typicalEta + 5)

      if (minutesCount > gracePeriod) {
        hoursCount += 1
      }

      const hoursInPast = Array.from(Array(hoursCount).keys())

      return hoursInPast
    }

    const getClosedHours = () => {
      const nowCopy = moment(now)

      if (!date) {
        return []
      }

      const roundedUp = Math.ceil(nowCopy.minute() / 15) * 15
      if (roundedUp) {
        // NOTE: roundup to 15 min interval
        nowCopy.add(roundedUp - nowCopy.minute(), 'minute')
      }

      let closedHoursByDay: number[] = []
      const dayAsNum = date.day()

      closedHoursByDay = getClosedHoursByDay(moment, restaurant, dayAsNum)

      if (date.isAfter(nowCopy, 'day')) {
        return closedHoursByDay
      }

      return closedHoursByDay
    }

    const hoursInPast = getHoursInPast()
    const closedHours = getClosedHours()

    const disabledHours = [...hoursInPast, ...closedHours]
    const uniqueDisabledHours = disabledHours.filter((hour, index) => disabledHours.indexOf(hour) === index)

    // TODO: if the current hour is past 45min, then disable that hour

    return uniqueDisabledHours
  }

  const validateTime = () => {
    // NOTE: Check if Select Time is in past when TimePicker opens

    if (!value || !onChange) {
      return
    }

    const selectedDateTime = date ? moment(date).startOf('day').add(value.hours(), 'hours').add(value.minutes(), 'minutes') : value

    // @ts-ignore
    const newPDT = moment().add(typicalEta + 5, 'minute')
    const roundedUp = Math.ceil(newPDT.minute() / 15) * 15

    if (roundedUp) {
      newPDT.add(roundedUp - newPDT.minute(), 'minute')
    }

    if (date) {
      if (date.isAfter(newPDT, 'day')) {
        return
      }
    }

    if (selectedDateTime.isBefore(newPDT, 'minute')) {
      onChange(newPDT)
    }
  }

  const handleOpen = () => {
    validateTime()
    setOpen(true)
  }

  const hidePlaceholder = () => {
    setShowPlaceholder(false)
    handleOpen()
  }

  return (
    <Flex width='100%' alignItems='center' borderWidth={1} bg='white' py={2} px={4} {...borderColourLight}>
      <TimeIcon color='#718096' boxSize='.75rem' ml={1} mr={2} />
      {showPlaceholder ? (
        <Box ml={2} width='100%' bg='white' onClick={() => hidePlaceholder()}>
          <Text color='#767676' display='inline' fontWeight={300} verticalAlign='middle'>
            Select Time
          </Text>
        </Box>
      ) : (
        <RCTimePicker
          open={open}
          onOpen={handleOpen}
          onClose={() => setOpen(false)}
          placeholder='Select Time'
          value={value}
          allowEmpty={false}
          onChange={handleChange}
          use12Hours
          showSecond={false}
          minuteStep={15}
          disabledHours={getDisabledHours}
          disabledMinutes={getDisabledMinutes}
          addon={(panel) => {
            return (
              <Box
                border='1px solid #e2e8f0'
                boxShadow='0px 0px 8px -4px rgba(0, 0, 0, 0.25)'
                position='relative'
                zIndex='99999999999999999'
                marginTop='-1px'
                backgroundColor='white'
              >
                <Button
                  onClick={() => {
                    // @ts-ignore
                    panel.close()
                  }}
                  width='9rem'
                  size='sm'
                  m={2}
                  brand
                  isRounded
                  reversed
                  type='submit'
                >
                  Set Time
                </Button>
              </Box>
            )
          }}
          // onAmPmChange={handleAmpPmChange} // TODO: install latest version to make use of 'onAmPmChange'. Then prevent time changes when morning is in past.
        />
      )}
    </Flex>
  )
}
