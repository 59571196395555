import { Box } from '@chakra-ui/core'
import { CardElement } from '@stripe/react-stripe-js'
import React, { FC } from 'react'
import { borderColourLight, borderRadiusSmall } from '../../styles'

export interface CreditCardProps {
  onChange?: (e) => void
}

export const CreditCard: FC<CreditCardProps> = (props) => {
  const { onChange } = props
  return (
    <Box padding={[2, 4]} borderWidth={1} {...borderColourLight} {...borderRadiusSmall}>
      <CardElement
        onChange={onChange}
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '16px',
              fontWeight: '300',
              color: '#000000',
              '::placeholder': {
                color: '#A0AEC0',
              },
            },
            invalid: {
              color: '#E53E3D',
            },
          },
        }}
      />
    </Box>
  )
}
