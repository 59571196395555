import React, { FC, useContext, useEffect } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button } from '../button'
import { AppContext, DateTimeContext, RestaurantContext } from '../../providers'
import { setFulfilmentPreferredDateTime } from '../../providers/app/actions'
import { borderRadiusNormal, fontSizeSmall } from '../../styles'
import { getCustomTimeLabel, hasLockedOrderTime } from '../../utils/feature-flags'

export interface FulfilmenTimeToggleProps {
  onChange?: () => void
}

export const FulfilmentTimeToggle: FC<FulfilmenTimeToggleProps> = (props) => {
  const { onChange } = props

  const { moment } = useContext(DateTimeContext)
  const { state, dispatch } = useContext(AppContext)
  const { restaurant } = useContext(RestaurantContext)
  const {
    fulfilmentState: { preferredDateTime },
  } = state

  // @ts-ignore
  const { typicalEta } = restaurant

  useEffect(() => {
    if (!preferredDateTime) {
      return
    }

    const today = moment().add(typicalEta + 5, 'minute')
    const roundedUp = Math.ceil(today.minute() / 15) * 15
    if (roundedUp) {
      today.add(roundedUp - today.minute(), 'minute')
    }

    // NOTE: Reset preferredDateTime if in the past
    if (moment(preferredDateTime).isBefore(today, 'minute')) {
      dispatch(setFulfilmentPreferredDateTime(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredDateTime, typicalEta])

  const timeLabel = moment(preferredDateTime).calendar(null, {
    sameDay: '[Today @ ] hh:mm a',
    nextDay: 'dddd [ @ ] hh:mm a',
    nextWeek: 'dddd [ @ ] hh:mm a',
    sameElse: 'L',
  })

  const customTimeLabel = getCustomTimeLabel(restaurant, preferredDateTime)
  const label = preferredDateTime ? customTimeLabel || timeLabel : hasLockedOrderTime(restaurant) ? 'Select Date' : 'Select Time'

  return (
    <Button
      isFullWidth
      brand
      px={[2, 8]}
      rightIcon={<ChevronDownIcon />}
      transition='none'
      {...fontSizeSmall}
      {...borderRadiusNormal}
      onClick={onChange}
    >
      {label}
    </Button>
  )
}
