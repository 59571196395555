import { ChildDataProps, graphql, compose } from 'react-apollo'
import moment from 'moment'
import React, { createContext, memo } from 'react'
import { MenuItem, Restaurant } from '../../models'
import { menuItemsForParams } from '../../queries'
import { getUniqueCategories } from '../../utils/categories'
import { Modal } from '../../components'

interface Response {
  menuItemsForParams: {
    menuItemsForParams?: MenuItem[]
  }
  error?: Error
  loading: boolean
}

interface Variables {
  restaurant?: Restaurant
  type: string
  date: string
  time: string
}

interface InputProps {
  restaurant?: Restaurant
  type: string
  date: string
  time: string
}

type ComponentProps = InputProps & Response
type ChildProps = ChildDataProps<{}, Response, Variables>

export const MenuContext = createContext<{
  menuItems?: MenuItem[]
  error?: Error
  loading: boolean
  categories?: string[]
}>({
  loading: true,
})

const menuItemsQuery = graphql<InputProps, Response, Variables, ChildProps>(menuItemsForParams, {
  name: 'menuItemsForParams',
  skip: ({ restaurant }) => !restaurant,
  options: ({ restaurant, type, date, time }) => {
    if (!time && !date) {
      return {
        variables: {
          restId: restaurant?.objectId,
          type,
          time: moment().format('h:mm a'),
          date: moment().format('YYYY-MM-DD'),
        },
      }
    }

    return {
      variables: {
        restId: restaurant?.objectId,
        type,
        time,
        date,
      },
    }
  },
})

const Component = memo<ComponentProps>((props) => {
  // eslint-disable-next-line no-shadow
  const { children, menuItemsForParams, error, loading } = props

  const { menuItemsForParams: menuItems } = menuItemsForParams || {}

  const categories = getUniqueCategories(menuItems)

  return (
    <MenuContext.Provider
      value={{
        categories,
        menuItems,
        error,
        loading,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
})

export const MenuContextProvider = compose(menuItemsQuery)(Component)
