import gql from 'graphql-tag'

export const menuItemsForMenu = gql`
  query MenuItemsForMenu($menuId: String!) {
    menuItemsForMenu(menuId: $menuId) {
      objectId
      price
      imageLink
      itemIndex
      itemDescription
      itemTitle
      category
      menuId
    }
  }
`
