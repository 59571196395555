import { Box, Text } from '@chakra-ui/core'
import React from 'react'
import { brandColour } from '../../styles'
import './styles.css'

export const AnnouncementBanner = (props) => {
  const { bannerText } = props

  if (!bannerText) {
    return null
  }

  return (
    <Box className='announcement-banner' mb='40px'>
      <Text className='announcement-banner-text' color={brandColour}>
        {bannerText.replace(/\s?(<br\s?\/?>)\s?/g, '\r\n')}
      </Text>
    </Box>
  )
}
