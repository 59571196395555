import React, { useContext } from 'react'
import { Box, Flex, Heading, Text, useBreakpoint } from '@chakra-ui/core'
import { useRouteMatch } from 'react-router-dom'
import { AppContext, RestaurantContext } from '../../providers'
import { Breakpoint, DeliveryOption, FulfilmentType } from '../../models'
import { setFulfilmentType } from '../../providers/app/actions'
import { brandColour, fontSemiBold, borderRadiusFullLeftOnly, borderRadiusFullRightOnly, transparentWhite, textShadow } from '../../styles'
import './styles.css'
import { routeMap } from '../../utils'
import { Button } from '../button'

export const HeroBanner = () => {
  const { restaurant } = useContext(RestaurantContext)
  const { state, dispatch } = useContext(AppContext)
  const deliverySelected = state.fulfilmentState.type === FulfilmentType.DELIVERY
  const pickUpSelected = state.fulfilmentState.type === FulfilmentType.PICKUP
  const breakpoint = useBreakpoint()
  const hideDelivery = process.env.REACT_APP_DELIVERY_OPTION === DeliveryOption.HIDE

  const { order } = routeMap
  const isOrder = useRouteMatch(order)

  if (!restaurant) return null

  const handleClick = (value: FulfilmentType) => () => {
    dispatch(setFulfilmentType(value))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderButtons = () => {
    if (!restaurant.services.delivery || hideDelivery) return null

    return (
      <>
        <Button
          _hover={{
            bg: deliverySelected ? brandColour : transparentWhite,
          }}
          bg={deliverySelected ? brandColour : transparentWhite}
          border='4px solid'
          borderColor={brandColour}
          boxShadow={deliverySelected ? 'inset 0 0 10px rgba(0,0,0,.2)' : 'none'}
          color={deliverySelected ? 'white' : brandColour}
          fontSize={[16, 18]}
          fontWeight={[400, 600]}
          height='auto'
          px={['8', '16']}
          py={['3', '4']}
          onClick={handleClick(FulfilmentType.DELIVERY)}
          {...borderRadiusFullLeftOnly}
        >
          <Text textTransform='uppercase' data-delivery borderBottom={deliverySelected ? '2px solid white' : 'none'}>
            {FulfilmentType.DELIVERY}
          </Text>
        </Button>
        <Button
          _hover={{
            bg: pickUpSelected ? brandColour : transparentWhite,
          }}
          bg={pickUpSelected ? brandColour : transparentWhite}
          border='4px solid'
          borderColor={brandColour}
          boxShadow={pickUpSelected ? 'inset 0 0 10px rgba(0,0,0,.2)' : 'none'}
          color={pickUpSelected ? 'white' : brandColour}
          fontSize={[16, 18]}
          fontWeight={[400, 600]}
          height='auto'
          px={['8', '16']}
          py={['3', '4']}
          onClick={handleClick(FulfilmentType.PICKUP)}
          {...borderRadiusFullRightOnly}
        >
          <Text textTransform='uppercase' data-pickup borderBottom={pickUpSelected ? '2px solid white' : 'none'}>
            {FulfilmentType.PICKUP}
          </Text>
        </Button>
      </>
    )
  }

  const showDeliveryOptions = () => {
    if (breakpoint === Breakpoint.MOBILE && !restaurant.services.delivery) {
      return (
        <Text color='white' textShadow={textShadow} display={['block', 'none']} {...fontSemiBold}>
          What would you like to order today?
        </Text>
      )
    }
  }

  const getHeight = () => {
    if (isOrder) {
      return [200, 300]
    }
    return [200, 400, 400, 800]
  }

  return (
    <Box
      backgroundImage={`url(${restaurant.imageLink})`}
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      height={getHeight()}
      className='heroImage'
    >
      <Flex flex='1' height='100%'>
        <Flex flexDir='column' flex={1} alignItems='center' justifyContent='center' textAlign='center' width='100%'>
          <Box top={[0, 5, 8, '9rem']} marginTop={[0, 0, 0, '9rem']} marginBottom={[0, 6, 6, '9rem']} position={['unset', 'unset']}>
            <Heading
              data-name
              color='white'
              textShadow={textShadow}
              lineHeight='1'
              fontSize={['3xl', '4xl', '6xl']}
              mb='5'
              display={restaurant.name ? 'block' : 'none'}
            >
              {restaurant.name}
            </Heading>
            <Box>{showDeliveryOptions()}</Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
