import React, { createContext, useState, useEffect } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'

export const PaymentContext = createContext<{
  stripe: Stripe | null
}>({
  stripe: null,
})

export const PaymentContextProvider = (props) => {
  const { children } = props
  const [stripe, setStripe] = useState<Stripe | null>(null)

  const getStripe = async () => {
    const client = await loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`)
    setStripe(client)
  }

  useEffect(() => {
    getStripe()
  }, [])

  return <PaymentContext.Provider value={{ stripe }}>{children}</PaymentContext.Provider>
}
