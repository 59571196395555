import { Box, Flex, useToast } from '@chakra-ui/core'
import { Moment } from 'moment'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../button'
import { ModalProps, Modal } from '../modal'
import { DatePicker } from '../datepicker'
import { TimePicker } from '../timepicker'
import { AppContext, DateTimeContext, RestaurantContext } from '../../providers'
import { setFulfilmentPreferredDateTime, setFulfilmentTime } from '../../providers/app/actions'
import { FulfilmentTime } from '../../models'
import { orderHours } from '../../utils'
import {
  afterNightlyCutoff,
  getInvalidDateMessage,
  getLockedOrderTime,
  getMaxBookingDays,
  getNightlyCutoff,
  hasLockedOrderTime,
  isClosedDateForRestaurant,
  isValidDateForRestaurant,
  orderDaysInAdvance,
} from '../../utils/feature-flags'

export type FulfilmentModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>

interface FulfilmentState {
  date?: Moment
  time?: Moment
}

export const FulfilmentModal: FC<FulfilmentModalProps> = (props) => {
  const toast = useToast()
  const { onClose, isOpen } = props
  const { moment } = useContext(DateTimeContext)
  const { state, dispatch } = useContext(AppContext)
  const { restaurant } = useContext(RestaurantContext)
  const {
    fulfilmentState: { type, preferredDateTime },
  } = state
  const [showTimePlaceholder, setShowTimePlaceholder] = useState(!preferredDateTime)

  // @ts-ignore
  const { typicalEta } = restaurant

  const getPreferredDateTime = useCallback(() => {
    // If restaurant disallows time, set it to a default
    if (hasLockedOrderTime(restaurant)) {
      return moment(getLockedOrderTime(restaurant), 'HH:mm')
    }

    const newPDT = moment().add(typicalEta + 5, 'minute')

    const roundedUp = Math.ceil(newPDT.minute() / 15) * 15

    if (roundedUp) {
      newPDT.add(roundedUp - newPDT.minute(), 'minute')
    }

    return preferredDateTime ? moment(preferredDateTime) : newPDT
  }, [moment, preferredDateTime, restaurant, typicalEta])

  const [fulfilmentState, setFulfilmentState] = useState<FulfilmentState>({
    date: getPreferredDateTime(),
    time: getPreferredDateTime(),
  })

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  const handleUpdate = () => {
    const { date, time } = fulfilmentState
    const requiresTime = !hasLockedOrderTime(restaurant)

    if (requiresTime && (showTimePlaceholder || !date || !time)) {
      showError('Date and time are required', 'Please select a date and time')
      return
    }

    if (!date || !time) {
      showError('Date is required', 'Please select a date')
      return
    }

    const mergedDateTime = moment([date.year(), date.month(), date.date(), time.hour(), time.minutes()])
    const now = moment()

    if (mergedDateTime.isSameOrBefore(now, 'minutes')) {
      showError('Incorrect time', 'Date and time must be in the future')
      return
    }

    // Whether the restaurant requires booking in advance
    const daysInAdvance = orderDaysInAdvance(restaurant)
    if (daysInAdvance > 0) {
      // Check order is at least X days in advance
      const daysUntilOrder = mergedDateTime.diff(moment().startOf('day'), 'days')
      if (daysUntilOrder < daysInAdvance) {
        showError(
          'Invalid date',
          `Order must be booked at least ${daysInAdvance} day${daysInAdvance === 1 ? '' : 's'} in advance`,
        )
        return
      }

      // Cut off orders placed after a certain time
      const nightlyCutoff = getNightlyCutoff(restaurant)
      if (daysUntilOrder === 1 && nightlyCutoff !== false) {
        if (afterNightlyCutoff(moment(), restaurant)) {
          showError(
            'Invalid date',
            `Next day orders can only be placed before ${moment()
              .set({ hour: nightlyCutoff / 100, minute: nightlyCutoff % 100 })
              .format('ha')}`,
          )
          return
        }
      }
    }

    const nowWithEta = moment().add(typicalEta + 5, 'minute')
    const roundedUp = Math.ceil(nowWithEta.minute() / 15) * 15
    if (roundedUp) {
      // NOTE: roundup to 15 min interval
      nowWithEta.add(roundedUp - nowWithEta.minute(), 'minute')
    }
    const timeExpiredAt = moment(nowWithEta).subtract(15, 'minutes').format('h:mm A')

    if (mergedDateTime.isBefore(nowWithEta, 'minutes')) {
      showError('Incorrect time', `Select a time after ${timeExpiredAt}`)
      return
    }

    if (!mergedDateTime.isValid()) {
      showError('Date is invalid', 'Please enter a valid date and time')
      return
    }

    const isAbleToOrder = orderHours(restaurant, moment, mergedDateTime)

    if (!isAbleToOrder) {
      showError('Incorrect Time', 'You cannot order outside of the opening hours')
      return
    }

    if (isClosedDateForRestaurant(restaurant, mergedDateTime)) {
      showError('Please choose another date', 'The venue is closed')
      return
    }

    if (!isValidDateForRestaurant(restaurant, mergedDateTime)) {
      const invalidDateMessage = getInvalidDateMessage(restaurant)
      showError(invalidDateMessage.title, invalidDateMessage.message)
      return
    }

    dispatch(setFulfilmentPreferredDateTime(mergedDateTime, mergedDateTime))
    dispatch(setFulfilmentTime(FulfilmentTime.LATER_TODAY))
    onClose()
  }

  const handleDateChange = (date: Moment) => {
    if (fulfilmentState.date === date) return

    setFulfilmentState((prevState: FulfilmentState) => ({
      ...prevState,
      date,
    }))
  }

  const handleTimeChange = (time: Moment) => {
    if (fulfilmentState.time === time) return

    setFulfilmentState((prevState: FulfilmentState) => ({
      ...prevState,
      time,
    }))
  }

  useEffect(() => {
    if (isOpen) {
      return
    }

    if (!preferredDateTime && !showTimePlaceholder) {
      setShowTimePlaceholder(true)
    }
  }, [isOpen, preferredDateTime, showTimePlaceholder])

  useEffect(() => {
    // NOTE: Check if Select Time is in past when Modal loads

    if (!isOpen || showTimePlaceholder || !preferredDateTime) {
      return
    }

    const newPDT = moment().add(typicalEta + 5, 'minute')
    const roundedUp = Math.ceil(newPDT.minute() / 15) * 15

    if (roundedUp) {
      newPDT.add(roundedUp - newPDT.minute(), 'minute')
    }

    if (moment(preferredDateTime).isAfter(newPDT, 'day')) {
      return
    }

    if (moment(preferredDateTime).isBefore(newPDT, 'minute') && fulfilmentState.time) {
      if (!fulfilmentState.time) {
        return
      }

      if (fulfilmentState.time.isSame(newPDT, 'minute')) {
        return
      }

      setFulfilmentState({
        date: newPDT,
        time: newPDT,
      })
    }
  }, [fulfilmentState.time, getPreferredDateTime, isOpen, moment, preferredDateTime, showTimePlaceholder, typicalEta])

  const nightlyCutoff = getNightlyCutoff(restaurant)

  return (
    <Modal
      headerText={type}
      showCloseButton
      overflow='initial'
      placement='bottom'
      size='xl'
      isDrawerOnMobile
      isCentered
      borderRadius
      isFullHeight
      {...props}
    >
      {orderDaysInAdvance(restaurant) > 0 && (
        <Box style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
          Orders must be booked at least {orderDaysInAdvance(restaurant)} day
          {orderDaysInAdvance(restaurant) === 1 ? '' : 's'} in advance.
        </Box>
      )}
      {nightlyCutoff !== false && (
        <Box style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
          Next-day orders must be booked before{' '}
          {moment()
            .set({ hour: nightlyCutoff / 100, minute: nightlyCutoff % 100 })
            .format('h:ma')}
          .
        </Box>
      )}
      <Flex
        flexDirection={['column', 'row']}
        alignItems='flex-start'
        justifyContent={['flex-start', 'center']}
        padding={6}
      >
        <Box width='100%' mr={[0, 2]} mb={[2, 0]}>
          <DatePicker
            value={fulfilmentState.date}
            onChange={handleDateChange}
            maxBookingDays={getMaxBookingDays(restaurant)}
          />
        </Box>
        {!hasLockedOrderTime(restaurant) && (
          <Box width='100%'>
            <TimePicker
              value={fulfilmentState.time}
              date={fulfilmentState.date}
              onChange={handleTimeChange}
              setShowPlaceholder={setShowTimePlaceholder}
              showPlaceholder={showTimePlaceholder}
            />
          </Box>
        )}
      </Flex>
      <Flex justifyContent='center' px={6} paddingTop={0} paddingBottom={6}>
        <Button reversed brand isRounded onClick={handleUpdate} mr={2}>
          Update
        </Button>
      </Flex>
    </Modal>
  )
}
