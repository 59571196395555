import gql from 'graphql-tag'

export const subscribeToOrder = gql`
  subscription SubscribeToOrder($orderId: String!) {
    subscribeToOrder(orderId: $orderId) {
      orderId
      status
    }
  }
`
