import gql from 'graphql-tag'

export const optionsForMenuItem = gql`
  query OptionsForMenuItem($menuItemId: String!) {
    optionsForMenuItem(menuItemId: $menuItemId) {
      objectId
      menuItemId
      optionIndex
      optionTitle
      optionDescription
      minSelections
      maxSelections
      mandatory
      choices
    }
  }
`
