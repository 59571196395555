const guidExpression = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
const menuItemRoute = `/menu/:itemId(${guidExpression})`
const tableOrderNumRoute = `/table/:tableNumber([A-Za-z0-9_-]+)`
const tableOrderRoute = `/table`

export const routeMap = {
  root: [`/`, `/table`, tableOrderNumRoute],
  table: [tableOrderRoute, tableOrderNumRoute],
  menu: [
    `/checkout`,
    menuItemRoute,
    `${tableOrderRoute}/checkout`,
    `${tableOrderRoute}${menuItemRoute}`,
    `${tableOrderNumRoute}/checkout`,
    `${tableOrderNumRoute}${menuItemRoute}`,
  ],
  menuItem: [menuItemRoute, `${tableOrderRoute}${menuItemRoute}`, `${tableOrderNumRoute}${menuItemRoute}`],
  checkout: [`/checkout`, `${tableOrderNumRoute}/checkout`],
  order: [`/order/:orderId(${guidExpression})`, `${tableOrderNumRoute}/order/:orderId(${guidExpression})`],
  groupSlug: [`/group/:groupSlug`],
  group: [`/group`],
}
