export const brandColour = 'brand.500'
export const brandColourLight = 'brand.400'
export const brandColourDark = 'brand.600'
export const brandColourText = 'brandText.50'
export const transparentWhite = 'whiteAlpha.800'
export const greyNormal = 'gray.800'
export const greyLight = 'gray.500'
export const greyDark = 'gray.900'
export const greyExtraLight = 'gray.400'

export const brandColourAsBackground = {
  bg: brandColour,
  color: brandColourText,
}

export const brandColourAsText = {
  color: brandColour,
}

export const textColourNormal = {
  color: greyNormal,
}

export const textColourLight = {
  color: greyLight,
}

export const brandColourTextAsBackground = {
  bg: brandColourText,
}
