import { Elements } from '@stripe/react-stripe-js'
import React, { FC, useContext, useEffect, useRef } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import { MenuListRoute } from './list'
import { ErrorMessage } from '../../components'
import { RestaurantLayout } from '../../layout'
import { AppContext, PaymentContext, RestaurantContext } from '../../providers'
import { clearCart } from '../../providers/app/actions'
import { routeMap } from '../../utils'

const checkValidTableOrder = (matchTableOrder, restaurant) => {
  if (restaurant) {
    if (matchTableOrder) {
      if (restaurant.services.tableOrdering) {
        return true
      }
      console.error('Restaurant does not support table ordering')
      return false
    }
    return true
  }
  return false
}

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const MenuRoute: FC = () => {
  const history = useHistory()

  const { stripe } = useContext(PaymentContext)
  const { isValidRestaurant, loading, restaurant } = useContext(RestaurantContext)
  const { dispatch, state } = useContext(AppContext)
  const { table } = routeMap

  const prevState = usePrevious(state)

  useEffect(() => {
    if (!prevState) {
      return
    }

    // NOTE: clear cart if swapping from DINEIN to PICKUP/DELIVERY
    if (
      // @ts-ignore: Object is possibly 'undefined'
      prevState.fulfilmentState.type !== state.fulfilmentState.type &&
      // @ts-ignore: Object is possibly 'undefined'
      (prevState.fulfilmentState.type === 'Dine In' || state.fulfilmentState.type === 'Dine In')
    ) {
      dispatch(clearCart())
    }
  }, [dispatch, prevState, state.fulfilmentState.type])

  const matchTableOrder = matchPath(history.location.pathname, { path: table })
  const isValidTableOrder = checkValidTableOrder(matchTableOrder, restaurant)

  if ((!loading && !isValidRestaurant) || (!loading && !isValidTableOrder)) {
    // eslint-disable-next-line no-console
    console.log('Either restaurant or table order is not valid')
    return <ErrorMessage hideInitial />
  }

  return (
    <RestaurantLayout>
      <Elements stripe={stripe}>
        <MenuListRoute />
      </Elements>
    </RestaurantLayout>
  )
}
