import { brandColourText, brandColour, brandColourLight } from './colours'

// Button colour variants
export const button = {
  bg: 'gray.100',
  color: 'gray.600',
  border: '1px solid',
  borderColor: 'gray.100',
  _hover: {
    bg: 'gray.50',
    color: 'gray.600',
  },
  _focus: {
    bg: 'gray.50',
    color: 'gray.600',
  },
  _active: {
    bg: 'gray.50',
    color: 'gray.600',
  },
}

export const buttonBrand = {
  bg: brandColourText,
  color: brandColour,
  border: '1px solid',
  borderColor: brandColourText,
  _hover: {
    bg: brandColourText,
    color: brandColourLight,
  },
  _focus: {
    bg: brandColourText,
    color: brandColourLight,
  },
  _active: {
    bg: brandColourText,
    color: brandColourLight,
  },
}

export const buttonReversed = {
  bg: button.color,
  color: button.bg,
  border: '1px solid',
  borderColor: button.bg,
  _hover: {
    bg: button._hover.color,
    color: button._hover.bg,
  },
  _focus: {
    bg: button._focus.color,
    color: button._focus.bg,
  },
  _active: {
    bg: button._active.color,
    color: button._active.bg,
  },
}

export const buttonBrandReversed = {
  bg: buttonBrand.color,
  color: buttonBrand.bg,
  border: '1px solid',
  borderColor: buttonBrand.color,
  _hover: {
    bg: buttonBrand._hover.color,
    color: buttonBrand._hover.bg,
  },
  _focus: {
    bg: buttonBrand._focus.color,
    color: buttonBrand._focus.bg,
  },
  _active: {
    bg: buttonBrand._active.color,
    color: buttonBrand._active.bg,
  },
}

export const buttonTransparent = {
  bg: 'transparent',
  border: '1px solid',
  borderColor: button.color,
  color: button.color,
  _hover: {
    bg: button._hover.color,
    color: button._hover.bg,
  },
  _focus: {
    bg: button._focus.color,
    color: button._focus.bg,
  },
  _active: {
    bg: button._active.color,
    color: button._active.bg,
  },
}

export const buttonTransparentNoBorder = {
  bg: 'transparent',
  border: 'none',
  color: button.color,
  _hover: {
    bg: 'transparent',
    color: 'gray.600',
  },
  _focus: {
    bg: 'transparent',
    color: 'gray.600',
  },
  _active: {
    bg: 'transparent',
    color: 'gray.600',
  },
}

export const buttonBrandNoBorder = {
  bg: 'transparent',
  border: 'none',
  color: button.color,
}

export const buttonBrandTransparent = {
  bg: 'transparent',
  border: '1px solid',
  borderColor: buttonBrand.color,
  color: buttonBrand.color,
  _hover: {
    bg: buttonBrand._hover.color,
    color: buttonBrand._hover.bg,
  },
  _focus: {
    bg: buttonBrand._focus.color,
    color: buttonBrand._focus.bg,
  },
  _active: {
    bg: buttonBrand._active.color,
    color: buttonBrand._active.bg,
  },
}
