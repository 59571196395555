import React, { ChangeEvent, FC, useContext, useState, useEffect } from 'react'
import { Field, Formik, FormikHelpers } from 'formik'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import { FaPhoneAlt, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import { BsFillPersonFill } from 'react-icons/bs'
import usePlacesAutocomplete, { GeocodeResult, LatLon, getGeocode, getLatLng } from 'use-places-autocomplete'
import { Moment } from 'moment'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/core'
import { Modal } from '../modal'
import { Button } from '../button'
import { CreditCard } from '../credit-card'
import { AppContext, DateTimeContext, RestaurantContext } from '../../providers'
import { DatePicker } from '../datepicker'
import { TimePicker } from '../timepicker'
import {
  orderIsSubmitting,
  setFulfilmentPreferredDateTime,
  userName,
  userAddressGeocoded,
  userAddressReceived,
  userPostcodeSet,
  userPhone,
  userEmail,
} from '../../providers/app/actions'
import {
  textColourNormal,
  borderColourLight,
  borderColourDefault,
  fontLight,
  fontSizeNormal,
  greyLight,
} from '../../styles'
import { DeliveryOption, FulfilmentTime, FulfilmentType } from '../../models'
import { orderHours, hasValidPostcode, stripForeignCharacters } from '../../utils'
import PaymentRequestButton from '../payment-request-button'
import { RollbarErrorTracking } from '../rollbar'
import {
  afterNightlyCutoff,
  allowChangingDeliveryAddressAtCheckout,
  getInvalidDateMessage,
  getMaxBookingDays,
  getNightlyCutoff,
  getPostcodeValidationErrorMessage,
  hasLockedOrderTime,
  isClosedDateForRestaurant,
  isValidDateForRestaurant,
  meetsMinimumDelivery,
  mustChooseDeliveryDate,
  orderDaysInAdvance,
  validateCustomPostcodes,
} from '../../utils/feature-flags'

export interface PaymentModalProps {
  isOpen: boolean
  isLoading?: boolean
  isPrLoading?: boolean
  error?: boolean
  onClose: () => void
  onSubmit: () => void
  onSubmitting?: () => void
  onPrSubmitting?: () => void
  onPayRequestSubmit: (paymentMethodId: string, complete: () => void) => void
}

export interface Values {
  fullName: string
  phone: string
  email: string
  address: string
  date: string
  time: string
}

interface FulfilmentState {
  date?: Moment
  time?: Moment
}

export const PaymentModal: FC<PaymentModalProps> = (props) => {
  const { isLoading, isPrLoading, isOpen, onClose, onSubmit, onSubmitting, onPrSubmitting, onPayRequestSubmit } = props
  const { state, dispatch } = useContext(AppContext)
  const { restaurant } = useContext(RestaurantContext)
  // @ts-ignore
  const { typicalEta } = restaurant
  const { moment } = useContext(DateTimeContext)
  const [disabled, setDisabled] = useState(true)
  const stackPaddingY = useBreakpointValue({ base: 4, sm: 8 })
  const [error, setError] = useState()
  const {
    userState,
    fulfilmentState,
    cartState: { isSubmitting },
  } = state
  const [postCode, setPostcode] = useState(state.userState.addressPostcode)
  const isPickup = fulfilmentState.type === FulfilmentType.PICKUP
  const isDineIn = fulfilmentState.type === FulfilmentType.DINE_IN
  const toast = useToast()
  const [showTimePlaceholder, setShowTimePlaceholder] = useState(!fulfilmentState.preferredDateTime)

  const getPreferredDateTime = () => {
    const newPDT = moment().add(typicalEta + 5, 'minute')

    const roundedUp = Math.ceil(newPDT.minute() / 15) * 15

    if (roundedUp) {
      newPDT.add(roundedUp - newPDT.minute(), 'minute')
    }

    return fulfilmentState.preferredDateTime ? moment(fulfilmentState.preferredDateTime) : newPDT
  }

  const [updatedFulfilmentState, setFulfilmentState] = useState<FulfilmentState>({
    date: getPreferredDateTime(),
    time: getPreferredDateTime(),
  })

  const showDateTime = !isDineIn

  const [prDelayTrigger, setPrDelayTrigger] = useState({})
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: `${process.env.REACT_APP_COUNTRY_CODE}`,
      },
    },
    debounce: 300,
  })
  const [isEditingAddress, setEditAddress] = useState(false)
  const hideDelivery = process.env.REACT_APP_DELIVERY_OPTION === DeliveryOption.HIDE

  useEffect(() => {
    if (isSubmitting) {
      onSubmit()
      dispatch(orderIsSubmitting(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  useEffect(() => {
    // NOTE: this UseEffect with the 'prDelayTrigger' is a hack
    // to wait for the dispatch of the 'user details' before making the 'onPayRequestSubmit' call

    if (Object.keys(prDelayTrigger).length > 0) {
      // @ts-ignore
      const { paymentMethodId, complete } = prDelayTrigger

      // @ts-ignore
      onPrSubmitting()
      onPayRequestSubmit(paymentMethodId, complete)
      setPrDelayTrigger({})
    }
  }, [onPayRequestSubmit, onPrSubmitting, prDelayTrigger])

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  if (!restaurant) return null

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueWithSpecialCharactersRemoved = stripForeignCharacters(e.target.value)
    clearSuggestions()
    setValue(valueWithSpecialCharactersRemoved)
    dispatch(userAddressGeocoded(undefined))
    dispatch(userAddressReceived(undefined))
    dispatch(userPostcodeSet(undefined))
    setPostcode('')
  }

  const handleDateChange = (date: Moment) => {
    setFulfilmentState((prevState: FulfilmentState) => ({
      ...prevState,
      date,
    }))
  }

  const handleTimeChange = (time: Moment) => {
    setFulfilmentState((prevState: FulfilmentState) => ({
      ...prevState,
      time,
    }))
  }

  const validateField = (fieldName: string) => (fieldValue: string): string | undefined => {
    if (!fieldValue) {
      return `Please provide a valid ${fieldName}`
    }
  }

  const validateAddress = (fieldName: string) => {
    if (!value) {
      return `Please provide a valid ${fieldName}`
    }
  }

  const validatePhoneNumber = (fieldName: string) => (fieldValue: string): string | undefined => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      fieldValue,
      `${process.env.REACT_APP_COUNTRY_CODE}`.toUpperCase() as CountryCode,
    )
    if (!parsedPhoneNumber) {
      return `A ${fieldName} is required`
    }

    if (!parsedPhoneNumber.isPossible()) {
      return `Please provide a valid ${fieldName}`
    }
  }

  const validateEmail = (fieldName: string) => (fieldValue: string): string | undefined => {
    if (!fieldValue || !/.+@.+\..+/i.test(fieldValue)) {
      return `Please provide a valid ${fieldName}`
    }
  }

  const handleSuggestedLocationClick = ({ description }) => async () => {
    clearSuggestions()
    setValue(description)
    const geocodingResult: GeocodeResult[] = await getGeocode({ address: description })
    const latLng: LatLon = await getLatLng(geocodingResult[0])
    const reverseGeocodingResult = await getGeocode({
      location: latLng,
    })
    dispatch(userAddressGeocoded(latLng))
    setEditAddress(true)
    const [bestResult] = reverseGeocodingResult
    const userPostcode = bestResult.address_components[6].long_name
    setPostcode(userPostcode)
    dispatch(userPostcodeSet(userPostcode))

    if (!hasValidPostcode(restaurant, userPostcode)) {
      showError(
        'Sorry, we do not deliver to your postcode',
        'Please choose a different delivery address or switch to pickup.',
      )
      return
    }
    dispatch(userAddressReceived(description))
  }

  const renderSuggestions = () => {
    if (status !== 'OK' || hasValidPostcode(restaurant, postCode)) {
      return null
    }

    return (
      <Flex flexDirection='column' marginTop={3}>
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion

          return (
            <Flex key={place_id} marginTop={1}>
              <Button
                isFullWidth
                data-autocomplete-suggestion
                justifyContent='flex-start'
                onClick={handleSuggestedLocationClick(suggestion)}
              >
                <Text isTruncated>
                  {main_text} {secondary_text}
                </Text>
              </Button>
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const renderAddress = () => {
    if (isPickup || isDineIn || !hideDelivery) return null

    return (
      <Flex flexDirection='column'>
        <Field name='address' validate={validateAddress('Address')}>
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.address && form.touched.address}>
              <InputGroup>
                <InputLeftElement ml={2} padding={3} minHeight={10} color={greyLight} {...fontSizeNormal}>
                  <FaLocationArrow />
                </InputLeftElement>
                <Input
                  {...field}
                  type='address'
                  placeholder='Address'
                  paddingLeft={12}
                  isDisabled={!ready || !allowChangingDeliveryAddressAtCheckout(restaurant)}
                  onChange={handleAddressChange}
                  value={`${state.userState.address || value}`}
                  {...borderColourDefault}
                  {...textColourNormal}
                  {...borderColourLight}
                  {...fontLight}
                />
              </InputGroup>
              <FormErrorMessage>{form.errors.address}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Flex flexDirection='column' order={3}>
          {renderSuggestions()}
        </Flex>
      </Flex>
    )
  }

  const handleValidateForm = (values, setTouched) => {
    const allValuesTrue = Object.keys(values).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {})

    setTouched(allValuesTrue, true)
  }

  const validateAndSubmitPayRequest = async (showPayRequest, values, setTouched, validateForm) => {
    RollbarErrorTracking.logInfo('validate and submit Pay Request')

    const { fullName, address, email, phone } = values

    handleValidateForm(values, setTouched)

    const formErrors = await validateForm(values)
    const hasErrors = Object.values(formErrors).some((el) => el)

    if (hasErrors) {
      return
    }

    const { date, time } = updatedFulfilmentState
    const requiresTime = !hasLockedOrderTime(restaurant)

    if (!isDineIn) {
      if (requiresTime && (showTimePlaceholder || !date || !time)) {
        showError('Date and time are required', 'Please select a date and time')
        return
      }

      if (!date || !time) {
        showError('Date is required', 'Please select a date')
        return
      }

      const now = moment()
      const mergedDateTime = moment([date.year(), date.month(), date.date(), time.hour(), time.minutes()])

      if (mergedDateTime.isSameOrBefore(now, 'minutes')) {
        showError('Incorrect time', 'Date and time must be in the future')
        return
      }

      const nowWithEta = moment().add(typicalEta + 5, 'minute')
      const roundedUp = Math.ceil(nowWithEta.minute() / 15) * 15
      if (roundedUp) {
        // NOTE: roundup to 15 min interval
        nowWithEta.add(roundedUp - nowWithEta.minute(), 'minute')
      }
      const timeExpiredAt = moment(nowWithEta).subtract(15, 'minutes').format('h:mm A')

      if (mergedDateTime.isBefore(nowWithEta, 'minutes')) {
        showError('Incorrect time', `Select a time after ${timeExpiredAt}`)
        return
      }

      if (!mergedDateTime.isValid()) {
        showError('Date is invalid', 'Please enter a valid date and time')
        return
      }

      const isAbleToOrder = orderHours(restaurant, moment, mergedDateTime)

      if (!isAbleToOrder) {
        showError('Date and time outside of opening hours', 'Please select a date and time within opening hours')
        return
      }

      if (isClosedDateForRestaurant(restaurant, mergedDateTime)) {
        showError('Please choose another date', 'The venue is closed')
        return
      }

      // Whether the restaurant requires booking in advance
      const daysInAdvance = orderDaysInAdvance(restaurant)
      if (daysInAdvance > 0) {
        if (mergedDateTime.isBefore(moment().startOf('day').add(daysInAdvance, 'days'), 'minutes')) {
          showError(
            'Incorrect time',
            `Order must be booked at least ${daysInAdvance} day${daysInAdvance === 1 ? '' : 's'} in advance`,
          )
          return
        }
      }

      if (!isPickup) {
        const validCustomPostcode = validateCustomPostcodes(restaurant, state.userState.addressPostcode)

        if (!validCustomPostcode) {
          showError(
            'Sorry, we do not deliver to your postcode',
            'Please choose a different delivery address or switch to pickup.',
          )
          return
        }
      }

      if (restaurant.deliveryPostcodes && !isPickup) {
        if (!state.userState.address) {
          showError('Address is invalid', 'Please enter a delivery address')
          return
        }

        if (!hasValidPostcode(restaurant, state.userState.addressPostcode)) {
          showError(
            'Sorry, we do not deliver to your postcode',
            'Please choose a different delivery address or switch to pickup.',
          )
          return
        }

        dispatch(userAddressReceived(state.userState.address))
        dispatch(setFulfilmentPreferredDateTime(mergedDateTime))

        dispatch(userName(fullName))
        dispatch(userPhone(phone))
        dispatch(userEmail(email))

        showPayRequest()
        return
      }

      if ((!restaurant.deliveryPostcodes || restaurant.deliveryPostcodes === []) && !isPickup) {
        dispatch(userAddressReceived(address))
        dispatch(setFulfilmentPreferredDateTime(mergedDateTime))

        dispatch(userName(fullName))
        dispatch(userPhone(phone))
        dispatch(userEmail(email))

        showPayRequest()
        return
      }

      dispatch(setFulfilmentPreferredDateTime(mergedDateTime))
      dispatch(userName(fullName))
      dispatch(userPhone(phone))
      dispatch(userEmail(email))

      showPayRequest()
      return
    }

    dispatch(userName(fullName))
    dispatch(userPhone(phone))
    dispatch(userEmail(email))

    showPayRequest()
  }

  const handlePayRequest = (paymentMethodId, complete) => {
    setPrDelayTrigger({ paymentMethodId, complete })
  }

  const handleUpdate = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    RollbarErrorTracking.logInfo('validate and submit Credit Card')

    const { fullName, address, email, phone } = values
    const { date, time } = updatedFulfilmentState
    const requiresTime = !hasLockedOrderTime(restaurant)

    if (!isDineIn) {
      if (requiresTime && (showTimePlaceholder || !date || !time)) {
        showError('Date and time are required', 'Please select a date and time')
        return
      }

      if (!date || !time) {
        showError('Date is required', 'Please select a date')
        return
      }

      const now = moment()
      const mergedDateTime = moment([date.year(), date.month(), date.date(), time.hour(), time.minutes()])

      if (mergedDateTime.isSameOrBefore(now, 'minutes')) {
        showError('Incorrect time', 'Date and time must be in the future')
        return
      }

      // Cut off orders placed after a certain time when ordering for the next day
      const nightlyCutoff = getNightlyCutoff(restaurant)
      const daysUntilOrder = mergedDateTime.diff(moment().startOf('day'), 'days')
      if (daysUntilOrder === 1 && nightlyCutoff !== false) {
        if (afterNightlyCutoff(moment(), restaurant)) {
          showError(
            'Invalid date',
            `Next day orders can only be placed before ${moment()
              .set({ hour: nightlyCutoff / 100, minute: nightlyCutoff % 100 })
              .format('ha')}`,
          )
          return
        }
      }

      // Whether the restaurant requires booking in advance
      const daysInAdvance = orderDaysInAdvance(restaurant)
      if (daysInAdvance > 0) {
        if (mergedDateTime.isBefore(moment().startOf('day').add(daysInAdvance, 'days'), 'minutes')) {
          showError(
            'Incorrect time',
            `Order must be booked at least ${daysInAdvance} day${daysInAdvance === 1 ? '' : 's'} in advance`,
          )
          return
        }
      }

      const nowWithEta = moment().add(typicalEta + 5, 'minute')
      const roundedUp = Math.ceil(nowWithEta.minute() / 15) * 15
      if (roundedUp) {
        // NOTE: roundup to 15 min interval
        nowWithEta.add(roundedUp - nowWithEta.minute(), 'minute')
      }
      const timeExpiredAt = moment(nowWithEta).subtract(15, 'minutes').format('h:mm A')

      if (mergedDateTime.isBefore(nowWithEta, 'minutes')) {
        showError('Incorrect time', `Select a time after ${timeExpiredAt}`)
        return
      }

      if (!mergedDateTime.isValid()) {
        showError('Date is invalid', 'Please enter a valid date and time')
        return
      }

      const isAbleToOrder = orderHours(restaurant, moment, mergedDateTime)

      if (!isAbleToOrder) {
        showError('Date and time outside of opening hours', 'Please select a date and time within opening hours')
        return
      }

      if (isClosedDateForRestaurant(restaurant, mergedDateTime)) {
        showError('Please choose another date', 'The venue is closed')
        return
      }

      if (!isValidDateForRestaurant(restaurant, mergedDateTime)) {
        const invalidDateMessage = getInvalidDateMessage(restaurant)
        showError(invalidDateMessage.title, invalidDateMessage.message)
        return
      }

      // Errors that should prevent checkout in the first place
      const aboveMinimumDelivery = meetsMinimumDelivery(restaurant, state.cartState.subtotal)
      if (!aboveMinimumDelivery) {
        showError('Order does not meet minimum delivery amount', 'Please order an amount greater than $80')
        return
      }

      const validCustomPostcode = validateCustomPostcodes(restaurant, state.userState.addressPostcode)
      if (!validCustomPostcode) {
        showError(
          'Invalid postcode',
          getPostcodeValidationErrorMessage(
            restaurant,
            state.userState.addressPostcode,
            state.fulfilmentState.preferredDateTime,
          ),
        )
        return
      }

      const isValidDate = !mustChooseDeliveryDate(restaurant) || state.fulfilmentState.time !== FulfilmentTime.ASAP
      if (!isValidDate) {
        showError('Invalid date', 'You must set a delivery date for your order')
        return
      }

      if (restaurant.deliveryPostcodes && !isPickup) {
        if (!state.userState.address) {
          showError('Address is invalid', 'Please enter a delivery address')
          return
        }

        if (!hasValidPostcode(restaurant, state.userState.addressPostcode)) {
          showError(
            'Sorry, we do not deliver to your postcode',
            'Please choose a different delivery address or switch to pickup.',
          )
          return
        }

        if (
          onSubmitting &&
          fullName &&
          email &&
          phone &&
          date &&
          time &&
          state.userState.address &&
          hasValidPostcode(restaurant, state.userState.addressPostcode) &&
          isAbleToOrder &&
          !mergedDateTime.isSameOrBefore(now, 'minutes') &&
          mergedDateTime.isValid()
        ) {
          onSubmitting()
        }

        dispatch(userAddressReceived(state.userState.address))
        dispatch(setFulfilmentPreferredDateTime(mergedDateTime))

        dispatch(userName(fullName))
        dispatch(userPhone(phone))
        dispatch(userEmail(email))
        dispatch(orderIsSubmitting(true))
        setSubmitting(false)
        return
      }

      if ((!restaurant.deliveryPostcodes || restaurant.deliveryPostcodes === []) && !isPickup) {
        if (
          onSubmitting &&
          fullName &&
          email &&
          phone &&
          date &&
          time &&
          address &&
          isAbleToOrder &&
          !mergedDateTime.isSameOrBefore(now, 'minutes') &&
          mergedDateTime.isValid()
        ) {
          onSubmitting()
        }

        dispatch(userAddressReceived(address))
        dispatch(setFulfilmentPreferredDateTime(mergedDateTime))

        dispatch(userName(fullName))
        dispatch(userPhone(phone))
        dispatch(userEmail(email))
        dispatch(orderIsSubmitting(true))
        setSubmitting(false)
        return
      }

      if (onSubmitting && fullName && email && phone && date && time) {
        onSubmitting()
      }

      dispatch(setFulfilmentPreferredDateTime(mergedDateTime))
      dispatch(userName(fullName))
      dispatch(userPhone(phone))
      dispatch(userEmail(email))
      dispatch(orderIsSubmitting(true))
      setSubmitting(false)
      return
    }

    if (onSubmitting && fullName && email && phone && date && time) {
      onSubmitting()
    }

    dispatch(userName(fullName))
    dispatch(userPhone(phone))
    dispatch(userEmail(email))
    dispatch(orderIsSubmitting(true))
    setSubmitting(false)
  }

  const handleCreditCardChange = (event) => {
    setDisabled(event.empty || !!event.error)
    setError(event.error ? event.error.message : '')
  }

  const defaultValues: Pick<Values, 'fullName' | 'phone' | 'email' | 'address' | 'date' | 'time'> = {
    fullName: userState.fullName || '',
    phone: userState.phone || '',
    email: userState.email || '',
    address: state.userState.address || '',
    date: moment(updatedFulfilmentState.date).format('DD/MM/YYYY'),
    time: moment(updatedFulfilmentState.time).format('hh:mm A'),
  }

  const mapDefaultValuesToFormikOptions = () => {
    return {
      fullName: defaultValues.fullName,
      phone: defaultValues.phone,
      email: defaultValues.email,
      address: defaultValues.address,
      date: defaultValues.date,
      time: defaultValues.time,
    }
  }

  if (isEditingAddress) {
    defaultValues.address = value
  }

  return (
    <Modal
      headerText={`Add ${fulfilmentState.type} Details`}
      maxWidth='546px'
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom'
      zIndex={2}
      isDrawerOnMobile
      showCloseButton
      isCentered
      overflow='initial'
      borderRadius
    >
      <Flex justifyContent='center'>
        <Formik initialValues={mapDefaultValuesToFormikOptions()} onSubmit={handleUpdate}>
          {({ handleSubmit, validateForm, setTouched, values }) => (
            <form style={{ flex: 1 }} onSubmit={handleSubmit}>
              <Stack direction='column' spacing={4} py={stackPaddingY} px={10} mb={6}>
                {showDateTime && (
                  <Stack direction={['column', 'row']} spacing={4}>
                    <Field name='date'>
                      {() => (
                        <FormControl>
                          <InputGroup zIndex={2}>
                            <DatePicker
                              value={moment(fulfilmentState.preferredDateTime)}
                              onChange={handleDateChange}
                              maxBookingDays={getMaxBookingDays(restaurant)}
                            />
                          </InputGroup>
                        </FormControl>
                      )}
                    </Field>
                    {!hasLockedOrderTime(restaurant) && (
                      <Field name='date'>
                        {() => (
                          <FormControl>
                            <InputGroup>
                              <TimePicker
                                value={updatedFulfilmentState.time}
                                date={updatedFulfilmentState.date}
                                onChange={handleTimeChange}
                                showPlaceholder={showTimePlaceholder}
                                setShowPlaceholder={setShowTimePlaceholder}
                              />
                            </InputGroup>
                          </FormControl>
                        )}
                      </Field>
                    )}
                  </Stack>
                )}
                <Field name='fullName' validate={validateField('Full Name')}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.fullName && form.touched.fullName}>
                      <InputGroup>
                        <InputLeftElement ml={2} padding={3} minHeight={10} color={greyLight} {...fontSizeNormal}>
                          <BsFillPersonFill />
                        </InputLeftElement>
                        <Input
                          {...field}
                          type='fullName'
                          placeholder='Full Name'
                          paddingLeft={12}
                          {...borderColourDefault}
                          {...textColourNormal}
                          {...borderColourLight}
                          {...fontLight}
                        />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack direction={['column', 'row']} spacing={4}>
                  <Field name='phone' validate={validatePhoneNumber('Phone number')}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                        <InputGroup>
                          <InputLeftElement ml={2} padding={3} minHeight={10} color={greyLight} {...fontSizeNormal}>
                            <FaPhoneAlt />
                          </InputLeftElement>
                          <Input
                            {...field}
                            type='phone'
                            placeholder='Phone'
                            paddingLeft={12}
                            {...borderColourDefault}
                            {...textColourNormal}
                            {...borderColourLight}
                            {...fontLight}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='email' validate={validateEmail('Email')}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <InputGroup>
                          <InputLeftElement ml={2} padding={3} minHeight={10} color={greyLight} {...fontSizeNormal}>
                            <FaEnvelope />
                          </InputLeftElement>
                          <Input
                            {...field}
                            type='email'
                            placeholder='Email'
                            paddingLeft={12}
                            {...borderColourDefault}
                            {...textColourNormal}
                            {...borderColourLight}
                            {...fontLight}
                          />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                {renderAddress()}
                <CreditCard onChange={handleCreditCardChange} />
                <Button isLoading={isLoading} type='submit' isDisabled={disabled} brand isRounded reversed>
                  Pay by Credit Card
                </Button>
                <PaymentRequestButton
                  isSubmitting={isPrLoading}
                  validateAndSubmitPayRequest={async (showPayRequest) =>
                    validateAndSubmitPayRequest(showPayRequest, values, setTouched, validateForm)
                  }
                  handlePayRequest={(paymentMethodId, complete) => handlePayRequest(paymentMethodId, complete)}
                />
                {error && <FormErrorMessage>{error}</FormErrorMessage>}
              </Stack>
            </form>
          )}
        </Formik>
      </Flex>
    </Modal>
  )
}
