import React, { FC, useContext } from 'react'
import { Center, Stack, Text, useToast } from '@chakra-ui/core'
import { Link } from 'react-router-dom'
import { brandColourAsText, fontSizeH1, fontBold, fontSizeNormal } from '../../styles'
import { Button } from '../button'
import { MenuOptionsContext, RestaurantContext } from '../../providers'

interface ErrorMessageProps {
  hideInitial?: boolean
  title?: string
  description?: string
}

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { title = 'Page not found', description = 'Sorry, we could not find the page you requested', hideInitial } = props
  const toast = useToast()
  const { restaurantError } = useContext(RestaurantContext)
  const { menuOptionsError } = useContext(MenuOptionsContext)

  if (!hideInitial) {
    return null
  }

  if (restaurantError) {
    return (
      <Center>
        <Stack alignItems='center' direction='column' spacing={6}>
          <Text lineHeight='normal' textAlign='center' {...fontSizeH1} {...fontBold} {...brandColourAsText}>
            Server Error
          </Text>
          <Text textAlign='center' {...fontSizeNormal}>
            Sorry, something went wrong at our end.
          </Text>
          <Link to='/'>
            <Button brand reversed isRounded>
              Go Back
            </Button>
          </Link>
        </Stack>
      </Center>
    )
  }

  if (menuOptionsError) {
    toast({
      title: 'Server Error',
      description: 'Sorry, something went wrong at our end.',
      status: 'error',
      isClosable: true,
    })
  }

  return (
    <Center>
      <Stack alignItems='center' direction='column' spacing={6}>
        <Text lineHeight='normal' textAlign='center' {...fontSizeH1} {...fontBold} {...brandColourAsText}>
          {title}
        </Text>
        <Text textAlign='center' {...fontSizeNormal}>
          {description}
        </Text>
        <Link to='/'>
          <Button brand reversed isRounded>
            Go Back
          </Button>
        </Link>
      </Stack>
    </Center>
  )
}
