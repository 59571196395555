import React, { FC } from 'react'
import { Route, Router, Switch, useHistory } from 'react-router-dom'
import { MenuItemRoute } from '../item'
import { MenuCategory } from '../../../components'
import { routeMap } from '../../../utils'
import { CheckoutRoute } from '../checkout'

export const MenuListRoute: FC = () => {
  const history = useHistory()
  const { checkout, menuItem } = routeMap

  return (
    <MenuCategory>
      <Router history={history}>
        <Switch>
          <Route path={checkout}>
            <CheckoutRoute />
          </Route>
          <Route path={menuItem}>
            <MenuItemRoute />
          </Route>
        </Switch>
      </Router>
    </MenuCategory>
  )
}
