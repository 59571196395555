import { Box, Flex, Stack, Text } from '@chakra-ui/core'
import React, { FC, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext, RestaurantContext } from '../../providers'
import {
  brandColourAsText,
  borderColourBrand,
  borderRadiusFull,
  brandColourAsBackground,
  textColourLight,
  fontBold,
  fontSizeExtraSmall,
} from '../../styles'
import { CartItem, Order, FulfilmentType } from '../../models'
import { editCartItem, removeFromCart } from '../../providers/app/actions'
import { FormatCurrency } from '../format-currency'
import { CartTip } from '../cart-tip'
import { Button } from '../button'

export interface CartTableProps {
  isReadOnly: boolean
  order?: Order
}

export const CartTable: FC<CartTableProps> = (props) => {
  const { isReadOnly, order } = props
  const { dispatch, state } = useContext(AppContext)
  const { restaurant } = useContext(RestaurantContext)
  const { items, total, tip } = order || state.cartState
  const newTotal = parseFloat(`${total}`) // NOTE: total includes tip

  const isDelivery = order
    ? // @ts-ignore
      order.type.toLowerCase() === FulfilmentType.DELIVERY.toLowerCase()
    : state.fulfilmentState.type === FulfilmentType.DELIVERY

  const getTxnFees = () => {
    if (order) {
      // ORDER PAGE

      if (order.custTransFee) {
        return parseFloat(order.custTransFee)
      }

      return null
    }

    // CART PAGE

    if (!restaurant) return null
    if (!restaurant.custTransFeePercent) return null

    const { custTransFeePercent, deliveryFee } = restaurant

    // @ts-ignore
    let orderTxnFee = (parseFloat(custTransFeePercent) * parseFloat(total)) / 100

    if (isDelivery) {
      if (typeof deliveryFee !== 'undefined') {
        // @ts-ignore
        orderTxnFee = (parseFloat(custTransFeePercent) * (parseFloat(total) + parseFloat(deliveryFee))) / 100
      }
    }

    return orderTxnFee
  }

  const calculateTotal = () => {
    // NOTE: don't render if cart empty
    if (items.length === 0) {
      return 0
    }

    const txnFees = getTxnFees() || 0

    if (!restaurant || !isDelivery) {
      // @ts-ignore
      return parseFloat(`${total}`) + parseFloat(txnFees)
    }

    // @ts-ignore
    return parseFloat(`${total}`) + parseFloat(restaurant.deliveryFee) + parseFloat(txnFees)
  }

  const removeItem = (cartItem: CartItem) => () => {
    dispatch(removeFromCart(cartItem))
  }

  const editItem = (cartItem: CartItem) => () => {
    dispatch(editCartItem(cartItem))
  }

  const renderOptionsPrice = (price) => {
    if (!price || price === '0') return null

    return (
      <Text fontWeight='bold' minWidth='65px' textAlign='right'>
        <FormatCurrency>${price}</FormatCurrency>
      </Text>
    )
  }

  const renderOptionsAtCheckout = (options) => {
    if (!options) return null

    return options.map((option) => {
      const { choices } = option

      if (!choices) return null

      return (
        <React.Fragment key={option.objectId}>
          {choices.map((choice) => {
            const parsedChoice = JSON.parse(choice)

            return (
              <Stack
                key={`${option.objectId}-${parsedChoice.title}`}
                isInline
                justifyContent='space-between'
                {...fontSizeExtraSmall}
                {...textColourLight}
                mb={2}
              >
                <Text>{parsedChoice.title}</Text>
                {renderOptionsPrice(parsedChoice.price)}
              </Stack>
            )
          })}
        </React.Fragment>
      )
    })
  }

  const renderOptions = (options) => {
    if (!options) return null

    return (
      <>
        {options.map((option) => {
          const choices = typeof option.value === 'string' ? option.value : option.value[0]
          const splitChoices = choices.split(',')

          const price = splitChoices.splice(-1)[0].trim()
          const title = splitChoices.join(',')

          return (
            <Stack key={option.objectId} isInline justifyContent='space-between' {...fontSizeExtraSmall} {...textColourLight} mb={2}>
              <Text>{title}</Text>
              {renderOptionsPrice(price)}
            </Stack>
          )
        })}
      </>
    )
  }

  const renderRequests = (requests) => {
    if (!requests) {
      return null
    }

    return (
      <Text display='block' fontSize='xs' as='i' color='gray.500' mb={2}>
        * {requests}
      </Text>
    )
  }

  const renderSubtotal = () => {
    return (
      <Flex>
        <Text mr={2} {...brandColourAsText}>
          Subtotal
        </Text>
        <Box {...brandColourAsText} {...fontBold}>
          <FormatCurrency>{newTotal}</FormatCurrency>
        </Box>
      </Flex>
    )
  }

  const renderTransactionFees = () => {
    if (!restaurant) return null

    const { custTransFeePercent } = order || restaurant

    if (!custTransFeePercent) return null
    /* @ts-ignore */
    if (!parseFloat(custTransFeePercent)) return null

    const orderTxnFee = getTxnFees()

    return (
      <Flex>
        <Text mr={2} {...brandColourAsText}>
          Card Processing Fee ({custTransFeePercent}%)
        </Text>
        <Box {...brandColourAsText} {...fontBold}>
          <FormatCurrency>{orderTxnFee}</FormatCurrency>
        </Box>
      </Flex>
    )
  }

  const renderDeliveryFees = () => {
    if (!restaurant) return null

    if (!isDelivery) return null

    const renderFee = () => {
      if (order) {
        if (!order?.deliveryFee) {
          return <Text>Free</Text>
        }
        return <FormatCurrency>{parseFloat(order?.deliveryFee)}</FormatCurrency>
      }

      if (!restaurant.deliveryFee) {
        return <Text>Free</Text>
      }

      return <FormatCurrency>{restaurant.deliveryFee}</FormatCurrency>
    }

    return (
      <Flex>
        <Text mr={2} {...brandColourAsText}>
          Delivery Fees
        </Text>
        <Box {...brandColourAsText} {...fontBold}>
          {renderFee()}
        </Box>
      </Flex>
    )
  }

  const renderFees = () => {
    if (!restaurant) return null

    // NOTE: don't show if Order/Cart page without delivery and txn fee
    if (!isDelivery && !restaurant.custTransFeePercent) {
      return null
    }

    // NOTE: don't show fees if cart empty
    if (items.length === 0) {
      return null
    }

    return (
      <Stack flex={1} flexDirection='column' py={4} mt={4} alignItems='flex-end' borderTopWidth={2} spacing={4} {...borderColourBrand}>
        {renderSubtotal()}
        {renderDeliveryFees()}
        {renderTransactionFees()}
      </Stack>
    )
  }

  const renderEditAndRemoveButtons = (menuItemId, cartItem) => {
    return (
      <Stack direction='row' alignItems='baseline' spacing={2} {...textColourLight} mb={2} marginLeft='-0.625rem' marginTop='-.5rem'>
        <Link to={`/menu/${menuItemId}`}>
          <Button
            brand
            onClick={editItem(cartItem)}
            _hover={{ bg: 'none' }}
            bg='none'
            height='auto'
            minWidth='none'
            padding={0}
            {...fontSizeExtraSmall}
          >
            Edit
          </Button>
        </Link>
        <Text ml='0 !important'>&middot;</Text>
        <Button
          brand
          onClick={removeItem(cartItem)}
          _hover={{ bg: 'none' }}
          bg='none'
          height='auto'
          minWidth='none'
          padding={0}
          {...fontSizeExtraSmall}
        >
          Remove
        </Button>
      </Stack>
    )
  }

  const renderItems = () => {
    return (
      <>
        {items.map((cartItem) => {
          const { objectId, itemTitle, qty, subtotal: itemSubtotal, options, price, menuItemId } = cartItem

          return (
            <React.Fragment key={objectId}>
              <Flex flex={1} flexDir='row' justifyContent='space-between' mb={2}>
                <Flex>
                  <Text flexShrink={0} width='30px' marginRight={4} {...brandColourAsText} {...fontBold}>
                    {qty}
                  </Text>
                  <Flex flexDir='column'>
                    <Text>{itemTitle}</Text>
                  </Flex>
                </Flex>
                <Text {...brandColourAsText} {...fontBold}>
                  <FormatCurrency>{itemSubtotal || price}</FormatCurrency>
                </Text>
              </Flex>

              <Box ml='2.9rem'>
                {isReadOnly ? renderOptionsAtCheckout(options) : renderOptions(options)}
                {renderRequests(cartItem.requests)}
                {!isReadOnly && renderEditAndRemoveButtons(menuItemId, cartItem)}
              </Box>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const renderTip = () => {
    // NOTE: don't render if cart empty
    if (items.length === 0) {
      return null
    }

    if (isReadOnly) {
      return (
        <Flex flex={1} py={4}>
          <Text flex={1}>Tip</Text>
          <Box {...brandColourAsText} {...fontBold}>
            {/* @ts-ignore */}
            <FormatCurrency>{parseFloat(tip)}</FormatCurrency>
          </Box>
        </Flex>
      )
    }

    return <CartTip />
  }

  return (
    <Flex flexDir='column'>
      {renderItems()}
      {renderTip()}
      {renderFees()}
      <Flex flex={1} py={4} mt={4} justifyContent='flex-end' borderTopWidth={2} {...borderColourBrand}>
        <Text mr={2} {...brandColourAsText}>
          Total
        </Text>
        <Box px={3} {...borderRadiusFull} {...brandColourAsBackground}>
          <FormatCurrency>{calculateTotal()}</FormatCurrency>
        </Box>
      </Flex>
    </Flex>
  )
}
