import {
  Box,
  Flex,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import React, { useContext } from 'react'
import { FaFacebookF, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaQuestionCircle, FaTwitter } from 'react-icons/fa'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import { AppContext, RestaurantContext } from '../../providers'
import { Container } from '../container'
import {
  borderRadiusNormal,
  borderRadiusNormalTopOnly,
  brandColour,
  brandColourAsBackground,
  fontSizeSmall,
  shadowLarge,
  textColourNormal,
} from '../../styles'
import { Button } from '../button'
import { getLogoLink, getSupportText, getSupportURL, hasDisplayLocation } from '../../utils/feature-flags'

export const Header = () => {
  const { restaurant } = useContext(RestaurantContext)
  const { state } = useContext(AppContext)

  if (!restaurant) return null

  const {
    location,
    socialLinks: { facebookLink, twitterLink, instagramLink },
  } = restaurant

  const displayPhoneNumber = () => {
    if (!location.phone) return null

    const parsedPhoneNumber = parsePhoneNumberFromString(
      location.phone,
      `${process.env.REACT_APP_COUNTRY_CODE}`.toUpperCase() as CountryCode,
    )
    const formattedPhoneNumber = parsedPhoneNumber ? parsedPhoneNumber.formatNational() : location.phone

    return (
      <Link href={`tel:${location.phone}`}>
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={brandColour}>
            <FaPhoneAlt fontSize={24} />
          </Box>
          <Text data-phone whiteSpace='nowrap'>
            {formattedPhoneNumber}
          </Text>
        </Flex>
      </Link>
    )
  }

  const displaySupport = () => {
    const supportURL = getSupportURL(restaurant)
    if (!supportURL) {
      return null
    }

    return (
      <Link href={supportURL} isExternal>
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={brandColour}>
            <FaQuestionCircle fontSize={24} />
          </Box>
          <Text data-address>{getSupportText(restaurant)}</Text>
        </Flex>
      </Link>
    )
  }

  const displayLocation = () => {
    if (!location.address1) return null

    if (!hasDisplayLocation(restaurant)) {
      return null
    }

    return (
      <Link
        href={`https://maps.google.com/?q=${location.address1}+${location.city}+${location.postcode}+${location.state}`}
        isExternal
      >
        <Flex alignItems='center'>
          {/* @ts-ignore */}
          <Box marginRight={4} color={brandColour}>
            <FaMapMarkerAlt fontSize={24} />
          </Box>
          <Text data-address>{location.address1}</Text>
        </Flex>
      </Link>
    )
  }

  const renderContact = () => {
    return (
      <Box display={{ base: 'block', xl: 'none' }}>
        <Popover gutter={-1} placement='bottom-end'>
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Button
                  size='sm'
                  _hover={{
                    bg: 'white',
                  }}
                  _selected={{
                    bg: 'white',
                  }}
                  brand
                  px={2}
                  // @ts-ignore
                  rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  {...fontSizeSmall}
                  {...(isOpen ? borderRadiusNormalTopOnly : borderRadiusNormal)}
                  mr={4}
                >
                  Contact
                </Button>
              </PopoverTrigger>
              <PopoverContent
                border='none'
                boxShadow='none'
                marginTop='0! important'
                zIndex={2}
                top='auto !important'
                {...borderRadiusNormal}
                borderTopRightRadius={0}
                color={textColourNormal}
                maxWidth='16rem'
              >
                <PopoverBody {...shadowLarge}>
                  <Stack spacing={3}>
                    {displayPhoneNumber()}
                    {displayLocation()}
                    {displaySupport()}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Box>
    )
  }

  const renderFacebookIcon = () => {
    if (!facebookLink) return null
    return (
      <Link data-facebook href={facebookLink} target='_blank' isExternal>
        <FaFacebookF fontSize={20} />
      </Link>
    )
  }

  const renderInstagramIcon = () => {
    if (!instagramLink) return null
    return (
      <Link data-instagram href={instagramLink} target='_blank' isExternal>
        <FaInstagram fontSize={20} />
      </Link>
    )
  }

  const renderTwitterIcon = () => {
    if (!twitterLink) return null
    return (
      <Link data-twitter href={twitterLink} target='_blank' isExternal>
        <FaTwitter fontSize={20} />
      </Link>
    )
  }

  const renderHomeLink = () => {
    let url = `/`

    if (state.fulfilmentState.type === 'Dine In') {
      url = `/table/${state.fulfilmentState.tableNumber}`
    }

    return url
  }

  return (
    <Box as='header' {...brandColourAsBackground}>
      <Container>
        <Flex alignItems='center' height={[50, 70]} justifyContent='space-between' py={2}>
          <RouterLink to={renderHomeLink()}>
            <Image maxHeight={['30px', '50px']} maxWidth='200px' height='auto' src={getLogoLink(restaurant)} alt='' />
          </RouterLink>
          <Flex>
            {renderContact()}
            <Flex alignItems='center'>
              <Stack direction='row' spacing={5}>
                {renderFacebookIcon()}
                {renderTwitterIcon()}
                {renderInstagramIcon()}
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
