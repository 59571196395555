import React, { useContext } from 'react'
import moment from 'moment'
import { IoMdStopwatch } from 'react-icons/io'
import { Box, Stack, Text } from '@chakra-ui/core'
import { RestaurantContext, AppContext } from '../../providers'
import { borderRadiusFull, brandColourAsBackground, fontSizeSmall, fontBold } from '../../styles'
import { getTimeFormat } from '../../utils/feature-flags'
// import { FulfilmentTime } from '../../models'

export const EtaPill = () => {
  const { restaurant } = useContext(RestaurantContext)
  const {
    state: {
      fulfilmentState: { preferredDateTime },
    },
  } = useContext(AppContext)

  const preferredDateTimeAsMoment = moment(preferredDateTime)

  if (!preferredDateTime || !restaurant || !restaurant.typicalEta) return null

  const calculateETA = () => {
    if (moment().format('DD/MM/YYYY') === moment(preferredDateTime).format('DD/MM/YYYY')) {
      return (
        <Text {...fontBold} lineHeight={1.2}>
          <Text as='span' whiteSpace='nowrap'>
            Today
          </Text>
          <Text as='span' whiteSpace='nowrap'>
            {' '}
            @ {preferredDateTimeAsMoment.format(getTimeFormat(restaurant))}
          </Text>
        </Text>
      )
    }

    return (
      <Text {...fontBold} lineHeight={1.2}>
        <Text as='span' whiteSpace='nowrap'>
          {preferredDateTimeAsMoment.format('DD/MM/YYYY')}
        </Text>
        <Text as='span' whiteSpace='nowrap'>
          {' '}
          @ {preferredDateTimeAsMoment.format(getTimeFormat(restaurant))}
        </Text>
      </Text>
    )
  }

  return (
    <Box data-eta display='inline-block' px={3} py={1} {...borderRadiusFull} {...brandColourAsBackground} {...fontSizeSmall}>
      <Stack alignItems='center' direction='row' spacing={2}>
        <IoMdStopwatch fontSize={20} />
        <Text lineHeight={1.2}>Est. Time</Text>
        {calculateETA()}
      </Stack>
    </Box>
  )
}
