import React, { FC, useContext } from 'react'
import { Box, Grid, Text } from '@chakra-ui/core'
import { AppContext, MenuContext, RestaurantContext } from '../../providers'
import { brandColour, brandColourAsText, fontSemiBold, fontSizeH5 } from '../../styles'
import { toSlug } from '../../utils/strings'
import { Card } from '../card'

export const MenuCategory: FC = (props) => {
  const { children } = props
  const { categories, menuItems } = useContext(MenuContext)
  const { restaurant } = useContext(RestaurantContext)
  const { state } = useContext(AppContext)
  const {
    searchState: { searchTerm },
  } = state

  if (!categories || !menuItems || !restaurant) return null

  const { showMenuImages } = restaurant

  if (searchTerm) {
    const searchFields = ['itemTitle', 'itemDescription']

    const filteredItems = menuItems.filter((item) =>
      searchFields.reduce((prev, curr) => prev || item[curr]?.toLowerCase().includes(searchTerm.toLowerCase()), false),
    )

    if (filteredItems.length === 0) {
      return (
        <Text {...fontSemiBold} {...brandColourAsText}>
          Sorry, no results.
        </Text>
      )
    }

    return (
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
        {filteredItems.map((item) => (
          <Card
            key={item.objectId}
            menuItemId={item.objectId}
            category={item.category}
            title={item.itemTitle}
            description={item.itemDescription}
            image={item.imageLink}
            price={item.price}
            disableDate={item.disableDate}
            showMenuImages={showMenuImages}
          />
        ))}
      </Grid>
    )
  }

  return (
    <>
      {categories.map((category) => (
        <Box marginBottom={20} key={category}>
          <Box borderBottom='1px solid' borderColor='gray.400' marginBottom={6}>
            <Text
              key={category}
              id={toSlug(category)}
              borderBottom='2px solid'
              borderColor={brandColour}
              marginBottom='-1px'
              width='fit-content'
              {...fontSemiBold}
              {...fontSizeH5}
              {...brandColourAsText}
            >
              {category}
            </Text>
          </Box>
          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
            {menuItems
              .filter((item) => item.category === category)
              .map((item) => (
                <Card
                  key={item.objectId}
                  menuItemId={item.objectId}
                  category={item.category}
                  title={item.itemTitle}
                  description={item.itemDescription}
                  image={item.imageLink}
                  price={item.price}
                  disableDate={item.disableDate}
                  showMenuImages={showMenuImages}
                />
              ))}
          </Grid>
        </Box>
      ))}
      {children}
    </>
  )
}
