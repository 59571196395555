import Rollbar from 'rollbar'

export const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_MODE,
  })
  const logInfo = (info) => {
    RollbarObj.info(`${process.env.REACT_APP_MODE} - ${info}`)
  }
  const logErrorInRollbar = (error: string, errorObject = {}) => {
    RollbarObj.error(`${process.env.REACT_APP_MODE} - ${error}`, errorObject)
  }
  return { logInfo, logErrorInRollbar }
})()

export default RollbarErrorTracking
