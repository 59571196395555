import { actionCreatorFactory } from 'typescript-fsa'
import { CartItem, Tip } from '../../../models'

const actionCreator = actionCreatorFactory()

export const addToCart = actionCreator<CartItem>('ADD_CART_ITEM')
export const removeFromCart = actionCreator<CartItem>('REMOVE_CART_ITEM')
export const addTipToCart = actionCreator<Tip>('ADD_TIP')
export const editCartItem = actionCreator<CartItem>('EDIT_CART_ITEM')
export const resetEditCartItem = actionCreator('RESET_EDIT_CART_ITEM')
export const updateCartItem = actionCreator<CartItem>('UPDATE_CART_ITEM')
export const clearCart = actionCreator('CLEAR_CART')
export const orderIsSubmitting = actionCreator<boolean>('SET_SUBMIT_STATE')
