import { ChildDataProps, graphql, compose } from 'react-apollo'
import React, { createContext, memo } from 'react'
import { Order } from '../../models'
import { orderForId } from '../../queries'
import { updateCartWithTotal } from '../../utils'

interface Response {
  orderForId: Order
  error?: Error
  loading: boolean
}

interface Variables {
  orderId: string
}

interface InputProps {
  orderId: string
}

interface QueryProps {
  order: Response
}

type ChildProps = ChildDataProps<{}, Response, Variables>

export const OrderContext = createContext<{
  order?: Order
  error?: Error
  loading: boolean
}>({
  loading: true,
})

const orderQuery = graphql<InputProps, Response, Variables, ChildProps>(orderForId, {
  name: 'order',
  options: ({ orderId }) => ({
    variables: {
      orderId,
    },
    skip: !orderId,
  }),
})

const Component = memo<QueryProps>((props) => {
  const {
    children,
    order: { loading, error, orderForId: order },
  } = props

  const updatedOrder = updateCartWithTotal(order, 'choices') as Order

  return (
    <OrderContext.Provider
      value={{
        order: updatedOrder,
        loading,
        error,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
})

export const OrderContextProvider = compose(orderQuery)(Component)
