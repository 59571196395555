import React from 'react'
import { Box, Flex, Heading, Image } from '@chakra-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export const GroupHeader = (props) => {
  const { title, imageURL, backgroundColor } = props

  return (
    <Box width='100%' backgroundColor={`${backgroundColor} !important`}>
      <Flex
        alignItems='center'
        maxWidth={1208}
        paddingLeft={4}
        paddingRight={4}
        overflow='hidden'
        marginLeft='auto'
        marginRight='auto'
        height={[50, 70]}
        justifyContent='left'
      >
        <RouterLink to='#'>
          <Image maxHeight={['30px', '50px']} maxWidth='200px' height='auto' src={imageURL} alt='' />
        </RouterLink>
        <Flex
          alignItems='center'
          pl={[4, 4, 0, 0]}
          position={['relative', 'relative', 'fixed', 'fixed']}
          overflow='hidden'
          width='100%'
          maxWidth={1208}
          justifyContent={['left', 'left', 'center', 'center']}
        >
          <Heading color='white' whiteSpace='nowrap' overflow='hidden'>
            {title}
          </Heading>
        </Flex>
      </Flex>
    </Box>
  )
}
