import { defaultsDeep } from 'lodash'
import React, { createContext, Dispatch, FC, useReducer, useContext } from 'react'
import { Action, combineReducers } from 'redux'
import createPersistedReducer from 'use-persisted-reducer'
import {
  CartState,
  initialCartState,
  initialOrderState,
  cartState,
  initialSearchState,
  SearchState,
  searchState,
  fulfilmentState,
  FulfilmentState,
  initialFulfilmentState,
  UserState,
  userState,
  OrderState,
  orderState,
} from './reducers'
import { updateCartWithTotal } from '../../utils'
import { RestaurantContext } from '../restaurant'

export interface AppContextProviderProps {
  state?: AppContextState
}

export interface AppContextState {
  cartState: CartState
  searchState: SearchState
  fulfilmentState: FulfilmentState
  userState: UserState
  orderState: OrderState
}

const initialState = {
  cartState: initialCartState,
  searchState: initialSearchState,
  fulfilmentState: initialFulfilmentState,
  userState: {},
  orderState: initialOrderState,
}

export const AppContext = createContext<{
  state: AppContextState
  dispatch: Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

export const AppContextProvider: FC<AppContextProviderProps> = (props) => {
  const { children, state: injectedState } = props
  const { slug } = useContext(RestaurantContext)
  const usePersistedReducer = createPersistedReducer(`${slug}.context`)

  const reducers = combineReducers({
    cartState,
    searchState,
    fulfilmentState,
    userState,
    orderState,
  })

  const [mockState, mockDispatch] = useReducer(reducers, injectedState || initialState)
  const [state, dispatch] = usePersistedReducer<AppContextState>(reducers, initialState)

  // If we update the initial state in the future with new properties, they need to be
  // merged with the state in local storage if it exists. We can't use the spread operator here because
  // saved values would either be overridden by the initial values, or ignored completely
  const mergedState = injectedState || defaultsDeep(state, initialState)

  // Update the cart with totals so they can't be changed
  const { cartState: currentCartState } = injectedState ? mockState : mergedState
  const updatedCartState = updateCartWithTotal(currentCartState)
  const updatedAppState = {
    ...mergedState,
    cartState: updatedCartState,
  }

  return (
    <AppContext.Provider value={{ state: updatedAppState, dispatch: injectedState ? mockDispatch : dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
