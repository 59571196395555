import { Flex, Text } from '@chakra-ui/core'
import React, { FC, useContext } from 'react'
import { AppContext } from '../../providers'
import { brandColourText, brandColourAsText, borderRadiusFull, brandColourTextAsBackground } from '../../styles'

export const TablePill: FC = () => {
  const { state } = useContext(AppContext)
  const {
    fulfilmentState: { tableNumber },
  } = state

  if (!tableNumber) {
    return null
  }

  return (
    <Flex alignItems='center' justifyContent='flex-start'>
      <Text mr={2} color={brandColourText}>
        Order for table
      </Text>
      <Flex
        minWidth='2em'
        height='2em'
        alignItems='center'
        justifyContent='center'
        padding={1}
        {...borderRadiusFull}
        {...brandColourTextAsBackground}
        {...brandColourAsText}
      >
        {tableNumber}
      </Flex>
    </Flex>
  )
}
