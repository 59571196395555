import { CartItem } from './cart'

export interface Order {
  objectId: string
  created: string
  version: number
  restaurantId: string
  businessName: string
  total: string
  tip: string | null
  phone: string
  name: string
  email: string
  pickupTime: string
  pickupDate: string
  transactionId: string
  status: string
  statusReason: string | null
  partySize: number | null
  checkoutId: string | null
  paymentIntentId: string
  paymentIntentStatus: string
  tableNum: string | null
  items: CartItem[]
  type: string | null
  subtotal: string
  deliveryFee: string | null
  custTransFee: string | null
  custTransFeePercent: string | null
  address: string | null
}

export enum OrderStatus {
  NONE = '',
  SENT = 'sent',
  PREPARING = 'prepare',
  PICKUP = 'pickup',
  CANCELLED = 'cancelled',
}

export enum OrderType {
  DELIVERY = 'delivery',
  DINEIN = 'dine in',
  PICKUP = 'pickup',
}
