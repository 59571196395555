import React, { FC } from 'react'
import { formatCurrency } from '../../utils/numbers'

export const FormatCurrency: FC = (props) => {
  const { children } = props

  if (typeof children !== 'number') {
    return <>{children}</>
  }

  return <>{formatCurrency(children as number)}</>
}
