import React, { FC, useContext } from 'react'
import { Box, Text } from '@chakra-ui/core'
import { Button } from '../button'
import { AppContext } from '../../providers'
import { borderRadiusFull, brandColourAsBackground, brandColour, brandColourText, brandColourAsText } from '../../styles'
import { totalCartItems } from '../../utils'

export interface MiniCartProps {
  onClick?: () => void
}

export const MiniCart: FC<MiniCartProps> = (props) => {
  const { onClick } = props
  const { state } = useContext(AppContext)
  const {
    cartState: { items },
  } = state

  const cartState = () => {
    if (items.length === 0) {
      return <Text>Cart</Text>
    }

    return <Text>View Cart</Text>
  }

  const handleClick = () => {
    if (!onClick) {
      return
    }

    onClick()
  }

  return (
    <Box
      marginTop='0 !important'
      borderColor={brandColour}
      borderWidth={2}
      display='inline-block'
      minWidth='fit-content'
      {...borderRadiusFull}
      {...brandColourAsBackground}
    >
      <Button
        brand
        _disabled={{ bg: brandColourText }}
        isDisabled
        height='32px'
        cursor='auto'
        onClick={handleClick}
        {...borderRadiusFull}
        {...brandColourAsText}
      >
        <Text>{totalCartItems(items)}</Text>
      </Button>
      <Button
        _hover={{ bg: 'none' }}
        _focus={{ bg: 'none' }}
        brand
        reversed
        height='32px'
        paddingLeft='8px'
        paddingRight='16px'
        onClick={handleClick}
        {...brandColourAsBackground}
        {...borderRadiusFull}
      >
        {cartState()}
      </Button>
    </Box>
  )
}
