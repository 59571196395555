import gql from 'graphql-tag'

export const restaurantsForMultiSlug = gql`
  query RestaurantsForMultiSlug($multiSlug: String!) {
    restaurantsForMultiSlug(multiSlug: $multiSlug) {
      banner
      objectId
      branding
      countryCode
      # custTransFeePercent
      # dateFormat
      # deliveryFee
      # deliveryPostcodes
      enabled
      # hours
      imageLink
      # lastUpdated
      # locale
      location
      # menus {
      #   objectId
      #   endTime
      #   menuIndex
      #   menuTitle
      #   objectId
      #   startTime
      #   type
      # }
      name
      # services
      # showMenuImages
      slug
      # socialLinks
      # timeZone
      # typicalEta
      # website
    }
  }
`
