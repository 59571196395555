import moment from 'moment'
import React, { createContext, FC, useEffect } from 'react'

export const DateTimeContext = createContext<{
  moment: typeof moment
  shortDateFormat?: string
}>({
  moment,
  shortDateFormat: 'MM/DD/YYYY',
})

export const DateTimeContextProvider: FC = (props) => {
  const { children } = props

  useEffect(() => {
    moment().locale(process.env.REACT_APP_LOCALE || 'en-US')
  }, [])

  return (
    <DateTimeContext.Provider value={{ moment, shortDateFormat: process.env.REACT_APP_SHORT_DATE_FORMAT }}>
      {children}
    </DateTimeContext.Provider>
  )
}
