import { Moment } from 'moment'
import { Action, Reducer } from 'redux'
import { isType } from 'typescript-fsa'
import { setTableNumber, setServingType } from '../actions/fulfilment'
import { FulfilmentType, FulfilmentTime } from '../../../models'
import { setFulfilmentTime, setFulfilmentType, setFulfilmentPreferredDateTime } from '../actions'

export interface FulfilmentState {
  type: FulfilmentType
  time: FulfilmentTime
  preferredDateTime?: Moment
  tableNumber?: string | number | null
  servingType?: string | null
}

export const initialFulfilmentState = {
  type: FulfilmentType.PICKUP,
  time: FulfilmentTime.ASAP,
}

export const fulfilmentState: Reducer = (state: FulfilmentState = initialFulfilmentState, action: Action) => {
  if (isType(action, setFulfilmentType)) {
    return {
      ...state,
      type: action.payload,
    }
  }

  if (isType(action, setFulfilmentTime)) {
    return {
      ...state,
      time: action.payload,
    }
  }

  if (isType(action, setFulfilmentPreferredDateTime)) {
    return {
      ...state,
      preferredDateTime: action.payload,
    }
  }

  if (isType(action, setTableNumber)) {
    return {
      ...state,
      tableNumber: action.payload,
    }
  }

  if (isType(action, setServingType)) {
    return {
      ...state,
      servingType: action.payload,
    }
  }

  return state
}
