import { BorderRadiusProps } from 'styled-system'

export const borderColourDefault = {
  borderColor: 'gray.500',
}

export const borderColourDark = {
  borderColor: 'gray.800',
}

export const borderColourLight = {
  borderColor: 'gray.200',
}

export const borderColourBrand = {
  borderColor: 'brand.500',
}

export const borderRadiusNormal: BorderRadiusProps = {
  borderRadius: 'lg',
}

export const borderRadiusNormalTopOnly: BorderRadiusProps = {
  ...borderRadiusNormal,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}

export const borderRadiusNormalBottomOnly: BorderRadiusProps = {
  ...borderRadiusNormal,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusNormalLeftOnly: BorderRadiusProps = {
  ...borderRadiusNormal,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusNormalRightOnly: BorderRadiusProps = {
  ...borderRadiusNormal,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}

export const borderRadiusLarge: BorderRadiusProps = {
  borderRadius: '1.75rem',
}

export const borderRadiusLargeTopOnly: BorderRadiusProps = {
  ...borderRadiusLarge,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}

export const borderRadiusLargeBottomOnly: BorderRadiusProps = {
  ...borderRadiusLarge,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusLargeLeftOnly: BorderRadiusProps = {
  ...borderRadiusLarge,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusLargeRightOnly: BorderRadiusProps = {
  ...borderRadiusNormal,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}

export const borderRadiusSmall: BorderRadiusProps = {
  borderRadius: 'md',
}

export const borderRadiusSmallTopOnly: BorderRadiusProps = {
  ...borderRadiusSmall,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}

export const borderRadiusSmallBottomOnly: BorderRadiusProps = {
  ...borderRadiusSmall,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusSmallLeftOnly: BorderRadiusProps = {
  ...borderRadiusSmall,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusSmallRightOnly: BorderRadiusProps = {
  ...borderRadiusSmall,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}

export const borderRadiusExtraSmall: BorderRadiusProps = {
  borderRadius: 'sm',
}

export const borderRadiusExtraSmallTopOnly: BorderRadiusProps = {
  ...borderRadiusExtraSmall,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}

export const borderRadiusExtraSmallBottomOnly: BorderRadiusProps = {
  ...borderRadiusExtraSmall,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusExtraSmallLeftOnly: BorderRadiusProps = {
  ...borderRadiusExtraSmall,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusExtraSmallRightOnly: BorderRadiusProps = {
  ...borderRadiusExtraSmall,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}

export const borderRadiusFull: BorderRadiusProps = {
  borderRadius: 'full',
}

export const borderRadiusFullTopOnly: BorderRadiusProps = {
  ...borderRadiusFull,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}

export const borderRadiusFullBottomOnly: BorderRadiusProps = {
  ...borderRadiusFull,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusFullLeftOnly: BorderRadiusProps = {
  ...borderRadiusFull,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
}

export const borderRadiusFullRightOnly: BorderRadiusProps = {
  ...borderRadiusFull,
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
}
