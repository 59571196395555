import React, { ChangeEvent, FC, forwardRef, useContext, useEffect, useRef, useState } from 'react'
import Geolocation from 'react-geolocation'
import { FaLocationArrow, FaMapMarkerAlt } from 'react-icons/fa'
import {
  Box,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  Input,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/core'
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import usePlacesAutocomplete, { GeocodeResult, LatLon, getGeocode, getLatLng } from 'use-places-autocomplete'
import { Button } from '../button'
import { DeliveryOption, FulfilmentType, GeolocationPosition, GeolocationPositionError } from '../../models'
import { AppContext, RestaurantContext } from '../../providers'
import {
  setFulfilmentType,
  userAddressGeocoded,
  userAddressReceived,
  userPostcodeSet,
  userLocationError,
  userLocationReceived,
} from '../../providers/app/actions'
import {
  borderRadiusNormalTopOnly,
  borderRadiusNormal,
  borderRadiusNormalBottomOnly,
  brandColour,
  brandColourAsText,
  fontSizeSmall,
  shadowLarge,
} from '../../styles'
import { hasValidPostcode, stripForeignCharacters } from '../../utils'
import { getFulfilmentButtonLabel, isLockedToDelivery } from '../../utils/feature-flags'

const TextInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <FormControl d='flex' flexDirection='row' alignItems='center' key='thisform'>
    <Box color={brandColour} width='24px' height='24px' mr={4} as={FaLocationArrow} />
    <Input ref={ref} key={props.id} {...props} />
  </FormControl>
))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    if (htmlElRef.current) {
      // @ts-ignore: Object is possibly 'null'
      htmlElRef.current.focus()
    }
  }

  return [htmlElRef, setFocus]
}

export const FulfilmentTypeToggle: FC = () => {
  const { restaurant } = useContext(RestaurantContext)
  const firstFieldRef = useRef<HTMLInputElement>(null)
  const firstButtonRef = useRef<HTMLButtonElement>(null)
  const hideDelivery = process.env.REACT_APP_DELIVERY_OPTION === DeliveryOption.HIDE
  const toast = useToast()

  const [inputRef, setInputFocus] = useFocus()
  const [focusAfterDispatch, setFocusAfterDispatch] = useState(false)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
    })
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: `${process.env.REACT_APP_COUNTRY_CODE}`,
      },
    },
    debounce: 300,
  })

  const { state, dispatch } = useContext(AppContext)
  const {
    fulfilmentState: { type },
    userState: { address, addressGeocoded },
  } = state

  const isDelivery = type === FulfilmentType.DELIVERY
  const hasDelivery = restaurant?.services.delivery

  const serializeGeolocation = (geoposition: GeolocationPosition): GeolocationPosition => {
    const {
      timestamp,
      coords: { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed },
    } = geoposition

    return {
      timestamp,
      coords: {
        accuracy,
        altitude,
        altitudeAccuracy,
        heading,
        latitude,
        longitude,
        speed,
      },
    }
  }

  const clearAddressInput = () => {
    clearSuggestions()
    // @ts-ignore
    setValue('')
    dispatch(userAddressReceived(undefined))
    dispatch(userAddressGeocoded(undefined))
    dispatch(userPostcodeSet(undefined))

    // @ts-ignore
    setFocusAfterDispatch(true)
  }

  const handleFulfilmentTypeChange = (fulfilmentType: FulfilmentType) => {
    dispatch(setFulfilmentType(fulfilmentType))
    clearSuggestions()
    // closePopup()
  }

  const handleGeolocationSuccess = async (geolocation: GeolocationPosition) => {
    dispatch(userLocationReceived(serializeGeolocation(geolocation)))
    clearSuggestions()

    const latLng: LatLon = {
      lat: geolocation.coords.latitude,
      lng: geolocation.coords.longitude,
    }

    const reverseGeocodingResult = await getGeocode({
      location: latLng,
    })

    const [bestResult] = reverseGeocodingResult
    const userPostcode = bestResult.address_components[6]?.long_name
    const formattedAddress = bestResult.formatted_address
    setValue(formattedAddress)

    if (!restaurant || !restaurant.deliveryPostcodes || restaurant.deliveryPostcodes === []) {
      dispatch(userAddressReceived(formattedAddress))
      dispatch(userAddressGeocoded(latLng))
    }

    if (!hasValidPostcode(restaurant, userPostcode)) {
      showError('Sorry, we do not deliver to your postcode', 'Please choose a different delivery address or switch to pickup.')
      return
    }

    dispatch(userAddressReceived(formattedAddress))
    dispatch(userAddressGeocoded(latLng))
    dispatch(userPostcodeSet(userPostcode))
  }

  const handleSuggestedLocationClick = ({ description }) => async () => {
    setValue(description)
    const geocodingResult: GeocodeResult[] = await getGeocode({ address: description })
    const latLng: LatLon = await getLatLng(geocodingResult[0])
    const reverseGeocodingResult = await getGeocode({
      location: latLng,
    })

    const [bestResult] = reverseGeocodingResult
    const bestPostcode = bestResult.address_components.find((component) => component.types.includes('postal_code'))
    const userPostcode = bestPostcode?.long_name

    if (!hasValidPostcode(restaurant, userPostcode)) {
      clearAddressInput()
      showError('Sorry, we do not deliver to your postcode', 'Please choose a different delivery address or switch to pickup.')
      return
    }

    dispatch(userAddressReceived(description))
    dispatch(userAddressGeocoded(latLng))
    dispatch(userPostcodeSet(userPostcode))

    clearSuggestions()
  }

  const handleGeolocationError = (error: GeolocationPositionError) => {
    dispatch(userLocationError(error))
  }

  const handleGeolocationRetrieve = (getCurrentPosition: () => void) => () => {
    getCurrentPosition()
  }

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueWithSpecialCharactersRemoved = stripForeignCharacters(e.target.value)
    clearSuggestions()
    setValue(valueWithSpecialCharactersRemoved)
    dispatch(userAddressGeocoded(undefined))
    dispatch(userAddressReceived(undefined))
    dispatch(userPostcodeSet(undefined))
  }

  useEffect(() => {
    if (focusAfterDispatch) {
      // @ts-ignore
      setInputFocus()
      setFocusAfterDispatch(false)
    }
  }, [focusAfterDispatch, setFocusAfterDispatch, setInputFocus])

  const renderSuggestions = () => {
    if (status !== 'OK' || addressGeocoded) {
      return null
    }

    return (
      <Flex flexDirection='column' marginTop={3}>
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion

          return (
            <Flex key={place_id} marginTop={1}>
              <Button
                isFullWidth
                data-autocomplete-suggestion
                justifyContent='flex-start'
                onClick={handleSuggestedLocationClick(suggestion)}
              >
                <Text isTruncated>
                  {main_text} {secondary_text}
                </Text>
              </Button>
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const renderLocationInput = () => {
    return (
      <Flex marginY='2' flexDirection='row' alignItems='center'>
        <Geolocation
          lazy
          onError={handleGeolocationError}
          onSuccess={handleGeolocationSuccess}
          render={({ getCurrentPosition, fetchingPosition }) => (
            <>
              {!fetchingPosition && <Box width='24px' height='24px' mr={4} as={FaMapMarkerAlt} {...brandColourAsText} />}
              {fetchingPosition && (
                <Box {...brandColourAsText}>
                  <CircularProgress mr={4} size='24px' {...brandColourAsText} />
                </Box>
              )}
              <Button brand reversed isFullWidth onClick={handleGeolocationRetrieve(getCurrentPosition)} isDisabled={fetchingPosition}>
                Use your location
              </Button>
            </>
          )}
        />
      </Flex>
    )
  }

  const renderDeliveryForm = () => {
    if (!isDelivery || !hasDelivery) {
      return null
    }

    return (
      <Flex flexDirection='column'>
        {renderLocationInput()}
        <Flex>
          <TextInput
            ref={inputRef}
            isDisabled={!ready || Boolean(address)}
            autoComplete='off'
            onChange={handleAddressChange}
            placeholder='Enter your delivery address'
            value={`${address || value}`}
          />
          {address && (
            <Button
              onClick={() => {
                clearSuggestions()
                // @ts-ignore
                setValue('')
                dispatch(userAddressReceived(undefined))
                dispatch(userAddressGeocoded(undefined))
                dispatch(userPostcodeSet(undefined))

                // @ts-ignore
                setFocusAfterDispatch(true)
              }}
              // brand
              // reversed
              ml={2}
            >
              Change
            </Button>
          )}
        </Flex>
        <Flex flexDirection='column'>
          {renderSuggestions()}
          {address && (
            <Button mt={4} brand reversed onClick={closePopup}>
              Continue to Order
            </Button>
          )}
          {!isLockedToDelivery(restaurant) && (
            <>
              <Divider marginY='5' />
              <Button
                onClick={() => {
                  handleFulfilmentTypeChange(FulfilmentType.PICKUP)
                  closePopup()
                }}
              >
                Or Switch To Pickup
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    )
  }

  const renderPickupForm = () => {
    if (isDelivery || !hasDelivery) {
      return null
    }

    return (
      <Button
        ref={firstButtonRef}
        onClick={() => {
          handleFulfilmentTypeChange(FulfilmentType.DELIVERY)
        }}
      >
        Switch to Delivery
      </Button>
    )
  }

  if (!hideDelivery || !hasDelivery) {
    return (
      <Button isDisabled brand opacity='1 !important' px={[2, 8]} {...fontSizeSmall} {...borderRadiusNormal}>
        Pickup
      </Button>
    )
  }

  const popoverMaxWidth = () => {
    if (isDelivery || !hasDelivery) {
      return ['20rem', '20rem', '30rem']
    }

    return 'fit-content'
  }

  return (
    <Popover
      isOpen={isPopupOpen}
      onOpen={openPopup}
      onClose={closePopup}
      gutter={-1}
      initialFocusRef={isDelivery ? firstButtonRef : firstFieldRef}
      placement='bottom-start'
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              isFullWidth
              brand
              px={[2, 8]}
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              transition='none'
              {...fontSizeSmall}
              {...(isOpen ? borderRadiusNormalTopOnly : borderRadiusNormal)}
            >
              {address && type === FulfilmentType.DELIVERY && <CheckCircleIcon mr={2} />}
              {getFulfilmentButtonLabel(restaurant, type)}
            </Button>
          </PopoverTrigger>
          <PopoverContent maxWidth={popoverMaxWidth()} border='none' zIndex={2} {...borderRadiusNormalBottomOnly}>
            <PopoverBody {...shadowLarge}>
              {renderDeliveryForm()}
              {renderPickupForm()}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
