import { Box, Spinner } from '@chakra-ui/core'
import React, { useContext, memo, FC } from 'react'
import { Container, GroupHeader } from '../../components'
import { GroupConfigContext } from '../../providers'
import { brandColourAsText } from '../../styles'
import '../styles.css'
import { AnnouncementBanner } from '../../components/announcement-banner'

export const LandingLayout: FC = memo((props) => {
  const { children } = props
  const { loading, configForGroup } = useContext(GroupConfigContext)
  const { bgColor = null, logoLink = null, title = null } = configForGroup || {}

  if (loading) {
    return (
      <Spinner
        top='50%'
        left={['45%', '50%']}
        transform='translate(-50%, -50%)'
        position='absolute'
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        size='xl'
        {...brandColourAsText}
      />
    )
  }

  // TODO if no config for group, load single restaurant instead

  return (
    <Box paddingBottom={4}>
      {configForGroup && (
        <Box position='sticky' top={0} zIndex={2}>
          <GroupHeader title={title} imageURL={logoLink} backgroundColor={bgColor} />
          <AnnouncementBanner bannerText={configForGroup.banner} />
        </Box>
      )}
      <Box bg='white' my={16}>
        <Container>{children}</Container>
      </Box>
    </Box>
  )
})
