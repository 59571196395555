import React, { FC } from 'react'
import { Box } from '@chakra-ui/core'

export const Container: FC = (props) => {
  const { children } = props

  return (
    <Box maxWidth={1248} mx={[0, 'auto']} my={0} px={[4, 6]} py={0}>
      {children}
    </Box>
  )
}
