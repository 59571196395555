import { DAYS, DAYS_SHORT } from './dates'

const getNextOpeningHour = (restaurant, moment) => {
  const today = new Date().getDay()

  for (let i = 0; i < 7; i += 1) {
    const nextDay = today + i > 5 ? i - today : today + (i + 1)

    const nextDayLabel = DAYS[nextDay]

    const nextOpen = restaurant.hours[`${nextDayLabel}Open`]

    if (nextOpen !== 'Closed') {
      const nextOpenTime = moment(nextOpen, ['HH:mm A'])
      return `${DAYS_SHORT[nextDay]} ${nextOpenTime.format('h:mm A')}`
    }
  }

  return 'Closed'
}

export const openingHours = (restaurant, moment) => {
  if (!restaurant || !restaurant.hours) return false
  const label = DAYS[new Date().getDay()]

  const openingTime = moment(restaurant.hours[`${label}Open`], ['HH:mm A'])
  const closingTime = moment(restaurant.hours[`${label}Close`], ['HH:mm A'])

  const restaurantIsOpen = moment().isBetween(openingTime, closingTime)
  return restaurantIsOpen
}

export const openStatus = (restaurant, moment) => {
  if (!restaurant || !restaurant.hours) return false

  const now = moment()

  const label = DAYS[new Date().getDay()]

  const dayOpen = restaurant.hours[`${label}Open`]
  const dayClose = restaurant.hours[`${label}Close`]

  if (dayOpen === 'Closed' || dayClose === 'Closed') {
    return { status: 'closed' }
  }

  const nextOpeningHour = getNextOpeningHour(restaurant, moment)

  // Check for siesta during opening hours
  if (restaurant.hours[`${label}OpenEx`]) {
    const openingTime1 = moment(restaurant.hours[`${label}Open`], ['HH:mm A'])
    const closingTime1 = moment(restaurant.hours[`${label}OpenEx`], ['HH:mm A'])
    const openingTime2 = moment(restaurant.hours[`${label}CloseEx`], ['HH:mm A'])
    const closingTime2 = moment(restaurant.hours[`${label}Close`], ['HH:mm A'])

    if (now.isBetween(openingTime1, closingTime1) || now.isBetween(openingTime2, closingTime2)) {
      return { status: 'opened' }
    }

    if (now.isBefore(openingTime1)) {
      return { status: 'willOpen', opensAt: openingTime1.format('h:mm A') }
    }

    if (now.isBetween(closingTime1, openingTime2)) {
      return { status: 'willOpen', opensAt: openingTime2.format('h:mm A') }
    }

    if (now.isAfter(closingTime2)) {
      if (nextOpeningHour === 'Closed') {
        return { status: 'closed' }
      }

      return { status: 'wasOpened', opensAt: nextOpeningHour }
    }
  }

  // Check for regular hours
  const openingTime = moment(dayOpen, ['HH:mm A'])
  let closingTime = moment(dayClose, ['HH:mm A'])

  if (closingTime.isBefore(openingTime)) {
    closingTime = closingTime.add(1, 'day')
  }

  if (moment().isBefore(openingTime)) {
    return { status: 'willOpen', opensAt: openingTime.format('h:mm A') }
  }

  if (moment().isBetween(openingTime, closingTime)) {
    return { status: 'opened' }
  }

  if (moment().isAfter(closingTime)) {
    if (nextOpeningHour === 'Closed') {
      return { status: 'closed' }
    }

    return { status: 'wasOpened', opensAt: nextOpeningHour }
  }

  return { status: 'closed' }
}

export const OPEN_STATUS = { opened: 'Open Now:', willOpen: 'Closed. Opens at:', wasOpened: 'Closed. Opens on:', closed: 'Closed Today' }

export const getClosedHoursByDay = (moment, restaurant, day) => {
  if (!restaurant || !restaurant.hours) return []

  const label = DAYS[day]
  const { typicalEta } = restaurant

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const gracePeriod = 60 - (typicalEta + 5)

  const dayOpen = restaurant.hours[`${label}Open`]
  const dayClose = restaurant.hours[`${label}Close`]

  if (dayOpen === 'Closed' || dayClose === 'Closed') {
    const allHours = Array.from(Array(24).keys())
    return allHours
  }

  const startHour = 0
  const endHour = 23
  const closedHours: number[] = []

  // NOTE: Check for siesta
  if (restaurant.hours[`${label}OpenEx`]) {
    const openingTime1 = moment(restaurant.hours[`${label}Open`], ['HH:mm A'])
    const closingTime1 = moment(restaurant.hours[`${label}OpenEx`], ['HH:mm A'])
    const openingTime2 = moment(restaurant.hours[`${label}CloseEx`], ['HH:mm A'])
    const closingTime2 = moment(restaurant.hours[`${label}Close`], ['HH:mm A'])

    for (let i = startHour; i < openingTime1.hour(); i += 1) {
      closedHours.push(i)
    }

    for (let i = closingTime1.hour(); i < openingTime2.hour(); i += 1) {
      if (i === closingTime1.hour()) {
        // NOTE: If last hour, check if there's no time available. Disable hour if no time.
        if (!closingTime1.minute()) {
          closedHours.push(i)
        }
      } else {
        closedHours.push(i)
      }
    }

    for (let i = closingTime2.hour(); i <= endHour; i += 1) {
      if (i === closingTime2.hour()) {
        // NOTE: If last hour, check if there's no time available. Disable hour if no time.
        if (!closingTime2.minute()) {
          closedHours.push(i)
        }
      } else {
        closedHours.push(i)
      }
    }

    return closedHours
  }

  // NOTE: Check for regular hours (without siesta)
  const openingTime = moment(dayOpen, ['HH:mm A'])
  const closingTime = moment(dayClose, ['HH:mm A'])

  for (let i = startHour; i < openingTime.hour(); i += 1) {
    closedHours.push(i)
  }

  for (let i = closingTime.hour(); i <= endHour; i += 1) {
    if (i === closingTime.hour()) {
      // NOTE: If last hour, check if there's no time available. Disable hour if no time.
      if (!closingTime.minute()) {
        closedHours.push(i)
      }
    } else {
      closedHours.push(i)
    }
  }

  return closedHours
}

export const getClosedMinutesByDayByHour = (moment, restaurant, day, selectedHour) => {
  if (!restaurant || !restaurant.hours) return []

  const { hours } = restaurant

  const label = DAYS[day]

  const dayOpen = hours[`${label}Open`]
  const dayClose = hours[`${label}Close`]

  const allMinutes = [0, 15, 30, 45]

  if (dayOpen === 'Closed' || dayClose === 'Closed') {
    return allMinutes
  }

  const getMinutesBefore = (minutes) => {
    // NOTE: if restaurant opens at 8:20am, disable 0min and 15min.
    const pastSteps = Math.ceil(minutes / 15)
    const minutesInPast = Array.from(Array(pastSteps).keys()).map((e) => e * 15)

    return minutesInPast
  }
  const getMinutesAfter = (minutes) => {
    // NOTE: Reverse logic: if restaurant closes at 8:20pm, disabled 30min and 45min.
    const pastSteps = Math.ceil(minutes / 15)
    const minutesInFuture = Array.from(allMinutes)
    const removeSteps = pastSteps + 1

    minutesInFuture.splice(0, removeSteps)

    return minutesInFuture
  }

  // NOTE: Check for siesta
  if (hours[`${label}OpenEx`]) {
    const openingTime1 = moment(hours[`${label}Open`], ['HH:mm A'])
    const closingTime1 = moment(hours[`${label}OpenEx`], ['HH:mm A'])
    const openingTime2 = moment(hours[`${label}CloseEx`], ['HH:mm A'])
    const closingTime2 = moment(hours[`${label}Close`], ['HH:mm A'])

    // Check before/after hours
    if (
      selectedHour < openingTime1.hour() ||
      (selectedHour > closingTime1.hour() && selectedHour < openingTime2.hour()) ||
      selectedHour > closingTime2.hour()
    ) {
      return allMinutes
    }

    // Check 1st opening
    if (selectedHour === openingTime1.hour()) {
      const closedMinutesBefore = getMinutesBefore(openingTime1.minute())
      let closedMinutesAfter: number[] = []

      if (selectedHour === closingTime1.hour()) {
        closedMinutesAfter = getMinutesAfter(closingTime1.minute())
      }

      const closedMinutes = [...closedMinutesBefore, ...closedMinutesAfter]

      return closedMinutes
    }

    // Check 2nd opening
    if (selectedHour === openingTime2.hour()) {
      const closedMinutesBefore = getMinutesBefore(openingTime2.minute())
      let closedMinutesAfter: number[] = []

      if (selectedHour === closingTime2.hour()) {
        closedMinutesAfter = getMinutesAfter(closingTime2.minute())
      }

      const closedMinutes = [...closedMinutesBefore, ...closedMinutesAfter]

      return closedMinutes
    }

    // Check 1st closing
    if (selectedHour === closingTime1.hour()) {
      const closedMinutes = getMinutesAfter(closingTime1.minute())
      return closedMinutes
    }

    // Check 2nd closing
    if (selectedHour === closingTime2.hour()) {
      const closedMinutes = getMinutesAfter(closingTime2.minute())
      return closedMinutes
    }

    return []
  }

  // NOTE: Check for regular hours (without siesta)
  const openingTime = moment(dayOpen, ['HH:mm A'])
  const closingTime = moment(dayClose, ['HH:mm A'])

  // Check before/after hours
  if (selectedHour < openingTime.hour() || selectedHour > closingTime.hour()) {
    return allMinutes
  }

  // Check opening
  if (selectedHour === openingTime.hour()) {
    const closedMinutes = getMinutesBefore(openingTime.minute())
    return closedMinutes
  }

  // Check closing
  if (selectedHour === closingTime.hour()) {
    const closedMinutes = getMinutesAfter(closingTime.minute())
    return closedMinutes
  }

  return []
}
