import React, { FC } from 'react'
import { Route, Router, Switch, useHistory, useParams } from 'react-router-dom'
import { OrderItem } from './item'
import { RestaurantLayout } from '../../layout'
import { OrderContextProvider } from '../../providers'

export const OrderRoute: FC = () => {
  const history = useHistory()
  const params = useParams()
  const { orderId } = params

  return (
    <RestaurantLayout>
      <OrderContextProvider orderId={orderId}>
        <Router history={history}>
          <Switch>
            <Route path='/'>
              <OrderItem />
            </Route>
          </Switch>
        </Router>
      </OrderContextProvider>
    </RestaurantLayout>
  )
}
