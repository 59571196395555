import { useElements, CardElement } from '@stripe/react-stripe-js'
import { useToast } from '@chakra-ui/core'
import React, { FC, useContext, useState } from 'react'
import { Mutation } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { StripeCardElement } from '@stripe/stripe-js'
import { RestaurantContext, AppContext, PaymentContext } from '../../../providers'
import { PaymentModal } from '../../../components'
import { paymentIntentForOrder, submitOrder } from '../../../mutations'
import { clearCart } from '../../../providers/app/actions'
import { createPaymentIntentFromState } from '../../../utils/orders'
import { RollbarErrorTracking } from '../../../components/rollbar'

export const CheckoutRoute: FC = () => {
  const history = useHistory()
  const { restaurant } = useContext(RestaurantContext)
  const { state, dispatch } = useContext(AppContext)
  const { stripe } = useContext(PaymentContext)
  const elements = useElements()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [prLoading, setPrLoading] = useState(false)

  const showError = (title: string, description: string) => {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
      duration: null,
    })
  }

  const confirmOrder = async (addOrder, orderId: string) => {
    try {
      await addOrder({ variables: { orderId } })
      dispatch(clearCart())
      setLoading(false)
      setPrLoading(false)

      let url = `/order/${orderId}`

      if (state.fulfilmentState.type === 'Dine In') {
        url = `/table/${state.fulfilmentState.tableNumber}/order/${orderId}`
      }

      history.push(url)
    } catch (ex) {
      RollbarErrorTracking.logErrorInRollbar('failed when confirming order')
      setLoading(false)
      setPrLoading(false)
      showError('Order failed', 'We have taken payment for your order, please contact us')
    }
  }

  const processPayment = async (secret: string, orderId: string, addOrder: () => void) => {
    if (!stripe) {
      RollbarErrorTracking.logErrorInRollbar('no stripe when processing CC')
      setLoading(false)
      return showError('Error with payment', 'Could not find provider')
    }

    if (!elements) {
      RollbarErrorTracking.logErrorInRollbar('no elements when processing CC')
      setLoading(false)
      return showError('Error with payment', 'Could not find credit card')
    }

    try {
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            name: state.userState.fullName,
          },
        },
      })

      if (confirmError) {
        setLoading(false)

        if (!paymentIntent) {
          RollbarErrorTracking.logErrorInRollbar('no payment intent when confirming CC', confirmError)
          return showError('Error with payment', confirmError.message || 'Payment could not be made at this time')
        }
        RollbarErrorTracking.logErrorInRollbar('error when confirming CC', confirmError)
        return showError('Error', confirmError.message || 'Payment could not be made at this time')
      }

      confirmOrder(addOrder, orderId)
    } catch (catchError) {
      RollbarErrorTracking.logErrorInRollbar('failed when confirming CC', catchError)
      setLoading(false)
      showError('Error', catchError.message)
    }
  }

  const handleSubmit = (addPaymentIntent, addOrder) => async () => {
    const paymentIntentVariables = createPaymentIntentFromState(state, restaurant)

    try {
      const {
        data: { paymentIntentForOrder: response },
      } = await addPaymentIntent({
        variables: {
          order: paymentIntentVariables,
        },
      })

      const result = JSON.parse(response)

      if (result.status === 'success' && elements && stripe) {
        const { orderId, secret } = result
        processPayment(secret, orderId, addOrder)
      } else {
        RollbarErrorTracking.logErrorInRollbar('error when adding payment intent CC', result)
        setLoading(false)
        return showError('Error creating order', result.errorMessage || 'A technical error occurred')
      }
    } catch (ex) {
      RollbarErrorTracking.logErrorInRollbar('failed when adding payment intent CC', ex)
      setLoading(false)
      showError('Error', ex.message)
    }
  }

  const processPayRequestPayment = async (
    secret: string,
    orderId: string,
    addOrder: () => void,
    paymentMethodId: string,
    complete: (string: string) => void,
  ) => {
    if (!stripe) {
      RollbarErrorTracking.logErrorInRollbar('no stripe when processing PR')
      complete('fail')
      setPrLoading(false)
      return showError('Error with payment', 'Could not find provider')
    }

    if (!elements) {
      RollbarErrorTracking.logErrorInRollbar('no elements when processing PR')
      complete('fail')
      setPrLoading(false)
      return showError('Error with payment', 'Could not find credit card')
    }

    if (!paymentMethodId) {
      RollbarErrorTracking.logErrorInRollbar('no paymentMethodId when processing PR')
      complete('fail')
      setPrLoading(false)
      return showError('Error with payment', 'Could not find payment method id')
    }

    try {
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        secret,
        { payment_method: paymentMethodId },
        { handleActions: false },
      )

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.

        complete('fail')
        setPrLoading(false)

        if (!paymentIntent) {
          RollbarErrorTracking.logErrorInRollbar('no payment intent when confirming PR', confirmError)
          return showError('Error with payment', confirmError.message || 'Payment could not be made at this time')
        }
        RollbarErrorTracking.logErrorInRollbar('error when confirming PR', confirmError)
        return showError('Error', confirmError.message || 'Payment could not be made at this time')
      }

      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      complete('success')

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow.
      if (paymentIntent) {
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error: actionConfirmError } = await stripe.confirmCardPayment(secret)
          if (actionConfirmError) {
            // The payment failed -- ask your customer for a new payment method.
            RollbarErrorTracking.logErrorInRollbar('payment intent requires action but has error when confirming PR', actionConfirmError)
            return showError('Error with payment', actionConfirmError.message || 'Payment could not be made at this time')
          }
        }
      } else {
        RollbarErrorTracking.logErrorInRollbar('no error BUT no payment intent when confirming PR')
      }

      // The payment has succeeded.
      confirmOrder(addOrder, orderId)
    } catch (catchError) {
      RollbarErrorTracking.logErrorInRollbar('failed when confirming PR', catchError)
      complete('fail')
      setPrLoading(false)
      showError('Error', catchError.message)
    }
  }

  const handlePayRequestSubmit = (addPaymentIntent, addOrder, paymentMethodId, complete) => {
    const paymentIntentVariables = createPaymentIntentFromState(state, restaurant)

    const getData = async () => {
      try {
        const {
          data: { paymentIntentForOrder: response },
        } = await addPaymentIntent({
          variables: {
            order: paymentIntentVariables,
          },
        })

        const result = JSON.parse(response)

        if (result.status === 'success' && elements && stripe) {
          const { orderId, secret } = result
          processPayRequestPayment(secret, orderId, addOrder, paymentMethodId, complete)
        } else {
          RollbarErrorTracking.logErrorInRollbar('error when adding payment intent PR', result)
          complete('fail')
          setPrLoading(false)
          return showError('Error creating order', result.errorMessage || 'A technical error occurred')
        }
      } catch (ex) {
        RollbarErrorTracking.logErrorInRollbar('failed when adding payment intent PR', ex)
        complete('fail')
        setPrLoading(false)
        return showError('Error', ex.message)
      }
    }
    getData()
  }

  const handleClose = () => {
    let url = `/`

    if (state.fulfilmentState.type === 'Dine In') {
      url = `/table/${state.fulfilmentState.tableNumber}`
    }

    history.push(url)
  }

  const handleSubmitting = () => {
    setLoading(true)
  }

  const handlePrSubmitting = () => {
    setPrLoading(true)
  }

  const renderPayment = (addPaymentIntent, addOrder) => {
    return (
      <PaymentModal
        isLoading={loading}
        isPrLoading={prLoading}
        isOpen
        onClose={handleClose}
        onSubmitting={handleSubmitting}
        onPrSubmitting={handlePrSubmitting}
        onSubmit={handleSubmit(addPaymentIntent, addOrder)}
        onPayRequestSubmit={(paymentMethodId, complete) => handlePayRequestSubmit(addPaymentIntent, addOrder, paymentMethodId, complete)}
      />
    )
  }

  return (
    <Mutation mutation={paymentIntentForOrder}>
      {(addPaymentIntent) => <Mutation mutation={submitOrder}>{(addOrder) => renderPayment(addPaymentIntent, addOrder)}</Mutation>}
    </Mutation>
  )
}
