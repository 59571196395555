import { Box, Image, Text } from '@chakra-ui/core'
import React, { useState } from 'react'
import { IoIosImage } from 'react-icons/io'
import { fontSizeH2, textShadowThick, shadow, fontSemiBold } from '../../styles'

const ImageButton = ({ url, children }) => {
  const onImageClick = () => {
    window.location.href = url
  }

  return (
    <Box
      height='250px'
      bg='#d5d5d5'
      position='relative'
      cursor='pointer'
      transition='all 100ms ease'
      _hover={{
        ...shadow,
        transform: 'scale(1.025)',
      }}
      _active={{
        transform: 'scale(1.0125)',
      }}
      _focus={{
        outline: '3px solid #d3e6ec',
      }}
      as='a'
      display='flex'
      justifyContent='center'
      alignItems='center'
      onClick={onImageClick}
    >
      {children}
    </Box>
  )
}

const ImageTitle = ({ title }) => {
  return (
    <Text position='absolute' color='#fff' textAlign='center' left={4} right={4} {...textShadowThick} {...fontSizeH2} {...fontSemiBold}>
      {title}
    </Text>
  )
}

const ImageLoad = ({ imageLink, name }) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      {isLoading && <IoIosImage color='rgba(0,0,0,0.2)' fontSize='4rem' />}

      <Image
        src={imageLink}
        display={!isLoading ? 'block' : 'none'}
        onLoad={() => setIsLoading(false)}
        height='250px'
        objectFit='cover'
        width='100%'
        alt={name}
      />
    </>
  )
}

/**
 * Display an image card with venue name.
 * Click card to redirect to venue's whitelabel page
 *
 * @param venue
 */
export const VenueCard = (venue) => {
  const { name, slug, imageLink, branding } = venue
  const imageURL = branding?.groupImageLink || branding?.heroImageLink || imageLink

  // If no slug, use the external link
  const getUrl = () => {
    if (!slug && venue?.website) {
      return venue?.website
    }
    return `/${slug}`;
  }

  return (
    <ImageButton url={getUrl()}>
      <ImageTitle title={name} />
      {!imageURL ? <IoIosImage color='rgba(0,0,0,0.2)' fontSize='4rem' /> : <ImageLoad imageLink={imageURL} name={name} />}
    </ImageButton>
  )
}
