import gql from 'graphql-tag'

export const menuItemsForParams = gql`
  query MenuItemsForParams($restId: String!, $type: String!, $date: AWSDate!, $time: String!) {
    menuItemsForParams(restId: $restId, type: $type, date: $date, time: $time) {
      objectId
      price
      imageLink
      itemIndex
      itemDescription
      itemTitle
      category
      disableDate
      options {
        objectId
        menuItemId
        optionIndex
        optionTitle
        optionDescription
        minSelections
        maxSelections
        mandatory
        choices
      }
    }
  }
`
